import React from 'react'
import { Link } from 'react-router-dom'
import { IKImage } from 'imagekitio-react'

import { StyledGrid, StyledGridItem } from './styled'
import { PAGES_TO_SLIDES } from '../assets/images/data'
import { TRANSLATIONS_POS } from './components/translationsPosition'
import { useElementRect } from '../hooks/useElementRect'
import Icon from '../assets/icons/Icon'
import { canFullscreen, enterFullScreen, exitFullScreen } from '../utils/fullScreen'
import { urlEndpoint } from '../constants'
import Content from './components/Content'
import pageToCount from '../assets/images/köide_003.png'
import WebsiteTeam from './components/WebsiteTeam'
import { TransformComponent } from 'react-zoom-pan-pinch'

const MobilePage = ({
  i,
  src,
  page,
  hoveredElementId,
  isFullscreenState,
  setHoveredElementId,
  swiper,
  lng,
  t,
  zoom,
  pageToCountRef,
  isLastPage,
}) => {
  const { height: pageHeight, width: pageWidth } = useElementRect(pageToCountRef)
  const activePage = PAGES_TO_SLIDES[page] === i

  // const setHeight = () => {
  //   if (typeof window !== 'undefined') {
  //     document.documentElement.style.setProperty('--height', `${window.innerHeight}px`)
  //   }
  // }
  //
  // useEffect(() => {
  //   document.addEventListener('orientationchange', () => {
  //     setHeight()
  //   })
  //   return document.removeEventListener('orientationchange', () => {
  //     setHeight()
  //   })
  // }, [])

  return (
    <div
      data-tip={!!t(hoveredElementId)}
      data-for={hoveredElementId}
      className={zoom && PAGES_TO_SLIDES[page] !== i ? 'hidden swiper-zoom-container' : 'swiper-zoom-container'}
    >
      {page === 'content' && <Content pageHeight={pageHeight} pageWidth={pageWidth} swiper={swiper} isMobile />}
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          inset: 'initial',
        }}
      >
        <StyledGrid
          className={`mobile swiper-zoom-target${lng !== 'uk' && TRANSLATIONS_POS[page] ? ' showGrid' : ''}`}
          cols={TRANSLATIONS_POS[page]?.cols}
          rows={TRANSLATIONS_POS[page]?.rows}
          style={{ width: `${pageWidth}px`, height: `${pageHeight}px` }}
        >
          {activePage && (
            <div className="rightTopIcons">
              <Link to="/">
                <Icon name="home" className={zoom ? 'zoomed home' : ' home'} />
              </Link>
              {canFullscreen() && (
                <Icon
                  name={isFullscreenState ? 'exitFullscreen' : 'fullscreen'}
                  className={zoom ? 'fullscreenIcon zoomed' : 'fullscreenIcon'}
                  onClick={isFullscreenState ? exitFullScreen : enterFullScreen}
                />
              )}
            </div>
          )}

          {TRANSLATIONS_POS[page]?.items.map((item, i) => {
            return (
              <StyledGridItem
                key={`${item.name}-${i}`}
                pos={item.position}
                item={item}
                as={TRANSLATIONS_POS[page].type}
                onMouseOver={() => {
                  setHoveredElementId(item.name)
                }}
                onMouseOut={() => setHoveredElementId(null)}
              />
            )
          })}

          {page === '0' && (
            <div
              style={{
                position: 'absolute',
                top: '-9vh',
                bottom: 0,
                right: pageWidth && pageWidth / 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: pageWidth && pageWidth / 2,
                fontSize: 20,
              }}
            >
              <div
                style={{
                  padding: '0 30px',
                  textAlign: 'center',
                  whiteSpace: 'pre-wrap',
                  textDecoration: 'underline',
                }}
              >
                {t('common:mobileTranslateTip')}
              </div>
              <div
                style={{
                  padding: '20px 30px 0',
                  textAlign: 'center',
                  whiteSpace: 'pre-wrap',
                  textDecoration: 'underline',
                }}
              >
                {t('common:mobileZoomTip')}
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: 20 }}>
                {'<-'}
                <div style={{ margin: '0 10px', textDecoration: 'underline' }}>{t('common:swipe')}</div>
                ->
              </div>
            </div>
          )}
          {isLastPage && <WebsiteTeam isMobile />}
        </StyledGrid>
      </div>
      <>
        <IKImage
          urlEndpoint={urlEndpoint}
          path={src}
          id={`img${i}`}
          transformation={[
            {
              width: 1100,
            },
          ]}
        />
        {/*pageWidth > 500 ? pageWidth : 600*/}
        <img style={{ opacity: 0, position: 'absolute' }} src={pageToCount} alt="tech page" ref={pageToCountRef} />
      </>
    </div>
  )
}

export default MobilePage
