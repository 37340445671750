import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import React, { useEffect } from 'react'
import { pageview } from 'react-ga'

import { StyledContent } from './styled'
import { useWindowParams } from '../../hooks/useWindowWidth'
import { PAGES_TO_SLIDES } from '../../assets/images/data'

const Content = ({ bookRef, isMobile, pageHeight, pageWidth, swiper }) => {
  const {
    t,
    i18n: {
      language,
      options: {
        resources: {
          en: { content },
        },
      },
    },
  } = useTranslation(['content', 'contentText'])
  let location = useLocation()
  const { width, height } = useWindowParams()
  const isSmallScreen = width < 717 || height < 512

  const firstHalfKeys = Object.keys(content)
  const secondHalfKeys = isSmallScreen ? firstHalfKeys.splice(8) : Object.keys(content)

  useEffect(() => {
    pageview(location?.pathname + location?.search)
  }, [location])

  const getItem = (key) => {
    const page = key.split('_')[1]
    // const pageSplit = t(key).split(language === 'et' ? 'lk ' : '.')
    return (
      <div
        key={key}
        className="contentItem header"
        onClick={() =>
          bookRef ? bookRef.getPageFlip().turnToPage(PAGES_TO_SLIDES[page]) : swiper.slideTo(PAGES_TO_SLIDES[page])
        }
      >
        {t(key)}
      </div>
    )
  }

  return (
    <StyledContent className={`${isSmallScreen && 'mobile'} ${language}`} pageWidth={pageWidth} pageHeight={pageHeight}>
      {isSmallScreen && (
        <div className="firstHalf">
          <div>
            <div className="header title">{t('contentTexts:content')}</div>
            {firstHalfKeys.map((key) => getItem(key))}
          </div>
        </div>
      )}
      <div className="secondHalf">
        <div className="header title hidden">{t('contentTexts:content')}</div>
        {isMobile && (
          <div className="swipe">
            <div className="text">{t('contentTexts:swipeText')}</div>
            <div className="arrow">-></div>
          </div>
        )}
        <div>{secondHalfKeys.map((key) => getItem(key))}</div>
      </div>
    </StyledContent>
  )
}

export default Content
