import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledLanguages } from './styled'

const Languages = ({ className }) => {
  const { i18n } = useTranslation()
  const lng = i18n.languages[0]
  const onLngChange = (newLng) => () => {
    i18n.changeLanguage(newLng)
  }

  return (
    <StyledLanguages className={className}>
      <span className={lng === 'uk' ? 'selected' : undefined} onClick={onLngChange('uk')}>
        укр
      </span>
      <span className={lng === 'et' ? 'selected' : undefined} onClick={onLngChange('et')}>
        est
      </span>
      <span className={lng === 'en' ? 'selected' : undefined} onClick={onLngChange('en')}>
        eng
      </span>
    </StyledLanguages>
  )
}

export default Languages
