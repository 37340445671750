import React, { useCallback, useEffect } from 'react'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { useTranslation } from 'react-i18next'

import {
  StyledArrowWrapper,
  StyledGrid,
  StyledGridItem,
  StyledPage,
  StyledPageWrapper,
  StyledZoomIcons,
} from '../styled'
import { TRANSLATIONS_POS } from './translationsPosition'
import Icon from '../../assets/icons/Icon'
import { PAGES, PAGES_TO_SLIDES, SLIDES_TO_PAGES } from '../../assets/images/data'
import { enterFullScreen, exitFullScreen } from '../../utils/fullScreen'
import { Link } from 'react-router-dom'
import { IKImage } from 'imagekitio-react'
import { urlEndpoint } from '../../constants'
import Content from './Content'
import contentPage from '../../assets/images/köide_002.png'
import { useElementRect } from '../../hooks/useElementRect'
import WebsiteTeam from './WebsiteTeam'

const Page = React.forwardRef(
  (
    {
      children,
      i,
      page,
      src,
      hoveredElementId,
      setHoveredElementId,
      setZoom,
      swiper,
      isMobile,
      zoom,
      onArrowClick,
      isFullscreenState,
      pageToCountRef,
      isLastPage,
    },
    ref
  ) => {
    const { t, i18n } = useTranslation(['texts', 'common'])
    const lng = i18n.language

    const { height: pageHeight, width: pageWidth } = useElementRect(pageToCountRef)

    // useEffect(() => {
    //   console.log(pageToCountRef)
    // }, [pageToCountRef])

    const setZooming = useCallback(
      (e) => {
        if (e.scale > 1 && !zoom) {
          setTimeout(() => {
            setZoom(true)
          }, 200)
        }
        if (e.scale === 1) {
          setZoom(false)
        }
      },
      [setZoom, zoom]
    )

    useEffect(() => {
      setZoom(false)
    }, [page, setZoom])

    const activePage = PAGES_TO_SLIDES[page] === i
    // const showIcons = i === PAGES.length - 1
    return (
      <>
        <StyledPageWrapper
          // zIndex={-i}
          // showPage={i >= PAGES_TO_SLIDES[page]}
          // zoom={zoom}
          // pageWidth={pageWidth}
          // className="demoPage"
          // data-density={'hard'}
          ref={ref}
          data-tip={!!t(hoveredElementId)}
          data-for={hoveredElementId}
          className={zoom && PAGES_TO_SLIDES[page] !== i ? 'hidden' : ''}
          style={{ width: `${pageWidth}px`, height: `${pageHeight}px` }}
        >
          <StyledPage>
            <div style={{ position: 'relative', display: 'grid' }}>
              {activePage && (
                <div className="rightTopIcons">
                  <Link to="/">
                    <Icon name="home" className={zoom ? 'zoomed home' : ' home'} />
                  </Link>
                  <Icon
                    name={isFullscreenState ? 'exitFullscreen' : 'fullscreen'}
                    className={zoom ? 'zoomed fullscreen' : 'fullscreen'}
                    onClick={isFullscreenState ? exitFullScreen : enterFullScreen}
                  />
                </div>
              )}
              {page !== 'content' && activePage && (
                <StyledArrowWrapper className={zoom ? 'left zoomed' : 'left'}>
                  <Icon name="arrowLeft" className="arrowLeft" onClick={() => swiper.slidePrev()} />
                </StyledArrowWrapper>
              )}
              {+page !== SLIDES_TO_PAGES[PAGES.length - 1] && activePage && (
                <StyledArrowWrapper className={zoom ? 'right zoomed' : 'right'}>
                  <Icon name="arrowRight" className="arrowRight" id="arrowRight" onClick={() => swiper.slideNext()} />
                </StyledArrowWrapper>
              )}
              {!zoom && TRANSLATIONS_POS[page] && lng !== 'uk' && (
                <StyledGrid
                  cols={TRANSLATIONS_POS[page].cols}
                  rows={TRANSLATIONS_POS[page].rows}
                  className={`${lng !== 'uk' ? ' showGrid' : ''}`}
                >
                  {TRANSLATIONS_POS[page].items.map((item, i) => (
                    <StyledGridItem
                      key={`${item.name}-${i}`}
                      pos={item.position}
                      item={item}
                      as={TRANSLATIONS_POS[page].type}
                      onMouseOver={() => {
                        setHoveredElementId(item.name)
                      }}
                      onMouseOut={() => setHoveredElementId(null)}
                    />
                  ))}
                </StyledGrid>
              )}
              {activePage ? (
                <TransformWrapper
                  options={{ maxScale: 2, centerContent: false, limitToWrapper: true }}
                  zoomIn={{ step: 10 }}
                  zoomOut={{ step: 11 }}
                  wheel={{ disabled: true }}
                  doubleClick={{ disabled: true }}
                  pan={{ disabled: !zoom }}
                  onZoomChange={setZooming}
                >
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => {
                    return (
                      <>
                        {page === 'content' && (
                          <Content swiper={swiper} pageHeight={pageHeight} pageWidth={pageWidth} />
                        )}
                        <TransformComponent>
                          {page === '0' && (
                            <div
                              style={{
                                position: 'absolute',
                                top: '-7vh',
                                bottom: 0,
                                left: '1.7vw',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '49%',
                                fontSize: 35,
                              }}
                            >
                              <div
                                style={{
                                  padding: '0 20px',
                                  textAlign: 'center',
                                  whiteSpace: 'pre-wrap',
                                  textDecoration: 'underline',
                                }}
                              >
                                {t('common:howToTranslateTip')}
                              </div>
                            </div>
                          )}
                          {isLastPage && <WebsiteTeam />}
                          <IKImage urlEndpoint={urlEndpoint} path={src} id={`img${i}`} />
                          <img
                            style={{ opacity: 0, position: 'absolute' }}
                            src={contentPage}
                            alt="tech page"
                            ref={pageToCountRef}
                          />
                        </TransformComponent>
                        {activePage && page !== 'content' && (
                          <StyledZoomIcons className={zoom ? 'zoomed' : ''}>
                            <Icon id="zoomIn" name="zoomIn" className="zoomIcon" onClick={zoomIn} />
                            <Icon name="zoomOut" id="zoomOut" className="zoomIcon" onClick={zoomOut} />
                          </StyledZoomIcons>
                        )}
                      </>
                    )
                  }}
                </TransformWrapper>
              ) : (
                <IKImage urlEndpoint={urlEndpoint} path={src} id={`img${i}`} style={{ opacity: 0 }} />
              )}
            </div>
          </StyledPage>
        </StyledPageWrapper>
      </>
    )
  }
)

export default Page
