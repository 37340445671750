import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// import en from './locales/en'
import et from './locales/et'
import en from './locales/en'
import uk from './locales/uk'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: { uk, et, en },
    whitelist: ['uk', 'et', 'en'],
    languageOnly: true,
    load: 'languageOnly',
    // lng: 'et',
    fallbackLng: 'en',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
