import styled from 'styled-components'

export const StyledWrapper = styled.div`
  height: inherit;

  &.centered {
    margin-top: ${({ marginTop }) => marginTop}px;
  }

  .icon {
    cursor: pointer;
    z-index: 3000;
  }

  .rightTopIcons {
    position: absolute;
    right: 100%;
    top: 1.8vw;
    transition: all 0.1s ease-in;
    z-index: 3;

    .zoomed {
      margin-right: 0.7vw;
    }

    .home {
      margin-bottom: 15px;
      svg {
        width: 2.6vw;
        height: auto;
      }
    }
    .fullscreen {
      cursor: pointer;
      svg {
        width: 1.9vw;
        height: auto;
      }
    }
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
  }

  .swiper-container {
    overflow: visible;
  }

  .react-transform-component {
    margin-top: 5px;
    border-radius: 10px;
  }

  //&.zoomed {
  //  .swiper-container {
  //    overflow: visible;
  //  }
  //}

  .swiper-zoom-container {
    max-width: 96vw;
    height: calc(100vh - 1.5vw);
    position: relative;

    img {
      width: auto;
      height: auto;
    }

    &.hidden {
      & > img {
        opacity: 0;
      }
    }
  }

  .__react_component_tooltip.tooltipTranslation {
    max-width: calc(90vw / 2 - 40px);
    width: auto;
    white-space: pre-line;
    max-width: ${({ item }) => item && item.width};
    font-size: ${({ item }) => (item && item.fontSize) || (item && item.header ? 38 : 22)}px;
    font-family: ${({ item }) => (item && item.header ? 'AnatolijCapital' : 'AnatolijCursive')}, sans-serif;
    text-align: ${({ item }) => item && item.textAlign};
    color: ${({ item }) => item && item.color};
  }

  &.mobile {
    &.fullscreen {
      display: flex;
      align-items: center;
    }
    .rightTopIcons {
      margin-right: 10px;
      top: 2vw;
      opacity: ${({ zoom }) => (zoom ? 0 : 1)};

      svg {
        width: 2.5vw;
        height: auto;
      }
    }

    .home {
      margin-bottom: 10px;
      svg {
        width: 3.5vw;
        height: auto;
      }
    }

    .__react_component_tooltip.tooltipTranslation {
      font-size: ${({ item }) => (item && item.mobileFontSize) || (item && item.header ? 20 : 14)}px;
      max-width: ${({ item }) => item && (item.mobileWidth || '45vw')};
    }

    .swiper-zoom-container {
      height: auto;
      max-width: 96vw;
      max-height: calc(var(--vh, 1vh) * 90);
    }

    img {
      max-height: calc(var(--vh, 1vh) * 90);
    }
  }
`

export const StyledPageWrapper = styled.div``

export const StyledArrowWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  transition: margin 0.1s ease-in;

  &.left {
    right: 100%;

    &.zoomed {
      margin-right: 1vw;
    }
  }
  &.right {
    left: 100%;
    z-index: 100;

    &.zoomed {
      margin-left: 1vw;
    }
  }

  .arrowLeft,
  .arrowRight {
    svg {
      width: 1.8vw;
      height: auto;
    }
  }
`

export const StyledZoomIcons = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 100%;
  top: 2vw;
  transition: margin 0.1s ease-in;

  &.zoomed {
    margin-left: 1vw;
  }

  .zoomIcon {
    &:nth-child(2) {
      padding-top: 10px;
    }
    &:nth-child(3) {
      padding: 20px 0;
    }
    svg {
      width: 2.4vw;
      height: auto;
    }
  }
`

export const StyledPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    max-height: 98vh;
    max-width: 92vw;
    width: auto;
    height: auto;

    &.hidden {
      z-index: -1;
      opacity: 0;
    }
  }

  &.hidden {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

export const StyledGrid = styled.div`
  &.showGrid {
    display: grid;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    height: 100%;
    justify-items: stretch;
    align-items: stretch;
    grid-template-columns: ${({ cols }) => `[start] 1fr repeat(${cols - 2}, 1fr) 1fr [end]`};
    grid-template-rows: ${({ rows }) => `[start] 1fr repeat(${rows - 2}, 1fr) 1fr [end]`};
  }

  &.mobile {
    position: relative;
  }
`

export const StyledGridItem = styled.a`
  align-self: stretch;
  justify-self: stretch;
  grid-area: ${({ pos }) => pos};
  cursor: pointer;
  margin: ${({ item }) => item && item.margin};
  padding: ${({ item }) => item && item.padding};
  transform: ${({ item }) => item && item.transform};
`
