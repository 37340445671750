import { useEffect, useState } from 'react'

export const useWindowParams = () => {
  const isClient = typeof window !== 'undefined'

  const getWidth = () => (isClient ? window.innerWidth : undefined)
  const getHeight = () => (isClient ? window.innerHeight : undefined)

  const [width, setWidth] = useState(getWidth)
  const [height, setHeight] = useState(getHeight)

  useEffect(() => {
    if (isClient) {
      const handleResize = () => {
        const w = getWidth()
        const h = getHeight()
        // console.log(h)
        setWidth(w)
        setHeight(h)
        document.documentElement.style.setProperty('--vh', `${h * 0.01}px`)
        // document.documentElement.style.setProperty('--vw', w)
      }

      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return { width, height }
}
