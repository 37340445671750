import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledWebsiteTeam } from './styled'
import ukrInstLogo from '../../assets/images/Logo_of_the_Ukrainian_Institute.png'
import Icon from '../../assets/icons/Icon'

const ROLES = [
  'rozrobkaSaytu',
  'avtorKnygy',
  'avtoryProektu',
  'elektronniShryfty',
  'perekladyNaEstonsku',
  'perekladyNaAngl',
  'finansy',
]

const WebsiteTeam = ({ isMobile }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('last')

  return (
    <StyledWebsiteTeam className={isMobile && 'mobile'}>
      <div>
        <div className="header title">{t('nadSaitomPracuvaly')}</div>
        {ROLES.map((role) => (
          <div className="row">
            <div className="role">{t(role)}</div>
            <div className="name header">{t(`${role}Name`)}</div>
          </div>
        ))}
      </div>
      <div className={`institutions ${language}`}>
        <div className="row">
          <Icon name={`${language === 'uk' ? 'uk' : 'en'}Ukf`} className="UKFLogo" />
          <div className="role">{t('knygaStvorenaZaPidtrymkyUKF')}</div>
        </div>
        <div className="row UI">
          <img src={ukrInstLogo} alt="Ukrainian institute logo" className="UILogo" />
          <div className="role">{t('saytStvorenyjZaPidtrymkyUkrInst')}</div>
        </div>
      </div>
    </StyledWebsiteTeam>
  )
}

export default WebsiteTeam
