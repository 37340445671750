import styled from 'styled-components'

import theme from '../../theme'

export const StyledContent = styled.div`
  position: absolute;
  width: ${({ pageWidth }) => pageWidth / 2}px;
  top: 8vw;
  display: flex;
  align-items: flex-start;
  left: 52%;
  z-index: 10;
  font-size: 2vw;
  font-size: ${({ pageWidth }) => 2 * (pageWidth / 100)}px;
  justify-content: center;
  text-align: left;

  .title {
    font-size: ${({ pageWidth }) => 3.5 * (pageWidth / 100)}px;
    margin-bottom: 10px;
  }

  .secondHalf {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 65%;
  }

  .contentItem {
    cursor: pointer;
    display: block;
    padding: 4px 0;
    &:hover {
      opacity: 0.7;
    }
  }

  &.mobile {
    justify-content: space-around;
    top: initial;
    width: ${({ pageWidth }) => pageWidth}px;
    left: initial;
    font-size: ${({ pageHeight }) => 4 * (pageHeight / 100)}px;

    .title {
      font-size: ${({ pageHeight }) => 6 * (pageHeight / 100)}px;
      margin-bottom: 5px;
      margin-left: -10px;
      &.hidden {
        opacity: 0;
      }
    }

    .swipe {
      white-space: pre;
      position: absolute;
      font-size: ${({ pageHeight }) => 5.2 * (pageHeight / 100)}px;
      align-self: flex-end;
      line-height: 16px;
      display: flex;
      align-items: center;
      .arrow {
      }
      .text {
        margin-right: 7px;
        text-decoration: underline;
      }
    }

    .contentItem {
      padding: 3px 0;
    }

    .firstHalf {
      flex-basis: 37%;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: ${theme.light};
      font-weight: 600;
      margin-right: 10px;
    }

    .secondHalf {
      flex-basis: 40%;
      left: 65%;
      .contentItem {
        padding-right: 10px;
        padding-left: 20px;
      }
    }
    &.uk {
      .swipe {
        top: -${({ pageHeight }) => 10.5 * (pageHeight / 100)}px;
      }
    }
    &.et {
      .title {
        margin-left: 0;
      }
      .firstHalf .contentItem {
        padding-left: 10px;
      }
      .swipe {
        top: -${({ pageHeight }) => 13 * (pageHeight / 100)}px;
      }
    }
    &.en {
      .title {
        margin-left: -5px;
      }
      .swipe {
        top: -${({ pageHeight }) => pageHeight / 100}px;
      }
      .firstHalf {
        flex-basis: 37%;
        .contentItem {
          padding: 2px 0 2px 10px;
        }
      }
      .secondHalf {
        flex-basis: 40%;
        .contentItem {
          padding: 2px 0 2px 25px;
        }
      }
    }
  }

  @media only screen and (max-width: 1010px) {
    //font-size: 22px;
  }

  @media only screen and (max-height: 360px) {
    //font-size: 13px;
  }

  @media only screen and (max-height: 325px) {
    //font-size: 12px;
  }
`

export const StyledWebsiteTeam = styled.div`
  position: absolute;
  left: 50%;
  top: 7%;
  right: 5%;
  color: ${theme.light};
  bottom: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    font-size: 2.3vw;
    text-align: center;
    margin-bottom: 1.36vw;
  }

  .row {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0.68vw 0;
    text-align: center;
    white-space: pre-line;
  }

  .institutions {
    margin-top: 2vw;
    .role {
      font-size: 1.3vw;
    }

    .row {
      flex-direction: row;
      text-align: left;
      margin-left: 5vw;

      &.UI {
        margin-top: -0.8vw;
      }
    }
  }

  .role {
    font-size: 1.6vw;
  }

  .name {
    font-size: 2vw;
  }

  && .UILogo {
    height: 2.3vw;
    margin-right: 1.6vw;
    max-height: initial;
    margin-left: -0.9vw;
  }

  .UKFLogo {
    margin-left: -2.6vw;
    margin-right: -0.3vw;
    cursor: initial;
    svg {
      width: 6.7vw;
      height: auto;
    }
  }

  &.mobile {
    top: 4vh;
    .title {
      font-size: 4vh;
      margin-bottom: 1vh;
    }
    .row {
      margin: 0.8vh 0;
    }
    .role {
      font-size: 3vh;
    }
    .name {
      font-size: 3.5vh;
    }

    && .UILogo {
      height: 4.1vh;
      margin-right: 2vh;
      max-height: initial;
      margin-left: -1vh;
    }
    .UKFLogo {
      margin-left: -4.5vh;
      margin-right: -1vh;
      svg {
        width: 12.5vh;
        height: auto;
      }
    }
    .institutions {
      margin-top: 0;
      .role {
        font-size: 3vh;
      }

      .row {
        margin-left: 4.9vh;

        &.UI {
          margin-top: -1.9vh;
        }
      }

      &.uk {
        margin-left: 5.3vh;
        line-height: 2.5vh;
        .UI {
          margin-top: -1vh;
        }
      }
    }
  }
`
