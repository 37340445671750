import React, { useEffect, useRef, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { pageview } from 'react-ga'
import ReactTooltip from 'react-tooltip'
import { deviceType } from 'detect-it'
import { useTranslation } from 'react-i18next'
import { SwiperSlide, Swiper } from 'swiper/react'
import SwiperCore, { EffectFade, Keyboard, Lazy, Zoom } from 'swiper'

import { MOBILE_PAGES, PAGES } from '../assets/images/data'
import { StyledWrapper } from './styled'
import Page from './components/Page'
import { useElementRect } from '../hooks/useElementRect'
import { PAGES_TO_SLIDES, SLIDES_TO_PAGES } from '../assets/images/data'
import { TRANSLATIONS_POS } from './components/translationsPosition'
import { useWindowParams } from '../hooks/useWindowWidth'
import { isFullScreen } from '../utils/fullScreen'
import MobilePage from './MobilePage'
import CheckDeviceMode from './components/CheckDeviceMode'

import 'swiper/swiper.min.css'
import 'swiper/components/effect-fade/effect-fade.min.css'
import 'swiper/components/zoom/zoom.min.css'
import 'swiper/components/lazy/lazy.min.css'

SwiperCore.use([EffectFade, Zoom, Lazy, Keyboard])

const Book = () => {
  const { t, i18n } = useTranslation(['texts', 'common'])
  const lng = i18n.language
  const history = useHistory()
  let location = useLocation()
  const { page } = useParams()

  useEffect(() => {
    pageview(location?.pathname + location?.search)
  }, [location])

  const pageToCountRef = useRef()
  const { height: pageHeight } = useElementRect(pageToCountRef)
  const { width, height } = useWindowParams()
  // const isTouchDevice = deviceType === 'touchOnly'
  const isMobile = deviceType === 'touchOnly' || width < 860

  const BOOK_PAGES = PAGES

  // const [showGif, setShowGif] = useState(false)
  const [hoveredElementId, setHoveredElementId] = useState(null)

  const [isFullscreenState, setIsFullscreenState] = useState(isFullScreen)

  const [swiper, setSwiper] = useState(null)
  const [zoom, setZoom] = useState(false)

  const toggleFS = () => {
    if (document.fullscreenElement) {
      setIsFullscreenState(true)
    } else {
      setIsFullscreenState(false)
    }
  }

  useEffect(() => {
    document.addEventListener('fullscreenchange', () => {
      toggleFS()
    })
    return document.removeEventListener('fullscreenchange', () => {
      toggleFS()
    })
  }, [])

  const bookCentered = height - pageHeight > 150
  const isLastPage = PAGES_TO_SLIDES[page] === MOBILE_PAGES.length - 1
  // const onChangeOrientation = (data) => {
  //   console.log(data)
  // }

  // if (showGif) {
  //   return (
  //     <StyledWrapper justifyContent="center">
  //       <StyledPage className="count">
  //         <img src={BOOK_PAGES[1]} alt="countImg0" ref={pageToCountRef} />
  //       </StyledPage>
  //       {/*<img src={gif.src} alt="cartoon" className="gif" />*/}
  //     </StyledWrapper>
  //   )
  // }

  useEffect(() => {
    setHoveredElementId(null)
  }, [page, setHoveredElementId])

  useEffect(() => {
    if (page === 'content') {
      swiper?.zoom.disable()
    } else if (!swiper?.zoom.enabled) {
      swiper?.zoom.enable()
    }
  }, [page, swiper])

  const onSwipeInit = (swiper) => {
    swiper.slideTo(PAGES_TO_SLIDES[+page])
  }

  const onSlideChange = (data) => {
    history.replace(`/page/${SLIDES_TO_PAGES[data.activeIndex]}`)
  }

  const onZoomChange = (data) => {
    setZoom(data.zoom.currentScale === 1)
    setHoveredElementId(null)
  }

  if (width < height) {
    return <CheckDeviceMode pageToCountRef={pageToCountRef} t={t} BOOK_PAGES={BOOK_PAGES} />
  }

  return (
    <StyledWrapper
      zoom={zoom}
      marginTop={bookCentered && (height - pageHeight) / 2.5}
      className={`${isFullscreenState ? 'fullscreen' : ''}${isMobile ? ' mobile' : ''}${
        bookCentered ? ' centered' : ''
      }`}
      isFullscreen={isFullscreenState}
      item={TRANSLATIONS_POS[page] && TRANSLATIONS_POS[page].items.find((item) => item.name === hoveredElementId)}
    >
      {lng !== 'uk' && (
        <ReactTooltip
          id={hoveredElementId}
          type="light"
          className="tooltipTranslation"
          getContent={() => t(hoveredElementId)}
          clickable
          // effect="float"
          // place={deviceType === 'touchOnly' ? 'right' : ''}
          // effect={deviceType === 'touchOnly' ? 'solid' : 'float'}
        />
      )}
      <Swiper
        simulateTouch={isMobile}
        onSwiper={setSwiper}
        keyboard={{ enabled: true }}
        zoom={isMobile ? { maxRatio: 2 } : undefined}
        lazy={{ loadPrevNext: true }}
        effect="fade"
        preloadImages={false}
        onSlideChange={onSlideChange}
        onInit={onSwipeInit}
        onZoomChange={onZoomChange}
      >
        {MOBILE_PAGES.map((src, i) => {
          const pageProps = {
            isFullscreenState: isFullscreenState,
            page: page,
            i: i,
            src: src,
            hoveredElementId: hoveredElementId,
            setHoveredElementId: setHoveredElementId,
            zoom: zoom,
            setZoom: setZoom,
            swiper: swiper,
            pageToCountRef: pageToCountRef,
            isLastPage: isLastPage,
          }
          return (
            <SwiperSlide key={i}>
              {isMobile ? <MobilePage t={t} lng={lng} {...pageProps} /> : <Page {...pageProps} />}
            </SwiperSlide>
          )
        })}
      </Swiper>
    </StyledWrapper>
  )
}

export default Book
