const theme = {
  dark: '#000000',
  gray: '#897d7a',
  gray2: '#7C746B',
  grayTransparent: 'rgba(137, 125, 122, 0.4)',
  light:  '#DCCBA3',
  white: '#DAD0B9',
  whiteTransparent: 'rgba(255, 255, 255, 1)',
  lightTransparent: 'rgba(222, 184, 135, 0.7)',
  darkTransparent: 'rgba(0, 0, 0, 0.4)',
}

export default theme
