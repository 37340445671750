import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Book from './Book'
import Home from './Home'
// import NotFound from './NotFound'

// export const GifContext = createContext(false)

// const ContextWrapper = ({ children }) => {
//   return <GifContext.Provider value={gif}>{children}</GifContext.Provider>
// }

function App() {
  return (
    <Switch>
      <Route exact path="/page/:page">
        {/*<ContextWrapper>*/}
        <Book />
        {/*</ContextWrapper>*/}
      </Route>
      <Route path="/">
        {/*<ContextWrapper>*/}
        <Home />
        {/*</ContextWrapper>*/}
      </Route>

      {/*<Route exact path="/404" component={NotFound} />*/}
      {/*<Redirect to="/404" />*/}
    </Switch>
  )
}

export default App
