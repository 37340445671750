// eslint-disable-next-line import/no-anonymous-default-export
export default {
  common: {
    kazkaProKonyka: 'Казка про коника',
    landscape: 'Цю книгу можливо переглядати тільки в альбомному режимі.',
    // mobile: 'Цю книгу краще переглядати на десктопі. Будь ласка, зайдіть на сайт з комп’ютеру.',
    rotate: 'Будь ласка, перевірте налаштування повороту екрану та переверніть ваш пристрій горизонтально.',
    search: '',
    zoomIn: '',
    zoomOut: '',
    createdWithHelpOfUkrInstitute: 'Cайт створено за підтримки Українського Інституту',
    howToTranslateTip: '',
    mobileTranslateTip: '',
    mobileZoomTip: 'Щоб приблизити\nчи віддалити картинку,\nвикористовуйте\nподвійне натискання.',
    swipe: 'Свайп',
  },
  contentTexts: {
    content: 'Зміст',
    swipeText: 'Свайпніть, щоб іти далі',
  },
  content: {
    volunteers_1: 'ст.1 Коник і Волонтери',
    dog_5: 'ст.5 Коник і Песик',
    cows_9: 'ст.9 Коник і Корови',
    mouse_13: 'ст.13 Коник і Миша',
    fish_17: 'ст.17 Коник і Риба',
    goat_21: 'ст.21 Коник і Коза',
    bird_25: 'ст.25 Коник і Пташка',
    hare_29: 'ст.29 Коник і Заєць',
    cats_33: 'ст.33 Коник і 2 Коти',
    bumblebees_37: 'ст.37 Коник і Джмелі',
    oak_41: 'ст.41 Коник і Дуб',
    owl_49: 'ст.49 Коник і Сич',
    sanctuary_53: 'ст.53 Коник і Санктуаріум',
    gratitude_55: 'ст.55 Подяка Естонії',
    film_57: 'ст.57 Документальний Фільм',
    author_58: 'ст.58 Автор Книги',
  },
  last: {
    nadSaitomPracuvaly: 'Над сайтом працювали:',
    avtorKnygy: 'Автор Книги',
    avtorKnygyName: 'Анатолій Лютюк',
    avtoryProektu: 'Автори проекту',
    elektronniShryfty: 'Електронні шрифти',
    rozrobkaSaytu: 'Розробка веб-сайту',
    perekladyNaEstonsku: 'Переклади на естонську мову',
    perekladyNaAngl: 'Переклади на англійську мову',
    finansy: 'Фінанси',
    avtoryProektuName: 'Уляна Осовська\nДенис Страшний',
    elektronniShryftyName: 'Мара Забашта',
    rozrobkaSaytuName: 'Олександра Гарагуля',
    perekladyNaEstonskuName: 'Катерина Новак-Ботнар',
    perekladyNaAnglName: 'Уляна Федоряченко',
    finansyName: 'Уляна Осовська\nОлександра Глущенко',
    knygaStvorenaZaPidtrymkyUKF: 'Книга створена за підтримки Українського культурного фонду',
    saytStvorenyjZaPidtrymkyUkrInst:
      'Сайт створений за підтримки Українського інституту в рамках програми "proMotion"',
  },
}
