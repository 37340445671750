// eslint-disable-next-line import/no-anonymous-default-export
export default {
  common: {
    kazkaProKonyka: 'Tales of a Toy Horse',
    landscape: 'This book can only be viewed in the landscape mode.',
    rotate: 'Please check your screen rotation settings and rotate your device horizontally.',
    // mobile: 'This book is best viewed on desktop. Please come back from the computer.',
    search: '',
    zoomIn: '',
    zoomOut: '',
    createdWithHelpOfUkrInstitute: 'The site was created with the support of the Ukrainian Institute',
    howToTranslateTip: 'Move cursor over the text\nto see the translation.',
    mobileTranslateTip: 'Click on the text\nto see the translation.',
    mobileZoomTip: 'Use double click\nto zoom in or out.',
    swipe: 'Swipe',
  },
  contentTexts: {
    Content: 'Content',
    swipeText: 'Swipe to next page',
  },
  content: {
    volunteers_1: 'p.1 The Horse and the Volunteers',
    dog_5: 'p.5 The Horse and the Doggie',
    cows_9: 'p.9 The Horse and the Cows',
    mouse_13: 'p.13 The Horse and the Mouse',
    fish_17: 'p.17 The Horse and the Fish',
    goat_21: 'p.21 The Horse and the Goat',
    bird_25: 'p.25 The Horse and the Bird',
    hare_29: 'p.29 The Horse and the Hare',
    cats_33: 'p.33 The Horse and 2 Cats',
    bumblebees_37: 'p.37 The Horse and the Bumblebees',
    oak_41: 'p.41 The Horse and the Oak Tree',
    owl_49: 'p.49 The Horse and the Owl',
    sanctuary_53: 'p.53 The Horse and the Sanctuary',
    gratitude_55: 'p.55 Gratitude to Estonia',
    film_57: 'p.57 Documentary',
    author_58: 'p.58 Author of the Book',
  },
  last: {
    nadSaitomPracuvaly: 'Website team:',
    avtorKnygy: 'Author of the Book',
    avtorKnygyName: 'Anatoly Lyutyuk',
    avtoryProektu: 'Authors of the project',
    elektronniShryfty: 'Web fonts',
    rozrobkaSaytu: 'Website development',
    perekladyNaEstonsku: 'Translation into Estonian',
    perekladyNaAngl: 'Translation into English',
    finansy: 'Finances',
    avtoryProektuName: 'Ulyana Osovska\nDenis Strashny',
    elektronniShryftyName: 'Mara Zabashta',
    rozrobkaSaytuName: 'Oleksandra Garagulya',
    perekladyNaEstonskuName: 'Catherine Novak-Botnar',
    perekladyNaAnglName: 'Ulyana Fedoryachenko',
    finansyName: 'Ulyana Osovska\nOleksandra Hluschenko',
    knygaStvorenaZaPidtrymkyUKF: 'The Book was created with the support of Ukrainian Cultural Foundation',
    saytStvorenyjZaPidtrymkyUkrInst: 'The site was created with the support of the Ukrainian Institute',
  },
  texts: {
    //page0
    year: '2014-2020 year',
    kazkaProKonyka: 'Tales of a Toy Horse',
    knygaProLiudejZviriv: 'A book about people, animals and kindness in war',
    anatolijLiutiuk: 'Anatoly Lyutyuk and Friends',
    //page1
    donbas: 'Donbas',
    mistaZliva1: 'Lugansk, Donetsk, Avdijivka, Pisky, Vodiane',
    mistaZliva2: 'Lugansk, Donetsk, Avdijivka, Pisky, Vodiane',
    mistaSprava1: 'Kyiv, Poltava',
    mistaSprava2: 'Ternopil, Lviv, Ivano-Frankivsk, Vyzhnytsia',
    mistaSprava3: 'Ternopil, Lviv, Ivano-Frankivsk, Vyzhnytsia',
    jakKonykVolonteromStavTitle: 'How the Horse  became a volunteer\n',
    jakKonykVolonteromStavText:
      '\t1.\tThe Horse was born  in Vyzhnytsia, in an art school.\n\n' +
      '\t2.\tHe was dreaming of wings to fly over the Carpathians\n\n' +
      '\t3.\tWhen the Big Trouble came to Ukraine, he made himself a pair of wings and flew to Donbass to help people.',
    mistoVSviti: 'A city where Horses get together to help people.\n',
    //page2_3
    papirDliaKnygy:
      'The paper for this book is made with the addition of fabric from the clothes of friend Victor and Mr. Michael with the permission of their relatives',
    viddalySvojeZhyttia: 'They gave their lives... Helping people...\n',
    miscePamjati: 'Place of commemoration\n',
    z2014RokuRadaCerkvivEstonii:
      'In 2014 Council of Churches in Estonia organized a nationwide “Prayer for Ukraine”. Since then the daily prayer for those who gave their lives for Ukraine is held in our church by the “Place of commemoration”.\n',
    viktor: 'Viktor Hurnyak\n1987-2014\n',
    viktorText:
      'Died evacuating wounded people from shelling.\nJournalist, soldier, newspaper photographer, volunteer, “Plast” member.\n',
    myhajlo: 'Mykhailo Stasiv\n' + '1968-2016\n',
    myhajloText:
      'Died of a heart attack after coming back from war.\nSaved hundreds of wounded people.\nThe doctor who operated in the trench lines and blindages near Debaltseve.\n',
    olenaTaVolodymyr: 'Olena and Volodymyr Kulish\n',
    olenaTaVolodymyrText:
      'Were shot in 2014 for bringing food to the  people in the war zone.\nThey were shot together with seven dogs which they sheltered in their house. \n',
    //page4_5
    angelZTernopolia1: 'Angel from Ternopil',
    angelZTernopolia2: 'Angel from Ternopil',
    konykIPesykTitle: 'The Horse and the Doggie\n',
    konykIPesyk1:
      '\t1.\tOnce in the steppe a bomb was falling down right on the Horse. A Doggie called Rex shielded the Horse with his body and was injured.\n',
    konykJakogoSpasPesyk: 'The Horse who was rescued by Rex the Doggie.\n',
    konykIPesyk2: '\t2.\tThe Doggie survived.\n',
    konykIPesyk3: '\t3.\tThe Horse is carrying injured Rex to hospital and is trying  to cure the Doggie.\n',
    //page6_7
    dobriLiudySpaslyPesyka: 'Kind people saved the injured Doggie Rex\n',
    blagodijnyjFond:
      'Charity Organization “ASAP EMC Hottabych”\n' +
      '\t•\tRex was saved by ASAP: Oleksandr, call sign “Kabanchyk”; Roman, call sign “Romeo”; Nadiyka. \n' +
      '\t•\tRex was injured around Zhovanka village, near  Mayorsk.\n' +
      '\t•\tThen  doctors from the Chasiv Yar hospital were saving Rex.\n' +
      '\t•\tThen Rex was brought to Toretsk. Surgeon Oleksii and anesthesiologist Viktor operarted on him. The injury was defined as a mine-blast trauma. 15 shrapnel pieces were removed from the dog’s body.\n' +
      '\t•\tThis is how Rex the Doggie saved his owner Viktor and his friend.',
    pesykReksSpas: 'Rex the Doggie saved the kind people',
    lystRomashky:
      'Hello!\n' +
      'I’m “Romashka”, that’s my nickname at war. I would like to tell you about our Rex. This dog  shielded two soldiers with his body and was heavily injured. After he had a surgery we were nursing him for 4 months and people were sending him treats from all over the world. Now Rex is at home with his owner.\n\n' +
      'Sincerely, Romashka. Rotation senior at the time.\n\n' +
      'P.S. I am sending the bandage package that we used for Rex.\n',
    reksZakryv2bijciv: 'Rex shielded 2 soldiers with his body and was injured\n',
    likyJakymyReksaVyvodyly: 'The medicine (package) which Romashka used to reduce his state of shock.\n',
    reksPisliaNadannia: 'Rex  after treatment.\n',
    travaZBlokpostu: 'The grass from the block post near Zaytseve.\n',
    //page8_9
    konIKorovy: 'The Horse \nand Cows',
    minomet: 'Gun-mortar 2B9 “Vasilek” with a machine-gun and grenade launcher.\n',
    spalyvKorovamHatkuJabluniu: '\t1.\tBurned Cows’ home.\nShot Yana the Cow.\nBroke an apple tree.',
    konykVytiagujeZVogniu: '\t4.\tThe Horse rescues Chornushka getting her out of the fire.\n',
    rozbytaJablunia: '\t2.\tBroken apple tree',
    konIKorovy3: '\t3.\tChornushka the Cow waves her tail and shoos away the bullets as if they were flies.',
    //page10_11
    ogoloShenniaZiStovpiv: 'Advertisements from the street poles in Maryinka.\n',
    odnaGryvnia: '1 Hryvna',
    pochatokIstorijiLyst: 'Beginning of the story (a letter from Mykhailo Ukhman)',
    lystText:
      'Yakiv Lukich, a resident of Maryinka, who is living right at the frontline. This old blind man has a cow, rescued by volunteers and volunteer soldiers during bombardment.\n\nIf you are interested - let me know. I will be happy to help.\n\nSincerely yours, Mykhailo Ukhman, journalist, volunteer soldier.',
    hlivChornushy: '',
    jakivLukich: 'Yakiv Lukych',
    jakivLukichText:
      'Yakiv Lukych told us about the bombardment of Maryinka on the 25th of November 2016. During that shelling one grenade got into Yakiv Lukich’s cattle-shed. The shed took fire and the kind neighbours came running to help rescue the animals from the burning shed. They rescued the cow Chornushka and two cats.',
    myhajloUhmanText:
      'Mykhailo Ukhman, a military journalist, brought us (Anatolii Liutiuk, Uliana Osovska, Denys Strashnyi) to  Maksymilyanivka village to meet an interesting person, whose life is tied to the war tragedy in Donbass.\n',
    chornushka: 'Chornushka',
    chornushkaText:
      'Unfortunately the cow Yana, the dog Zhuzha and  some swallows that were in the nest burnt alive.\n\nChornushka is now living in Maryinka village and has given birth to a calf. \n',
    dobriLiudySpaslyKorovu: 'Kind neighbours rescued the cow.\n',
    //page12_13
    angelZLutska: 'Angel from Lutsk',
    kinIMysha: 'The Horse and\n the Mouse\n',
    kinIMysha1: '\t1.\tThe Horse, who was afraid of mice.\n',
    kinIMysha2: '\t2.\tA tank that smashed the Church, the house of the Mouse and damaged the flowers.\n',
    kinIMysha3: '\t3.\tThe Horse felt sorry for the poor Mouse and built a new house for it.\n',
    //page14_15
    HannaTerjanik: 'Hanna Terianik\n',
    HannaTerjanikText:
      'A volunteer from Dnipro.\n' +
      'She helped evacuate wounded people from the frontline. During the first winter of the war, in 2014-2015 (February) in the Debaltseve area  she was carrying out a soldier with a leg injury. Call sign “Siryi”. In order to numb the pain Siryi was telling Hanna stories of the ATO zone life: about bombardments, tamed  mice  etc.',
    rozpiznavalnyjPrapor: 'The identification flag from Hanna’s car.\n',
    chekyNaPalne: 'The receipts for fuel used to go to the frontline trips.\n', // нет в разметке
    travynkaZirvana: 'A blade of grass from the yard of the hospital where Hanna used to bring the wounded.\n',
    knygaAnnyTerijak: 'Read Hanna Terianik’s book “Fellow Travellers”\n', // нет в разметке
    myshiDonbasu: 'Mice of Donbass help people',
    dopomagajutLiudiam: 'help people\n', // нет в разметке
    myshiDonbasu1:
      'Of course, mice cause much damage to soldiers (sometimes they even eat their cigarette supplies). But sometimes those little creatures, committing their lives to soldiers, take food from their hands, let the soldiers pet them and live in the nests made specially for them.  That’s some kind of  “trench joy”...',
    myshiDonbasu2:
      'We brought humanitarian aid for the refugees in 2016. 15.11.2016 I spent the night in Kramatorsk, in “Kyiv” regiment settlement.\n\n16.11.2016 I met with a soldier who went through 4 wars: Afganistan, Karabakh, Chechnia, Georgia. Now he is serving in Donbass. Call sign Vadim.\n\n' +
      'He told me that in 2015  in Donetsk region there was a soldier with him, who made a nest for a mouse, fed it with dried bread and talked with it at night. The mouse didn’t  trust other soldiers. Such a story. 16.11.2016',
    vTojChasVPiskah:
      'At that time the 95 subdivision, volunteer soldiers “Karpatska Sich” and other volunteers were in Pisky.\n',
    maliunky: 'A sketch of the trips to Donbass. Stories.\n',
    //page16_17
    angelZHersonu1: 'Angel from Kherson',
    angelZHersonu2: 'Angel from Kherson',
    konykIRyba: 'The Horse and\n the Fish\n',
    konykIRyba1: '\t1.\tA grenade launcher ruined a dam.\n',
    konykIRyba2:
      '\t2.\tPoor Fish, Crawfish, Frogs, Birds, Water Lilies and Water Striders didn’t have a place to live.\n',
    konykIRyba3: '\t3.\tThe Horse repairs the dam.\n',
    granatomet: 'grenade launcher',
    //page18_19
    childrenFromKarlivka: 'Children from Karlivka\n',
    childrenFromKarlivkaText:
      '5.12.2017 I overheard three teenagers speaking in a shop in Karlivka: “Let’s go to the pond to stun the fish?”. I don’t know whether they went there to stun the fish or not, but I heard from adults that children go to the mine fields and remove the mines and grenades from trip wires. \n' +
      'In Shchastia 4 teenagers took the grenade off the trip wire in order to stun the fish.',
    stavokTaLis:
      'The pond and the forests are popular places among children in Donbass to spend their free time, but now those places have become dangerous.',
    rybalkaBezVudok: 'Fishing without rods - fish are jammed with mines and grenades',
    lystShkoliara: 'A letter of a pupil to the teacher.\n',
    lystShkoliaraText:
      'Hello, \n' +
      'I have spent my summer well. I went to the pond. Also I ate berries. I did my homework and learned the rules. And I went sunbathing.\n' +
      'Your pupil Sh.',
    stavok: 'The Pond\n',
    stavokText:
      'On the 9th of March 2016 we (Sanitar, Lys, Anna Brit) came to the pond in the suburbs of Donetsk. Lys told us that people often throw grenades into this pond to stun the fish. They also blew the dam up with a mine. \n' +
      'Good people repaired the dam and the bridge with the help of volunteers.\n' +
      'The pond is 4km away from the frontline, at the side of Pisky.',
    dobriLiudyRemontuyut: 'Good people are repairing the dams and don’t throw mines and grenades into the ponds.\n',
    diakuyuShoOberigaete: 'Thank you for protecting us!\n',
    //page20_21
    konykIKoza: 'The Horse and\n the Goat\n',
    konykIKoza1: '\t1.\tThe SPG “Savushka” is shooting at  a Goat.\n',
    konykIKoza2: '\t2.\tThe Goat and her goatlings are tied to a peg.\n',
    konykIKoza3: '\t3.\tThe Horse catapulted a stone with a rope to drag the peg out and free the Goat and her kids.\n',
    konykIKoza4: '\t4.\tThe Horse gets the Goat and her kids out from the shelling.\n',
    pidAvdiivkoiu: 'Near Avdiyivka',
    angelZIvanoFrankovsku1: 'Angel from Ivano-Frankivsk',
    angelZIvanoFrankovsku2: 'Angel from Ivano-Frankivsk',
    angelZIvanoFrankovsku3: 'Angel from Ivano-Frankivsk',
    //page22_23
    zrujnovanyiBudynky: 'Ruined buildings 25.04.2018\n',
    seloVodiane: 'Vodiane village',
    kozy4IBaba: '4 goats and\n Grandma Zoya\n',
    kozy4IBabaText:
      'Grandma Zoya lived in Vodyane village, she had a little household and kept 11 goats. She wasn’t expecting any bombardement so she tied the goats to graze in her vegetable garden. All of a sudden 16 shells fell and killed 7 goats, injured a cat, broke a plum tree and damaged the cabbage.\n',
    kozaMykolyFoto: 'Mykola Ivanovych’s goat from Vodyane village.\n',
    trava: 'Grass from Avdiyivka.',
    babaZojaFoto: 'Grandma Zoya in her house.\n',
    Avdijivka: 'Avdiyivka',
    kozaIkozliata: 'The Goat and her goatlings\n',
    kozaIkozliataText:
      'There was a Goat with her goatlings tied (or caught on something herself) at about 10 metres from the entrenchment in the field of fire near Avdiivka. When the shelling started, the goats got scared and started bleating loudly. A soldier called Mykola, with his head below the surface in the trench, tied a stone to a rope and  kept throwing it until he freed the goat.\n' +
      'From a night talk with Simon.\n',
    kozaIHlopec: 'The Goat and the Boy.\n',
    kozaIHlopecText:
      'On the 23rd of May 2017 Avdiivka was bombarded. They were shooting from “Grads”. 8 shells got into private houses. A young man was wounded by a shrapnel. Also, a barn was damaged and 15 ducks, a dog and a goat were killed. \n',
    //page24-25
    konykIPtashkaTitle: 'The Horse and \nthe Bird',
    konykIPtashka1: '\t1.\tThere was a nest on a tree. There was a trip wire hanging under the tree.',
    konykIPtashka2:
      '\t2.\tThe Bird started crying to warn the Horse about the trip wire someone had put on the tree.\n',
    konykIPtashka3:
      '\t3.\tThe Horse was new at the frontline, he didn’t know anything and anyone there, and if it wasn’t for that bird, he would have blown himself, his friends, the nestlings and everyone around 200 meters up into the air.\n',
    shnurSpletenyjZZhylky: 'Rigid braided wire.',
    granata: 'Grenade',
    //page26_27
    Donbas: 'Donbas',
    zaminovanaZemlia: 'The mined land.\n',
    naPidkontrolnijUriadu:
      'In the period from 2014 to 2018 on the territories governed by Ukraine 428 people were killed by mines, 21 of them were children.',
    zagynulo428liudej: '',
    dytyna21: '21 children',
    vybuhovonebezpechnyk37300: '373000 explosive items',
    photoAndrij: '',
    promka: 'Promka',
    promkaText:
      '15.11.2016 I was in Avdiivka, then we went to “Promka”. A little further down from the Citadel we approached a house ruined by bombs and got out of the car. I went to look at a bird sitting in a tree. The bird started crying and I turned back. Then the guys showed me a trip wire with a grenade from which  I had a narrow escape.\n',
    dobriLiudyRozminovujut: 'Good people are demining land.',
    maliuvanniaZNatury: 'A drawing from life.\n',
    photoUlizDenys: '',
    //page28_29
    angelZVinnyci1: 'Angel from Vinnytsia',
    angelZVinnyci2: 'Angel from Vinnytsia',
    konIZajac: 'The Horse and the Hare\n',
    konIZajac1: '\t1.\tA“Grad” vehicle is shooting at the Hare\n',
    konIZajac2: '\t2.\tThe Hare is hiding from the “Grad”.\n',
    konIZajac3: '\t3.\tThe Horse rescues the Hare\n',
    konykNaBMP: 'The Horse gets the Hare out of the fire on an IFV. \n',
    //page30_31
    zajciDonbasu: 'Hares of Donbas\n',
    stat: 'Statistics',
    statText:
      'The National agency of forest resources \n' +
      'Since 2014 and up to 2016 there were 1068 fires and 16621 ha of forest were burnt on the territory of Donetsk and Luhansk regions.\n' +
      '“Sviati Hory” reserve. Information about the number of hares.\n' +
      'There were 114 913 hares in 2012, and  their number reduced to 63 259 in 2015.\n',
    terytorijaOdnogoZajcia: 'The territory of one hare\n',
    terytorijaOdnogoZajciaText:
      'In April 2015 a soldier with a call sign Stryzh was on  combat duty. The entrenchment was well-equipped and there was an old uprooted acacia tree next to it. The night was quiet and there was “no serious fire”, just a sniper.\n\n' +
      'At about 2 AM a mine 82 fell and after it exploded Stryzh heard the sound of a small drum. He looked out and saw a hare under the roots of the acacia tree . The hare was looking in the direction the mine had come from,  tapping its feet , telling other hares and the whole world that it was its own territory. \n',
    pozyvnij: 'Call sign Stryzh, 16.112015, Avdiyivka\n',
    zajecVBMP2: 'The Hare in the IFV\n',
    zajecVBMP2Text:
      'On the 22nd of April 2015 an artillery bombardment started a fire in the “Sviati Hory” reserve. A hare jumped right into an open hatch of the IFV, trying to escape from shrapnel and fire. It wasn’t afraid of  the soldiers and in a while started eating dried bread right from their hands. They bought  a sack of carrots for it and let it stay in the IFV.\n',
    dobriLiudy: 'Kind people take care of hares\n',
    infoPojizd: 'The train Kyiv-Kostyntynivka, 4.12.2017, Ihor from Poltava, call sign “Khyzhak”.\n',
    //page32_33
    konykIDvaKoty: 'The Horse and \ntwo Cats\n',
    konykIDvaKoty1: '\t1.\tAGS wants to shoot all good Cats and smart Horses.\n',
    konykIDvaKoty2:
      '\t2.\tClever Cats Apelsyn and Persyk know when the AGS is going to shoot, so they jump and warn the Horse to hide in the basement.\n',
    konykIDvaKoty3: '\t3.\tThe Horse opened the basement and saved himself and the Cats.\n',
    konykIDvaKoty4:
      '\t4.\tThe basement belongs to a good Old Grandma. Everyone hides in that basement as no shells can ruin it.\n',
    kitApelsyn: 'Apelsyn the Cat is hiding from the fire.\n',
    konykHovajetsia: 'The Horse is hiding in the basement of the good Old Grandma.\n',
    //page34_35
    gusy: 'Gees',
    gusyText:
      'There was a goose named Hrysha in sector “A” of stanytsia Krymska, Luhansk region. He felt a shell or a mine 10-15  minutes before they were approaching, cried and hid in the trench. We also hid in the entrenchments. And also, Hrysha would pinch strangers.\n' +
      'Oleksandr from Donetsk, call sign “Bober”.',
    kozy: 'Goats',
    kozyText:
      'In Vodyane, Donetsk region, 7 goats were killed during bombardment. The four goats who survived started having a presentiment of the arrival of mines and shelling. They hide and warn people about it.\n',
    dykiSvyni: 'Wild pigs\n',
    dykiSvyniText:
      'In Luhansk region soldiers tamed wild pigs. Boars warn people about the arrival of strangers, as if they were dogs.\n',
    pesykyVijny: 'Dogs of War',
    pesykyVijnyText:
      '"Dogs of War" - a story about the war by Andrii Humeniuk (call sign "Celt").\n' +
      'Kelt’s dog friends:\n' +
      '\t•\tAlya - warned her owner about the enemy\n' +
      '\t•\tDozor - was crying over his owner, who was killed.\n' +
      '\t•\tBars - jumped onto  the trip wire and saved people\n' +
      '\t•\tBilochka - went out to meet her owner 1 hour before his arrival.\n' +
      '\t•\tRem - feels when the fire is about to happen and warns people.\n',
    andrijFoto: 'Andrii, call sign Celt',
    tvarynyDopomagajut: 'Animals help people in trouble\n',
    piven: 'The Rooster\n',
    pivenText:
      'A big rooster came to the frontline. He walked around all entrenchments, pecked a cat, picked up all breadcrumbs and stayed with the soldiers. Right before a bombardment the bird would start crying to warn everyone that they should hide. He was such a good landlord.\nKramatorsk, call sign “Shkala”\n',
    koty: 'Cats',
    kotyText:
      'There were two cats living in Maryinka in a private sector near  the old “Donetsk” stella. One was red, named Apelsyn. and the other one - Persyk. 5-10 minutes before bombardment they would run and jump around the house and scratch the hatch to the basement. Once the hatch  opened they would be the first to run down into the basement.\n' +
      'Maryinka, 2017.',
    erikaFoto: 'Erica, call sign "Weasel"',
    vKrasnogorivci: 'In Krasnohorivka a cat brought all her kittens into the basement right before the shelling.\n',
    //page36_37
    angelZMariupolia1: 'Angel from Mariupol in Slavne village',
    angelZMariupolia2: 'Angel from Mariupol in Slavne village',
    kinIDzhmeli: 'The Horse and\nthe Bumblebees\n',
    kinIDzhmeli1: '\t1.\tThe grenade-launcher was shooting at bumblebees.\n',
    kinIDzhmeli2: '\t2.\tRifle-attached grenade-launcher ruined bumblebee hive.  \n',
    kinIDzhmeli3: '\t3.\tThe Horse built a new hive for the bumblebees.\n',
    //page38_39
    fotoTaInfo: 'The photo and information by Evhen Kaplin, “Prolisok” humanitarian mission coordinator.\n',
    pidstavkyPidVulyky: 'Beehive holders made of parts of a mortar shell.\n',
    vulykyZrobleni: 'Beehives made of mortar shell boxes.\n',
    vulykyVijny: 'The Beehives of war\n',
    vulykyVijnyText:
      'A good, industrious man Serhii Kit lives in Slavne village. He made an apiary  right next to the frontline. The bees liked the hives made of those boxes, and the bees that had no hives came and stayed. Now there are 15 bee families who have their shelter and collect honey. \n',
    dzhmeliv1000: '1000 bumble bees and\nthe commander officer\n',
    dobriLiudySpaslyBdzhil: 'Kind people saved bees and bumblebees\n',
    dzhmeliv1000Text:
      'During the bombardment shells destroyed the entrenchment and many beehives. Bumblebees which survived were flying inside the dug-out trenches, there were thousands of them. There was shooting above and  bumble bees inside. And then the commander officer started talking to the bumblebees. He said: “ Hey,guys, if you sting anyone I will have to blow up a smoke grenade.”\nAnd then he told me “Can you imagine, Anatolii, they didn’t sting anyone! They are not people, you can make a deal with them”.\n' +
      'He moved the beehives which were not damaged into a safe place.\n\n' +
      'Night talk, Pisky, call sign Simon.\n',
    //page40_41
    angelZChernivciv1: 'Angel from Chernivtsi in Zolote',
    angelZChernivciv2: 'Angel from Chernivtsi in Zolote',
    konIDub: 'The Horse and \nthe Oak Tree\n',
    konIDub1: '\t1.\tA machine gun wants to kill the kind Horse.\n',
    konIDub2: '\t2.\tThe Horse asks an Oak Tree for help.\n',
    konIDub3: '\t3.\tThe Oak Tree shields the Horse from bullets and  mines.\n',
    konIDub4:
      '\t4.\tThe Horse picked up acorns from the Oak Tree that had saved him, made a seeder and planted an Oak grove.\n',
    sijalka: 'seeder',
    mina82mm: "82mm mine injured the Horse's leg",
    konyk: 'The Horse',
    dubovyjLis: 'oak forest',
    //page42_43
    spasenyjVojin: 'A saved warrior\n',
    spasenyjVojinText:
      'A huge old tree was right in front of me. I felt an urge to hug it. I approached and hugged it. My leg was sticking out. A mine flew. My leg is gone... I’m alive.\n' +
      'Tallinn, 2018, a talk with a soldier.',
    derevaSpasajut: 'Trees save\n',
    SpasenaHataTaRodyna: 'A saved\n house and \na family\n',
    SpasenaHataTaRodynaText:
      '11.04.2019 Zolote was bombarded. Some mines got into the yard of the house on 2 Horniatska street. One mine flew into a tree and exploded, but it never reached the house. The house was not damaged and the people in it were not harmed.',
    roztrosheneMynojuDerevo: 'A tree damaged by a mine.\nPhoto and info: Konstantyn Ilchenko',
    liudySadzhajut: 'People planting trees.\n',
    jaPalcemRobliuDirky:
      'I am digging holes with my finger in the ground at the edge of the trench. Someday I’m going to come back and there will be an oak forest growing here.\n',
    rozmovaZVojinom: 'A talk with a soldier, 2016, Hatne, Donetsk region.\n',
    budynkyLiudej: 'Houses, people and \ntheir children\n',
    spasenaShkola: 'A saved school and children.\n',
    spasenaShkolaText:
      '17.05.2018 A school in Svitlodarsk was bombarded. 370 pupils were evacuated. A shell got into a tree. The children were hugging the tree  saying: "Thank you, you are also alive, it must be painful."\n',
    povertajsiaZhyvym: 'Come back alive!',
    //page44_45??

    //page46_47??

    //page48_49
    angelZToshkivky1: 'Angel from Toshkivka village in Luganshchyna',
    angelZToshkivky2: 'Angel from Toshkivka village in Luganshchyna',
    konykISych: 'The Horse\n and the Owl\n',
    konykISych1:
      '\t1.\t29.10.2014 an Owl that was living on the school roof started knocking in the windows warning about the coming trouble.\n',
    konykISych2:
      '\t2.\tThe principal listened to the Owl, cancelled the lessons and sent all the pupils home at 11:00, and he did the right thing because\n',
    konykISych3:
      '\t3.\tAt 13:30, 29.10.2014 the “Grad” shells got into the school window and ruined half of the building. \n',
    toshkivskaShkola: 'The Toshkivka school',
    sychZShkoly1: 'The Owl from the Toshkivka school - friend of the schoolchildren',
    sychZShkoly2: 'The Owl from the Toshkivka school - friend of the schoolchildren',
    sychZShkoly3: 'The Owl from the Toshkivka school - friend of the schoolchildren',
    sychZShkoly4: 'The Owl from the Toshkivka school - friend of the schoolchildren',
    //page50_51
    podiaka: 'Gratitude \nto the Owl\n',
    shkolaFoto: 'The school in Toshkivka damaged by the shell.\n',
    dityFoto: 'The children from the Toshkivka school.\n',
    lystyVUKK: 'The letters to UKK (school children made horses)\n',
    raketaVidGragu: 'The “Grad” shell\n',
    raketaPopalaVShkolu: 'The shell hit the school.\n',
    direktor: 'The Toshkivka school principal Volodymyr Cherkess.\n',
    direktor29zhovtnia2014:
      'On the 29th of October, 2014 the school principal trusted the Owl and let the children go home.\n',
    dityZKatolyckojiShkoly:
      'Children from a Catholic school in Tartu, Estonia collected some money and created this Gratitude board to the Owl.\n',
    pidpysyDitej: 'Signatures of  the children who were saved by the Owl and  escaped the bombardment.\n',
    //page52_53
    kaligrafia: 'calligraphy',
    drukarnia: 'printing house',
    papirnia: 'paper mill',
    konykISanktuarium: 'The Horse and\nthe Sanctuary\n',
    molytvaIrobotaIChytannia: 'prayer and work and reading',
    konykPryviv: 'The Horse brought his new friends to the Sanctuary.\n',
    misceVJakomu: 'The Place, where people pray daily for the nature and those who were innocently harmed.\n',
    biblioteka: 'library',
    shkola: 'school',
    cerkva: 'church',
    muzej: 'museum',
    //page54_55
    spasybi: 'Thank you!\n',
    estonia: 'Estonia',
    konykVezePodiaku:
      'The Horse is taking the letter of gratitude to the kind people of Estonia from the grateful people of Ukraine.\n',
    //page56_57
    ukrkultfond: 'Ukrainian Cultural Foundation',
    eestifilmfest: 'Estonian Film Institute',
    docfilmkazkaprokonyka: 'Documentary film "Tales of a Toy Horse" directed by Ulyana Osovska and Denis Strashny',
    cejFilmMyPlanujemo:
      'We plan to show this film to viewers in Ukraine, Estonia, Finland, Sweden, Norway, Lithuania, Latvia, Germany, Italy, Poland, the Czech Republic and other countries.',
    Ukraina: 'Ukraine',
    surnames:
      '* Animation - Rodion Shub\n* Artists - Mara Zabashta, Masha Shub\n' +
      '* Sound - Vasily Yavtushenko\n' +
      '* Music composer - Anton Degtjarjov\n' +
      '* Anastasia Sidelnyk\n' +
      '* Volodymyr Favorskyj\n' +
      '* Pavlo Oleksienko\n' +
      '* Oleksandra Hluschenko\n' +
      '* Uljana Fedorachenko\n' +
      '* Anastasija Vesna\n' +
      '* Bohdan Kushnirchuk',
    kuliaJakuDobryjLikar:
      'The bullet that the good doctor pulled out of Denis, 25.02, in a week! A 9 mm bullet flew from a "Fort" pump-action rifle from Berkut.',
    denysFilmuvavMajdan:
      'Denis filmed the Maidan. After this shot 1 minute later he was wounded by a bullet. Kyiv. Institute street, February 18, 2014, 9:35 p.m.',
    andrijKumSestry: "Andrew - the godfather of Denis's sister",
    dobriLikariZa4Godyny:
      'Good doctors found this bullet in 4 hours, cutting Denis. (Denis is lying, anesthesiologist Andrew is standing)',
    ulianaVTojChasVidpravliala:
      'At that time, Ulyana was sending the wounded to Estonia for treatment with the E+ organization. And she met Denis. Then she came to Estonia on April 10, 2014. Denis was very happy about it, because he fell in love with Ulyana in the hospital and immediately 2 years later, on November 11, they had a wedding for 150 people.',
    zTogoChasuKrimDenysaVonaPoliubyla:
      'Since then, in addition to Denis, she has fallen in love with the Estonian Center of Ukrainian Culture. Denis also fell in love with our center. And with that love, they have been with us since 2014 to help people across the front line. Wherever we were, Ulyana and Denis were filming. From those travels they made a good film together with their talented Ukrainian and Estonian colleagues. I made this book with them and my Estonian colleagues at this time.',
    UlianaTaDenysZnimajut:
      'Ulyana Osovska and Denis Strashny are shooting a documentary film "Tales of a Toy Horse" in Pisky, Donetsk Region',
    //page58
    analolij: 'Anatoly Lyutyuk',
    text:
      'I live the life of a monk in peace. I belong to the oblates of St. Benedict at the Cistercian monastery in Norway and follow the rules of St. Benedict “Ora et Labora” (prayer and work). I read a prayer for the Environment every day. I have been creating the iconosphere in the material space for more than 20 years, which became the Tallinn Sanctuary (p. 52).\n\nTogether with like-minded people I collect stories about Kindness and preservation of the harmony of Man and Nature. From these stories we create books handmade by ancient technologies.\n\n' +
      '"Tales of a Toy Horse" is a book with real stories about People, Animals and Kindness at War, collected by me during humanitarian aid trips in the frontline zone of Donbass.',
  },
}
