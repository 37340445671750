import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import ReactGA from 'react-ga'

import './index.css'
import './fonts.css'
import './i18n'
import App from './App'
import reportWebVitals from './reportWebVitals'
import GlobalStyle from './globalStyles'

ReactGA.initialize('UA-189231691-1', {
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
})

ReactDOM.render(
  <BrowserRouter>
    <GlobalStyle />
    <App />
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
