import styled from 'styled-components'
import theme from '../theme'

export const StyledHome = styled.div`
  text-align: center;
  height: 100vh;
  //height: var(--vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: ${theme.light};
  width: 100vw;

  .logo {
    height: 40px;
    margin-right: 15px;
  }
  a {
    text-decoration: none;

    &:hover h1 {
      border-color: transparent;
    }
  }

  .h1 {
    font-size: 50px;
    border-bottom: 3px solid ${theme.light};
    transition: all ease-out 0.2s;
    margin: 0 40px;
  }

  &.checkDeviceMode {
    //flex-direction: column;
    justify-content: center;
    //width: calc(100vw + 80px);
    h1 {
      font-size: 25px;
      margin: 0 40px;
      border: none;
      line-height: 36px;
    }
  }

  @media only screen and (max-width: 800px) {
    .logo {
      height: 33px;
    }
  }
  @media only screen and (max-width: 680px) {
    .logo {
      height: 30px;
    }
  }
  @media only screen and (max-width: 700px) {
    //width: calc(100vw + 40px);

    .h1 {
      font-size: 30px;
      border-width: 2px;
      margin: 0 20px;
    }
  }
  @media only screen and (max-width: 370px) {
    &.checkDeviceMode {
      h1 {
        margin: 0 30px;
        font-size: 23px;
      }
    }
  }
  @media only screen and (max-width: 340px) {
    .h1 {
      font-size: 25px;
    }
  }
`
