import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { StyledHome } from './styled'
import { Link } from 'react-router-dom'
// import { StyledPage } from '../Book/styled'
// import { COVER } from '../data'
import Languages from './Languages/Languages'
import { useTranslation } from 'react-i18next'
// import { useWindowParams } from '../hooks/useWindowWidth'
// import { deviceType } from 'detect-it'
import ukrInstLogo from '../assets/images/Logo_of_the_Ukrainian_Institute.png'
import { enterFullScreen } from '../utils/fullScreen'
import { pageview } from 'react-ga'
// import { deviceType } from 'detect-it'
// import { useWindowParams } from '../hooks/useWindowWidth'

const Home = () => {
  const { t } = useTranslation('common')
  let location = useLocation()
  // const { width } = useWindowParams()
  // const isMobile = deviceType === 'touchOnly' || width < 860

  useEffect(() => {
    pageview(location?.pathname + location?.search)
  }, [location])

  return (
    <StyledHome>
      <div
        style={{
          display: 'flex',
          padding: '25px 20px 0px 25px',
          alignItems: 'center',
          alignSelf: 'flex-start',
          fontSize: 22,
          textAlign: 'left',
          maxWidth: '97vw',
          // marginRight: -15,
        }}
      >
        <img src={ukrInstLogo} alt="Ukrainian institute logo" className="logo" />
        {t('createdWithHelpOfUkrInstitute')}
      </div>
      <Languages className="bottom" />

      <div onClick={enterFullScreen}>
        <Link className="h1" to="/page/content">
          {t('kazkaProKonyka')}
        </Link>
      </div>
      <div style={{ height: 110 }} />

      {/*<StyledPage className="cover">*/}
      {/*  <img alt="cover" src={COVER} />*/}
      {/*</StyledPage>*/}
    </StyledHome>
  )
}

export default Home
