export const isFullScreen =
  window.document.fullscreenElement ||
  window.document.mozFullScreenElement ||
  window.document.webkitFullscreenElement ||
  window.document.msFullscreenElement

export const enterFullScreen = () => {
  const doc = window.document
  const docEl = doc.documentElement
  const requestFullScreen =
    docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen

  if (requestFullScreen && !isFullScreen) {
    requestFullScreen.call(docEl)
  }
}

export const exitFullScreen = () => {
  const doc = window.document
  const cancelFullScreen =
    doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen

  if (doc.fullscreenElement || doc.mozFullScreenElement || doc.webkitFullscreenElement || doc.msFullscreenElement) {
    cancelFullScreen.call(doc)
  }
}

export const canFullscreen = () =>{
  for (const key of [
    'exitFullscreen',
    'webkitExitFullscreen',
    'webkitCancelFullScreen',
    'mozCancelFullScreen',
    'msExitFullscreen',
  ]) {
    if (key in document) {
      return true;
    }
  }
  return false;
};