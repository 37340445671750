import { createGlobalStyle } from 'styled-components'
import { deviceType } from 'detect-it'

const GlobalStyle = createGlobalStyle`
html {
  overflow: ${deviceType === 'touchOnly' ? 'visible' : 'hidden'};
}

body {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  margin: 0;
  padding: 0;
  font-family: AnatolijCursive, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: ${deviceType === 'touchOnly' ? 'visible' : 'hidden'};
  --vh: 1vh;
  /*--vw: 100vw;*/
}

#root {
  height: inherit;
}

@media only screen and (max-width: 860px) {
  body, html {
    overflow-y: visible;
  }
}

body,
html {
  overscroll-behavior: none;
}

/*@supports (padding: max(0px)) {*/
/*body {*/
/*  padding-left: max(12px, env(safe-area-inset-left));*/
/*  padding-right: max(12px, env(safe-area-inset-right));*/
/*}*/
/*}*/

h1,
.h1, .header {
  font-family: AnatolijCapital, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a:visited,
a {
  color: unset;
}

a:hover {
  opacity: 0.7;
}
`

export default GlobalStyle
