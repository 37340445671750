import React from 'react'
import theme from '../../theme'

const Icons = {
  arrowLeft: ({ stroke = theme.lightTransparent, strokeWidth = 3, width = 33, height = 54, style, id }) => (
    <svg width={width} height={height} viewBox="0 0 11 18" fill="none" style={style} id={id}>
      <path d="M10 1L2 9L10 17" stroke={stroke} strokeWidth={strokeWidth} />
    </svg>
  ),
  arrowRight: ({ stroke = theme.lightTransparent, strokeWidth = 3, width = 33, height = 54, style, id }) => (
    <svg width={width} height={height} viewBox="0 0 11 18" fill="none" style={style} id={id}>
      <path d="M1 17L9 9L0.999999 1" stroke={stroke} strokeWidth={strokeWidth} />
    </svg>
  ),
  back: ({ fill = theme.black, width = 21, height = 14 }) => (
    <svg width={width} height={height} viewBox="0 0 21 14" fill={fill}>
      <path
        d="M18.7895 1.16667V5.83333H4.23316L8.19 1.645L6.63158 0L0 7L6.63158 14L8.19 12.355L4.23316 8.16667H21V1.16667H18.7895Z"
        fill={fill}
      />
    </svg>
  ),
  cross: ({ stroke = theme.white, strokeWidth = 2, width = 32, height = 32 }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none">
      <path d="M23.0596 7.46063L7.46061 23.0596" stroke={stroke} strokeWidth={strokeWidth} />
      <path d="M23.7373 23.0594L8.13835 7.46043" stroke={stroke} strokeWidth={strokeWidth} />
    </svg>
  ),
  exitFullscreen: ({ fill, width = 32, height = 32 }) => (
    <svg viewBox="0 0 32 32" width={width} height={height}>
      <polygon
        style={{ fill: fill || theme.lightTransparent }}
        points="24.586,27.414 29.172,32 32,29.172 27.414,24.586 32,20 20,20 20,32 			"
      />
      <polygon
        style={{ fill: fill || theme.lightTransparent }}
        points="0,12 12,12 12,0 7.414,4.586 2.875,0.043 0.047,2.871 4.586,7.414 			"
      />
      <polygon
        style={{ fill: fill || theme.lightTransparent }}
        points="0,29.172 2.828,32 7.414,27.414 12,32 12,20 0,20 4.586,24.586 			"
      />
      <polygon
        style={{ fill: fill || theme.lightTransparent }}
        points="20,12 32,12 27.414,7.414 31.961,2.871 29.133,0.043 24.586,4.586 20,0 			"
      />
    </svg>
  ),
  fullscreen: ({ fill, width = 23, height = 22 }) => (
    <svg width={width} height={height} viewBox="0 0 23 22">
      <path
        d="M3.22 17.38L0 14.3V22H8.05L4.83 18.92L9.821 14.168L8.188 12.606L3.22 17.38ZM19.78 4.62L23 7.7V0H14.95L18.17 3.08L13.179 7.832L14.812 9.394L19.78 4.62ZM18.17 18.92L14.95 22H23V14.3L19.78 17.38L14.812 12.606L13.179 14.168L18.1585 18.931L18.17 18.92ZM4.83 3.08L8.05 0H0V7.7L3.22 4.62L8.188 9.394L9.821 7.832L4.83 3.08Z"
        fill={fill || theme.lightTransparent}
      />
    </svg>
  ),
  home: ({ fill, width = 40, height = 40 }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 770.34 770.34"
      style={{ enableBackground: 'new 0 0 770.34 770.34' }}
      xmlSpace="preserve"
    >
      <polygon
        points="385.17,74.597 0,391.388 42.813,443.44 385.17,161.861 727.527,443.44 770.34,391.388"
        fill={fill || theme.lightTransparent}
      />
      <path
        d="M385.226,226.764l-248.622,205.19v193.396v58.219v12.174h174.432V562.53c-0.074,0-0.146,0-0.222,0
			c0-16.764,0-31.58,0-49.834c0.073,0,0.146,0,0.222,0v-25.465c0-17.629,0-33.473,0-53.518c17.587,0,31.355,0,48.094,0h100.54
			v262.027h174.066v-11.424v-58.967V432.702L385.226,226.764L385.226,226.764z"
        fill={fill || theme.lightTransparent}
      />
    </svg>
  ),
  minus: ({ fill, width = 6, height = 2 }) => (
    <svg width={width} height={height} viewBox="0 0 6 2">
      <rect width={width} height={height} fill={fill || theme.lightTransparent} />
    </svg>
  ),
  plus: ({ fill, width = 6, height = 6 }) => (
    <svg width={width} height={height} viewBox="0 0 6 6">
      <path d="M4 0H2V2H0V4H2V6H4V4H6V2H4V0Z" fill={fill || theme.lightTransparent} />
    </svg>
  ),
  search: ({ fill, width = 25, height = 25 }) => (
    <svg width={width || 124.524} height={height || 124.524} viewBox="0 0 124.524 124.524">
      <path
        d="M51,102.05c10.5,0,20.2-3.2,28.3-8.6l29.3,29.3c2.301,2.3,6.101,2.3,8.5,0l5.7-5.7c2.3-2.3,2.3-6.1,0-8.5L93.4,79.35
		c5.399-8.1,8.6-17.8,8.6-28.3c0-28.1-22.9-51-51-51c-28.1,0-51,22.9-51,51C0,79.149,22.8,102.05,51,102.05z M51,20.05
		c17.1,0,31,13.9,31,31c0,17.1-13.9,31-31,31c-17.1,0-31-13.9-31-31C20,33.95,33.9,20.05,51,20.05z"
        fill={fill || theme.lightTransparent}
      />
    </svg>
  ),
  enUkf: ({ width, height }) => (
    <svg
      enableBackground="new 0 0 633.9 485.2"
      height={height || 485.2}
      viewBox="0 0 633.9 485.2"
      width={width || 633.9}
    >
      <path
        d="m139.7 77.6c-19.2 3.4-45.7 22.8-45.7 58.4 0 55.2 44.4 71.2 71.9 73.9 40.7 3.9 66.7-24.5 66.7-57.2 0-79.7-73.7-78.4-92.9-75.1zm22.8 102.6c-23-1.6-36-11.1-36-40.9 0-15.2 5.3-25.1 24.4-29.7s50.2 2.3 50.2 37.7c0 14.9-11.2 34.8-38.6 32.9z"
        fill="#ffcc2d"
      />
      <path d="m542.5 233.6-87.3-1.7-2.1-25.6 94.9 5.1z" fill="#a3d2d9" />
      <path d="m523.2 275.3-87.3-1.7-2.1-25.5 94.9 5z" fill="#a3d2d9" />
      <path d="m182.7 225.4 6.5-.8s2.5-18.1-10.3-17.7c-12.7.4-16.8 17.7-16.7 35.6s1.6 35.2 15.1 34.8c13.4-.3 10.5-17.9 10.5-17.9l-6.8.7s2.8 10.4-3.3 10.4c-7.3 0-9.5-9.2-8.9-27.5.6-18.6 1.4-29.5 10-29.5 4.8.1 3.9 11.9 3.9 11.9z" />
      <path d="m300.7 184.9h-6.8l1.6-67.7h6.8z" />
      <path d="m355.1 185.5h-6.7l2.7-67.3h6.8z" />
      <path d="m405.4 369.4h-6.7l2.7-67.4 6.8.1z" />
      <path d="m222.8 167.1c-3.2-9-11.7-9.1-16.7-7.9l.5-14.2c18.5-2 19.6-27.9 19.6-27.9h-6.7c0 19.6-9.6 22.6-12.6 22.6l.6-22.5h-6.9l-1.9 67.6h7l.4-20.5c14.6-4.8 13.2 20.5 13.2 20.5h6.6s.8-6.5-3.1-17.7z" />
      <path d="m381.5 369.6h-6.1l1.6-67.1h6.2z" />
      <path d="m365.4 302.5 28.3-.5v5.1l-28.4.5z" />
      <path d="m274 277.3h-6.1l1.6-67.1h6.1z" />
      <path d="m257.9 210.1 28.3-.5v5.1l-28.4.5z" />
      <path d="m444.4 333.3c0-4.5-.2-7.9-.4-10.5v-.7c0-.4-.1-.8-.1-1.1-.9-12.3-3.8-19.1-14-19.1-9.4 0-12.9 6.7-13.9 19.3-.4 4.3-.4 7.4-.4 12.1v1.6 3.4c0 4.5.2 7.9.4 10.5v.7c0 .4.1.8.1 1.1.9 12.3 3.8 19.1 14 19.1 9.4 0 12.9-6.7 13.9-19.3.4-4.3.4-7.4.4-12.1v-1.6c0-1.2 0-2.4 0-3.4zm-14.4 29.7c-5.8 0-6.7-7.7-7.2-12.4 0-.1-.4-7.8-.4-15.4 0-7.2.4-14.2.4-14.4.6-6.5 1.4-12.4 7.2-12.4s6.7 7.7 7.2 12.4c0 .2.4 7.4.4 14.7 0 7.4-.4 14.8-.4 15-.5 6.5-1.3 12.5-7.2 12.5z" />
      <path d="m401.2 117.6 20.8 55-2-55h4.8l2.4 67.2h-6.9l-20.7-55.9.9 55.8-4.9.1-1-67.2z" />
      <path d="m315.7 117.6 20.8 55-2-55h4.8l2.4 67.2h-6.9l-20.7-55.9.9 55.8-4.9.1-1-67.2z" />
      <path d="m333.3 333.4c-.4-16.7-4.8-32.3-18.1-31.7l.4 4.1c6.9.6 10.8 12.5 11.1 27.3.4 17.1-1.7 28.8-9.5 30.8-1.9.5-5.7.7-5.9.7l-.1 4.9c2.3.1 7-.2 7.1-.2 13.2-1.8 15.3-19 15-35.9z" />
      <path d="m311.2 369.4-6.7.1 1.5-66.1 6.7-1.2z" />
      <path d="m305.8 307v-4s4.8-1.2 9.3-1.3.5 4.1.5 4.1c-4.7.1-9.8 1.2-9.8 1.2z" />
      <path d="m168.6 369.6h-6.6l1.2-66.6h6.3z" />
      <path d="m163 302.9 22.3.1-.1 5.1-22.1-.2z" />
      <path d="m163.4 336.8 20.3-.2.5 5.1-20.9.2z" />
      <path d="m204.6 256.2c0-1.5 0-4.3 0-12 0-20.9 0-34.2 0-34.2l-7-.1v34.8 11.6c0 9.6 1.5 21.1 13.8 21.1 11.8 0 13.8-8.1 13.8-21.1 0-4.2 0-6.8 0-11.9l-.1-34.3-6.6.2-.1 34.1v11.9c0 6.5.5 15.8-7.1 15.8-7.2 0-6.7-13-6.7-15.9z" />
      <path d="m170.6 164.4c0-1.5 0-4.3 0-12 0-20.9 0-35.3 0-35.3h-7v35.7 11.6c0 9.6 1.5 21.1 13.8 21.1 11.8 0 13.8-8.1 13.8-21.1 0-4.2 0-6.8 0-11.9l-.1-35.4h-6.6l-.1 35.4v11.9c0 6.5.5 15.8-7.1 15.8-7.2.1-6.7-12.8-6.7-15.8z" />
      <path d="m235.3 349.1c0-1.5 0-4.3 0-12 0-20.9 0-34.2 0-34.2l-7-.1v34.8 11.6c0 9.6 1.5 21.1 13.8 21.1 11.8 0 13.8-8.1 13.8-21.1 0-4.2 0-6.8 0-11.9l-.1-34.3-6.6.2-.1 34.1v11.9c0 6.5.5 15.8-7.1 15.8-7.2-.1-6.7-13-6.7-15.9z" />
      <path d="m299.6 256.2c0-1.5 0-4.3 0-12 0-20.9 0-34.2 0-34.2l-7-.1v34.8 11.6c0 9.6 1.5 21.1 13.8 21.1 11.8 0 13.8-8.1 13.8-21.1 0-4.2 0-6.8 0-11.9l-.1-34.3-6.6.2-.1 34.1v11.9c0 6.5.5 15.8-7.1 15.8-7.2 0-6.7-13-6.7-15.9z" />
      <path d="m237.1 159-.1 5.1c14.4-4.5 12.9 20.7 12.9 20.7h6.6s1-6.6-3-17.8c-3.1-8.9-11.4-9.1-16.4-8z" />
      <path d="m236.6 184.8h-6.4l2-67.2h6.6z" />
      <path d="m236.6 144.9s20.8.3 20.8-15.1c0-9.4-11.3-11.9-18.8-12.6-3.7-.3-6.4-.2-6.4-.2l-.1 5.2 6.3-.2s12.5-1.8 12.5 7.9c0 5.8-6 9.6-14 9.6z" />
      <path d="m334.1 251.5-.1 5.1c14.4-4.5 12.8 20.2 12.8 20.2h6.6s1.1-6.1-2.9-17.4c-3.1-8.8-11.4-9-16.4-7.9z" />
      <path d="m333.6 276.8h-6.4l2-66.7h6.6z" />
      <path d="m333.6 237.4s20.8.3 20.8-15.1c0-9.4-11.3-11.9-18.8-12.6-3.7-.3-6.4-.2-6.4-.2l-.1 5.2 6.3-.2s12.5-1.8 12.5 7.9c0 5.8-6 9.6-14 9.6z" />
      <path d="m234.5 209.7h6.7l-1.6 67.1h-6.4z" />
      <path d="m260.8 276.8-27.8.5.2-5.2 27.7-.4z" />
      <path d="m395 209.7h6.7l-1.6 67.1h-6.4z" />
      <path d="m421.3 276.8-27.8.5.2-5.2 27.7-.4z" />
      <path d="m266.8 158 14.6-1.2.7 4.9-15.9.7z" />
      <path d="m288.4 184.8-8.9-67.4-6.9-.6-13 68h5.6l9.6-53.8h.7l5.8 53.7z" />
      <path d="m344.4 342.7 14.6-1.2.7 4.9-15.9.7z" />
      <path d="m366 369.5-8.9-67.4-6.9-.6-13 68h5.6l9.6-53.8h.7l5.8 53.7z" />
      <path d="m367.3 157.9 14.6-1.2.8 5-16 .6z" />
      <path d="m389 184.8-9-67.5-6.9-.6-13 68.1 5.7-.1 9.5-53.7.7-.1 5.8 53.7z" />
      <path d="m364.8 250.4 14.6-1.2.8 5-16 .6z" />
      <path d="m386.5 277.3-9-67.5-6.9-.6-13 68.1 5.7-.1 9.5-53.7.7-.1 5.8 53.7z" />
      <path d="m459.2 302.3 20.9 55-2.1-55h4.8l2.4 67.2h-6.9l-20.7-55.9.9 55.8-4.9.1-1-67.2z" />
      <path d="m271.9 302.3 20.8 55-2.1-55h4.8l2.4 67.2h-6.9l-20.7-55.9.9 55.8-4.9.1-1-67.2z" />
      <path d="m220 334.1c0-4.5-.2-7.9-.4-10.5v-.7c0-.4-.1-.8-.1-1.1-.9-12.3-3.8-19.1-14-19.1-9.4 0-12.9 6.7-13.9 19.3-.4 4.3-.4 7.4-.4 12.1v1.6 3.4c0 4.5.2 7.9.4 10.5v.7c0 .4.1.8.1 1.1.9 12.3 3.8 19.1 14 19.1 9.4 0 12.9-6.7 13.9-19.3.4-4.3.4-7.4.4-12.1v-1.6c0-1.3 0-2.4 0-3.4zm-14.4 29.6c-5.8 0-6.7-7.7-7.2-12.4 0-.1-.4-7.8-.4-15.4 0-7.2.4-14.2.4-14.4.6-6.5 1.4-12.4 7.2-12.4s6.7 7.7 7.2 12.4c0 .2.4 7.4.4 14.7 0 7.4-.4 14.8-.4 15-.6 6.6-1.4 12.5-7.2 12.5z" />
    </svg>
  ),
  ukUkf: ({ width, height }) => (
    <svg
      enableBackground="new 0 0 633.9 485.2"
      height={height || 485.2}
      viewBox="0 0 633.9 485.2"
      width={width || 633.9}
    >
      <path
        d="m101.8 65.6c-19.2 3.4-45.7 22.8-45.7 58.4 0 55.2 44.4 71.2 71.9 73.9 40.7 3.9 66.7-24.5 66.7-57.2-.1-79.7-73.7-78.4-92.9-75.1zm22.8 102.6c-23-1.6-36-11.1-36-40.9 0-15.2 5.3-25.1 24.4-29.7s50.2 2.3 50.2 37.7c-.1 14.9-11.3 34.8-38.6 32.9z"
        fill="#ffcc2d"
      />
      <g fill="#1a1a1a">
        <path d="m246.3 395.4 19.1-75.7 7 .5s12.6 75.2 12.5 75.2h-7.5s-10.2-61.6-10.2-61.5h-.5s-8.4 43.7-14.5 61.5z" />
        <path d="m282 388.1-.9 7.3h-32v-7.3z" />
        <path d="m368.7 155.6 2.1-48.1h-7.6l-3.2 77.7 11.7-.1s15.8-1.4 15.8-15.2c-.1-10-11.9-13.9-18.8-14.3zm-1 23.8.9-18c3.9-.2 11.2-.1 11.2 9.3 0 6.7-8.5 8.9-12.1 8.7z" />
        <path d="m345.1 127.7 7.3-.9s2.8-20.4-11.6-19.9-18.9 19.9-18.8 40.1 1.9 39.6 17 39.2 11.9-20.2 11.9-20.2l-7.6.8s3.1 11.7-3.8 11.7c-8.2 0-10.7-10.3-10-31 .7-21 1.6-33.2 11.3-33.2 5.4 0 4.3 13.4 4.3 13.4z" />
        <path d="m180.6 165.6c-3.6-10.2-13.2-10.3-18.8-8.9l.6-16c20.8-2.3 22.1-31.4 22.1-31.4h-7.6c0 22-10.8 25.4-14.2 25.4l.7-25.4h-7.8l-2.1 75.9h7.9l.5-22.9c16.4-5.4 14.6 22.7 14.6 22.7h7.4c0 .2 1.2-6.7-3.3-19.4z" />
        <path d="m142 271.4c-3.6-10.2-13.2-10.3-18.8-8.9l.6-16c22.2-2.5 23-32.5 23-32.5h-7.6c0 22-11.7 26.5-15.1 26.5l.7-26.4h-7.8l-2.1 77h7.9l.5-22.9c16.4-5.4 14.6 22.7 14.6 22.7h7.4c-.1.1 1.1-6.8-3.3-19.5z" />
        <path d="m419.5 165.6c-3.6-10.2-13.2-10.3-18.8-8.9l.6-16c20.8-2.3 22.1-31.4 22.1-31.4h-7.6c0 22-10.8 25.4-14.2 25.4l.7-25.4h-7.8l-2.1 75.9h7.9l.5-22.9c16.4-5.4 14.6 22.7 14.6 22.7h7.4c0 .2 1.2-6.7-3.3-19.4z" />
        <path d="m196.8 185.6h-7.1l2.2-75.1h7.5z" />
        <path d="m196.9 141.2s23.5.4 23.5-17c0-10.6-12.8-13.4-21.2-14.2-4.2-.3-7.3-.2-7.3-.2l-.1 5.9 7.1-.2s14.1-2 14.1 8.9c0 6.5-6.7 10.8-15.8 10.8z" />
        <path d="m345.7 290.7h-7.1l2.2-75.1 7.4-.1z" />
        <path d="m345.8 246.3s23.5.4 23.5-17c0-10.6-12.8-13.4-21.2-14.2-4.2-.3-7.3-.2-7.3-.2l-.1 5.9 7.1-.2s14.1-2 14.1 8.9c0 6.5-6.7 10.8-15.8 10.8z" />
        <path d="m265.1 185.1h-7.6l3-75.8 7.7.1z" />
        <path d="m272.2 88.6c-3 .8-5.6 2.8-5.6 7.2 0 6.8 5.5 8.6 8.8 9.1 1.2.2 1.5.1 1.5.1.6-.1 1.1-.1 1.7-.3 2.7-.9 4.9-2.8 4.9-6.8.1-9.9-11.3-9.3-11.3-9.3zm4.3 10.9c-.1 0-.2.1-.3.1-.3.1-.6.1-.9.1-2-.1-3.4-.7-3.4-3.4 0-1.3.8-2.3 1.9-2.5 2.7 0 4.6.3 4.6 3.4-.1 1.3-1 2-1.9 2.3z" />
        <path d="m250.5 88.6c-3 .8-5.6 2.8-5.6 7.2 0 6.8 5.5 8.6 8.8 9.1 1.2.2 1.5.1 1.5.1.6-.1 1.1-.1 1.7-.3 2.7-.9 4.9-2.8 4.9-6.8.1-9.9-11.3-9.3-11.3-9.3zm4.3 10.9c-.1 0-.2.1-.3.1-.3.1-.6.1-.9.1-2-.1-3.4-.7-3.4-3.4 0-1.3.8-2.3 1.9-2.5 2.7 0 4.6.3 4.6 3.4 0 1.3-.9 2-1.9 2.3z" />
        <path d="m126.6 166.1c-4.3 1.1-8.1 4-8.1 10.3 0 9.7 7.9 12.3 12.7 13 1.7.2 2.1.2 2.2.2.8-.2 1.6-.2 2.4-.4 3.8-1.3 7.1-4.1 7.1-9.8 0-14.1-16.3-13.3-16.3-13.3zm6.4 16.4c-.2.1-.3.1-.5.2-.5.1-1.1.1-1.5.1-3.3-.2-5.6-1.2-5.6-5.6 0-2.2 1.4-3.8 3.1-4.1 4.4 0 7.6.5 7.6 5.6 0 2.1-1.5 3.2-3.1 3.8z" />
        <path d="m139 109.3.4 18.5c0 5.7-.1 9.5-.5 13.7-.6 7.3-1.5 15.8-8.1 15.8s-7.5-10.6-8.2-15.8c-.3-2.1-.7-5.2-.7-13.9s.7-18.3.7-18.3h-7.5s-.6 11.7-.6 18.2c0 6.4.4 10.9.6 13.9 1.1 13.9 4.2 21.6 15.8 21.6 10.6 0 14.5-7.5 15.7-21.7.4-4.8.4-8.3.4-13.6l-.5-18.3z" />
        <path d="m167.7 270.9c-4.3 1.1-8.1 4-8.1 10.3 0 9.7 7.9 12.3 12.7 13 1.7.2 2.1.2 2.2.2.8-.2 1.6-.2 2.4-.4 3.8-1.3 7.1-4.1 7.1-9.8.1-14.1-16.3-13.3-16.3-13.3zm6.4 16.4c-.2.1-.3.1-.5.2-.5.1-1.1.1-1.5.1-3.3-.2-5.6-1.2-5.6-5.6 0-2.2 1.4-3.8 3.1-4.1 4.4 0 7.6.5 7.6 5.6 0 2.1-1.4 3.2-3.1 3.8z" />
        <path d="m180.1 214.1.4 18.5c0 5.7-.1 9.5-.5 13.7-.6 7.3-1.5 15.8-8.1 15.8s-7.5-10.6-8.2-15.8c-.3-2.1-.7-5.2-.7-13.9 0-8.6.7-18.3.7-18.3h-7.5s-.6 11.7-.6 18.2c0 6.4.4 10.9.6 13.9 1.1 13.9 4.2 21.6 15.8 21.6 10.6 0 14.5-7.5 15.7-21.7.4-4.8.4-8.3.4-13.6l-.5-18.3z" />
        <path d="m310.2 271.5c-4.3 1.1-8.1 4-8.1 10.3 0 9.7 7.9 12.3 12.7 13 1.7.2 2.1.2 2.2.2.8-.2 1.6-.2 2.4-.4 3.8-1.3 7.1-4.1 7.1-9.8.1-14.1-16.3-13.3-16.3-13.3zm6.5 16.3c-.2.1-.3.1-.5.2-.5.1-1.1.1-1.5.1-3.3-.2-5.6-1.2-5.6-5.6 0-2.2 1.4-3.8 3.1-4.1 4.4 0 7.6.5 7.6 5.6-.1 2.2-1.5 3.2-3.1 3.8z" />
        <path d="m322.7 214.7.4 18.5c0 5.7-.1 9.5-.5 13.7-.6 7.3-1.5 15.8-8.1 15.8s-7.5-10.6-8.2-15.8c-.3-2.1-.7-5.2-.7-13.9s.7-18.3.7-18.3h-7.5s-.6 11.7-.6 18.2c0 6.4.4 10.9.6 13.9 1.1 13.9 4.2 21.6 15.8 21.6 10.6 0 14.5-7.5 15.7-21.7.4-4.8.4-8.3.4-13.6l-.5-18.3z" />
        <path d="m461.4 109.4-21.4 58.9 2.3-58.9h-7.5l-2.6 75.7h7.7l22-62.3-1.5 62.3h7.4l1.1-75.7z" />
        <path d="m447.3 215.1-21.4 58.8 2.3-58.9h-7.4l-2.7 75.8h7.7l22.1-62.3-1.5 62.2 7.3.1 1.1-75.7z" />
        <path d="m240.3 214.8-7.1-.5-3.1 75.1 7.4.6z" />
        <path d="m237.2 259.1s23.5 1.3 22.2 18.6c-.8 10.6-13.7 12.5-22.1 12.6-4.2.1-7.3-.3-7.3-.3l.3-5.9 7.1.7s13.9 3 14.7-7.9c.5-6.5-6-11.3-15-11.9z" />
        <path d="m276.7 290.3h-6.9l1.8-75.6h7z" />
        <path d="m258.5 214.7 31.9-.6v5.8l-32 .5z" />
        <path d="m196.3 355c0-5-.2-8.8-.5-11.7v-.8c0-.4-.1-.9-.1-1.3-1.1-13.8-4.2-21.4-15.6-21.4-10.5 0-14.4 7.4-15.6 21.5-.4 4.8-.4 8.2-.4 13.5v1.8 3.8c0 5 .2 8.8.5 11.7v.8c0 .4.1.9.1 1.3 1.1 13.8 4.2 21.4 15.6 21.4 10.5 0 14.4-7.4 15.6-21.5.4-4.8.4-8.2.4-13.5v-1.8c0-1.4 0-2.7 0-3.8zm-16.1 33.1c-6.5 0-7.5-8.6-8.1-13.8 0-.2-.5-8.7-.5-17.1 0-8 .4-15.8.4-16 .6-7.2 1.5-13.8 8-13.8s7.5 8.6 8.1 13.8c0 .2.5 8.3.5 16.4 0 8.2-.4 16.5-.4 16.7-.6 7.2-1.5 13.8-8 13.8z" />
        <path d="m225.9 155.3 16.5-1.4.9 5.6-18 .8z" />
        <path d="m250.4 185.6-10.1-76.1-7.8-.7-14.7 76.7h6.4l10.8-60.6.7-.1 6.6 60.5z" />
        <path d="m137.3 395.8h-7.3l1-75.9h7.6z" />
        <path d="m119.9 358.3c0-4-1.8-33 14.4-28l-.1-5.7c-5.6-1.3-14.1-.8-18.5 9-3.7 8.2-3.6 19.4-3.6 24.8s.1 15.1 3.6 22.9c4.4 9.8 12.9 10.3 18.5 9l.1-5.7c-15.5 4.7-14.4-22.3-14.4-26.3z" />
        <path d="m148.7 358.3c0-4 1.8-33-14.4-28l.1-5.7c5.6-1.3 14.1-.8 18.5 9 3.7 8.2 3.6 19.4 3.6 24.8s-.1 15.1-3.6 22.9c-4.4 9.8-12.9 10.3-18.5 9l-.1-5.7c15.5 4.7 14.4-22.3 14.4-26.3z" />
        <path d="m222.7 290.9-10-77.1-7.8-.7-14.7 77.8h6.4l10.5-62.4.7-.1 6.9 62.5z" />
        <path d="m307.6 109.3-1.6 44.6-18.5-13.2.8-31.5h-7.7l-2 76.4h7.6l1-36.4 18.2 13.4-.9 23h7.7l2.9-76.2z" />
        <path d="m232.8 318.2-1.6 44.6-18.4-13.2.7-31.5h-7.7l-2 76.4h7.6l1-36.4 18.2 13.4-.9 23h7.7l2.9-76.2z" />
        <path d="m402.3 215-1.6 44.7-18.4-13.2.7-31.5h-7.6l-2.1 76.3h7.6l1-36.3 18.3 13.3-.9 23h7.6l3-76.1z" />
        <path d="m506.5 109.4-21.4 58.9 2.3-58.9h-7.4l-2.7 75.7h7.7l22.1-62.3-1.5 62.3h7.3l1.1-75.7z" />
        <path d="m502 94.6c-.2 3.4-.8 6.7-4.6 6.7-4.5 0-5.2-3.2-5.3-6.8l-5 .6c.5 6.9 3.3 11.6 10.3 11.6 8 0 9.6-6.1 9.9-11.5z" />
        <path d="m492.1 215.1-21.4 58.9 2.4-58.9h-7.5l-2.7 75.7h7.8l22-62.3-1.5 62.2 7.3.1 1.1-75.7z" />
        <path d="m487.7 200.2c-.2 3.4-.8 6.7-4.6 6.7-4.5 0-5.2-3.2-5.3-6.8l-5 .6c.5 6.9 3.3 11.6 10.3 11.6 8 0 9.6-6.1 9.9-11.5z" />
      </g>
      <path d="m424.6 346.6-87.3-1.7-2.1-25.6 94.9 5.1z" fill="#a3d2d9" />
      <path d="m405.2 388.3-87.2-1.7-2.1-25.5 94.8 5z" fill="#a3d2d9" />
    </svg>
  ),
  zoomIn: ({ fill, width, height, id }) => (
    <svg height={height || 512} viewBox="0 0 515.558 515.558" width={width || 512} id={id}>
      <path
        d="m515.558 469.992-137.214-137.212c25.37-34.645 40.545-77.2 40.545-123.333 0-115.484-93.961-209.445-209.445-209.445s-209.444 93.961-209.444 209.445 93.961 209.445 209.445 209.445c46.133 0 88.692-15.177 123.337-40.547l137.212 137.212c0-.001 45.564-45.565 45.564-45.565zm-451.115-260.546c0-79.958 65.042-145 145-145s145 65.042 145 145-65.042 145-145 145-145-65.042-145-145z"
        fill={fill || theme.lightTransparent}
      />
      <path
        d="m241.666 128.89h-64.444v48.333h-48.333v64.444h48.333v48.333h64.444v-48.333h48.333v-64.444h-48.333z"
        fill={fill || theme.lightTransparent}
      />
    </svg>
  ),
  zoomOut: ({ fill, width, height, id }) => (
    <svg height={height || 512} viewBox="0 0 515.558 515.558" width={width || 512} id={id}>
      <path
        d="m515.558 469.992-137.214-137.212c25.37-34.645 40.545-77.2 40.545-123.333 0-115.484-93.961-209.445-209.445-209.445s-209.444 93.961-209.444 209.445 93.961 209.445 209.445 209.445c46.133 0 88.692-15.177 123.337-40.547l137.212 137.212c0-.001 45.564-45.565 45.564-45.565zm-451.115-260.546c0-79.958 65.042-145 145-145s145 65.042 145 145-65.042 145-145 145-145-65.042-145-145z"
        fill={fill || theme.lightTransparent}
      />
      <path d="m128.888 177.224h161.111v64.444h-161.111z" fill={fill || theme.lightTransparent} />
    </svg>
  ),
}

export default Icons
