import React, { forwardRef } from 'react'
import { PropTypes as T } from 'prop-types'

import Icons from './icons'

const Icon = forwardRef(
  (
    { name, width, id, height, style, fill, stroke, wrapperWidth, wrapperHeight, className, onClick, ...otherProps },
    ref
  ) => {
    const IconComponent = Icons[name]
    return (
      <div
        ref={ref}
        style={{
          width: wrapperWidth ? `${wrapperWidth}px` : 'auto',
          height: wrapperHeight ? `${wrapperHeight}px` : 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        className={`icon ${className || ''}`}
        onClick={onClick}
        {...otherProps}
      >
        <IconComponent
          id={id}
          style={style}
          width={width}
          height={height}
          fill={fill}
          stroke={stroke}
          {...otherProps}
        />
      </div>
    )
  }
)

export default Icon

Icon.propTypes = {
  className: T.string,
  fill: T.string,
  height: T.number,
  name: T.oneOf(Object.keys(Icons)).isRequired,
  onClick: T.func,
  width: T.number,
  wrapperHeight: T.number,
  wrapperWidth: T.number,
}

Icon.defaultProps = {
  fill: '',
  name: '',
}
