import styled from 'styled-components'
import theme from '../../theme'

export const StyledLanguages = styled.div`
  font-size: 28px;
  color: ${theme.light};
  display: flex;
  top: 30px;
  position: absolute;
  right: 30px;
  z-index: 5;

  span {
    height: 21px;
    display: block;
    margin-right: 20px;
    border-bottom: 1px solid transparent;
    cursor: pointer;

    &.selected {
      border-bottom: 1px solid ${theme.light};
      margin-top: -2px;
    }
  }

  @media only screen and (max-width: 870px) {
    &.bottom {
      bottom: 30px;
      top: unset;
    }
  }
`
