import pic1 from './köide_001.jpg'
import pic2 from './köide_002.png'
import pic3 from './köide_003 copy.png'
import pic3sm from './köide_003_small.png'
import pic4 from './köide_004.png'
import pic5 from './köide_005.png'
import pic6 from './köide_006.png'
import pic7 from './köide-13-14.png'
import pic8 from './köide-15-16.png'
import pic9 from './köide-17-18.png'
import pic10 from './köide-19-20.png'
import pic11 from './köide-21-22.png'
import pic12 from './köide-23-24.png'
import pic13 from './köide-25-26.png'
import pic14 from './20-21.png'
import pic15 from './22-23.png'
import pic16 from './köide-24-25.png'
import pic17 from './köide-26-27.png'
import pic18 from './köide-28-29.png'
import pic19 from './köide-30-31.png'
import pic20 from './köide-32-33.png'
import pic21 from './köide-34-35.png'
import pic22 from './36-37.png'
import pic23 from './38-39.png'
import pic24 from './40-41.png'
import pic25 from './42-43.png'
// import pic26 from './44-45.png'
// import pic27 from './46-47.png'
import pic28 from './48-49.png'
import pic29 from './50-51.png'
import pic30 from './52-53.png'
import pic31 from './köide-54-55.png'
import pic32 from './55-56.png'
import pic33 from './köide-33ЛАСТ.png'

// const mobpic1 = 'ko_ide_001.jpg'
const mobpic2 = 'ko_ide_002.png'
const mobpic3 = 'ko_ide_003.png'
// const mobpic3sm = 'ko_ide_003_small.png'
const mobpic4 = 'ko_ide_004.png'
const mobpic5 = 'ko_ide_005.png'
const mobpic6 = 'k_ide_006.png'
const mobpic7 = 'ko_ide-13-14.png'
const mobpic8 = 'ko_ide-15-16.png'
const mobpic9 = 'ko_ide-17-18.png'
const mobpic10 = 'ko_ide-19-20.png'
const mobpic11 = 'ko_ide-21-22.png'
const mobpic12 = 'ko_ide-23-24.png'
const mobpic13 = 'ko_ide-25-26.png'
const mobpic14 = '20-21.png'
const mobpic15 = '22-23.png'
const mobpic16 = 'ko_ide-24-25.png'
const mobpic17 = 'ko_ide-26-27.png'
const mobpic18 = 'ko_ide-28-29.png'
const mobpic19 = 'ko_ide-30-31.png'
const mobpic20 = 'ko_ide-32-33.png'
const mobpic21 = 'ko_ide-34-35.png'
const mobpic22 = '36-37.png'
const mobpic23 = '38-39.png'
const mobpic24 = '40-41.png'
const mobpic25 = '42-43.png'
// const mobpic26 = '44-45.png'
// const mobpic27 = '46-47.png'
const mobpic28 = '48-49.png'
const mobpic29 = '50-51.png'
const mobpic30 = '52-53.png'
const mobpic31 = 'ko_ide-54-55.png'
const mobpic32 = '55-56.png'
const mobpic33 = 'ko_ide-33____.png'

export const PAGES = [
  pic2,
  pic3,
  pic4,
  pic5,
  pic6,
  pic7,
  pic8,
  pic9,
  pic10,
  pic11,
  pic12,
  pic13,
  pic14,
  pic15,
  pic16,
  pic17,
  pic18,
  pic19,
  pic20,
  pic21,
  pic22,
  pic23,
  pic24,
  pic25,
  // pic26,
  // pic27,
  pic28,
  pic29,
  pic30,
  pic31,
  pic32,
  pic33,
]

export const MOBILE_PAGES = [
  mobpic2,
  mobpic3,
  mobpic4,
  mobpic5,
  mobpic6,
  mobpic7,
  mobpic8,
  mobpic9,
  mobpic10,
  mobpic11,
  mobpic12,
  mobpic13,
  mobpic14,
  mobpic15,
  mobpic16,
  mobpic17,
  mobpic18,
  mobpic19,
  mobpic20,
  mobpic21,
  mobpic22,
  mobpic23,
  mobpic24,
  mobpic25,
  // pic26,
  // pic27,
  mobpic28,
  mobpic29,
  mobpic30,
  mobpic31,
  mobpic32,
  mobpic33,
]

export const COVER = pic1

export const PAGES_TO_SLIDES = {
  content: 0,
  0: 1,
  1: 2,
  2: 3,
  3: 3,
  4: 4,
  5: 4,
  6: 5,
  7: 5,
  8: 6,
  9: 6,
  10: 7,
  11: 7,
  12: 8,
  13: 8,
  14: 9,
  15: 9,
  16: 10,
  17: 10,
  18: 11,
  19: 11,
  20: 12,
  21: 12,
  22: 13,
  23: 13,
  24: 14,
  25: 14,
  26: 15,
  27: 15,
  28: 16,
  29: 16,
  30: 17,
  31: 17,
  32: 18,
  33: 18,
  34: 19,
  35: 19,
  36: 20,
  37: 20,
  38: 21,
  39: 21,
  40: 22,
  41: 22,
  42: 23,
  43: 23,
  48: 24,
  49: 24,
  50: 25,
  51: 25,
  52: 26,
  53: 26,
  54: 27,
  55: 27,
  56: 28,
  57: 28,
  58: 29,
}

export const SLIDES_TO_PAGES = {
  0: 'content',
  1: 0,
  2: 1,
  3: 2,
  4: 4,
  5: 6,
  6: 8,
  7: 10,
  8: 12,
  9: 14,
  10: 16,
  11: 18,
  12: 20,
  13: 22,
  14: 24,
  15: 26,
  16: 28,
  17: 30,
  18: 32,
  19: 34,
  20: 36,
  21: 38,
  22: 40,
  23: 42,
  24: 48,
  25: 50,
  26: 52,
  27: 54,
  28: 56,
  29: 58,
}
// 21: 40,
// 22: 42,
// 23: 44,
// 24: 46,
// 25: 48,
// 26: 50,
// 27: 52,
// 28: 54,
// 29: 56,
// 30: 58,
