export const page0 = {
  rows: 25,
  cols: 23,
  items: [
    {
      name: 'year',
      position: '3 / 17 / 4 / 20',
      margin: '0 0 0 -1.3vw',
      padding: '',
      width: '',
      header: true,
      fontSize: 30,
    },
    {
      name: 'kazkaProKonyka',
      position: '7 / 14 / 14 / 22',
      margin: '',
      padding: '',
      width: '',
      header: true,
      fontSize: 74,
      textAlign: 'center',
    },
    {
      name: 'knygaProLiudejZviriv',
      position: '19 / 13 / 20 / 24',
      margin: '',
      padding: '',
      header: true,
      fontSize: 24,
      textAlign: 'center',
      width: '50vw',
    },
    {
      name: 'anatolijLiutiuk',
      position: '21 / 14 / 22 / 22',
      margin: '-1vw 0 0 0',
      padding: '',
      width: '',
      header: true,
      textAlign: 'center',
    },
    {
      name: 'anatolijLiutiuk',
      position: '22 / 17 / 23 / 20',
      margin: '0 1.7vw 0 -1.5vw',
      padding: '',
      width: '',
      header: true,
      textAlign: 'center',
    },
  ],
}

export const page1 = {
  cols: 13,
  rows: 17,
  items: [
    {
      name: 'donbas',
      position: '2 / 8 / 3 / 9',
      margin: '1vw -1.5vw 0 0',
      padding: '',
      transform: 'rotate(21deg)',
    },
    {
      name: 'mistaZliva1',
      position: '5 / 1 / 6 / 3',
      margin: '0 0 0 3vw',
      padding: '',
    },
    {
      name: 'mistaZliva2',
      position: '4 / 2 / 5 / 7',
      margin: '0 -4vw 1vw 2vw',
      padding: '',
      transform: 'rotate(-21deg)',
    },
    {
      name: 'mistaSprava1',
      position: '4 / 8 / 5 / 10',
      margin: '-1vw 2vw 1vw -3vw',
      padding: '',
      transform: 'rotate(40deg)',
    },
    {
      name: 'mistaSprava2',
      position: '9 / 9 / 10 / 12',
      margin: '2vw -2vw -2vw 3vw',
      padding: '',
      transform: 'rotate(40deg)',
    },
    {
      name: 'mistaSprava3',
      position: '11 / 12 / 12 / 14',
      margin: '0 2.5vw 0 0',
      padding: '',
    },
    {
      name: 'jakKonykVolonteromStavTitle',
      position: '13 / 12 / 14 / 13',
      margin: '',
      padding: '',
      header: true,
      textAlign: 'right',
    },
    {
      name: 'jakKonykVolonteromStavTitle',
      position: '14 / 11 / 15 / 13',
      margin: '',
      padding: '',
      header: true,
      textAlign: 'right',
    },
    {
      name: 'jakKonykVolonteromStavTitle',
      position: '15 / 8 / 16 / 13',
      margin: '',
      padding: '',
      header: true,
      textAlign: 'right',
    },
    {
      name: 'jakKonykVolonteromStavText',
      position: '10 / 8 / 15 / 11',
      margin: '',
      padding: '',
      width: '22vw',
      header: false,
    },
    {
      name: 'mistoVSviti',
      position: '5 / 5 / 7 / 6',
      margin: '',
      padding: '',
      header: false,
    },
    {
      name: 'mistoVSviti',
      position: '7 / 4 / 12 / 5',
      margin: '',
      padding: '',
      header: false,
    },
  ],
}

export const page2_3 = {
  rows: 35,
  cols: 21,
  items: [
    {
      name: 'papirDliaKnygy',
      position: '5 / 13 / 6 / 21',
      margin: '', //check
      padding: '',
      width: '',
    },
    {
      name: 'viddalySvojeZhyttia',
      position: '3 / 3 / 5 / 21',
      margin: '',
      padding: '',
      width: '80vw',
      header: true,
    },
    {
      name: 'miscePamjati',
      position: '20 / 2 / 22 / 7',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'z2014RokuRadaCerkvivEstonii',
      position: '22 / 3 / 31 / 6',
      margin: '',
      padding: '',
      width: '19vw',
    },
    {
      name: 'viktor',
      position: '13 / 12 / 15 / 17',
      margin: '',
      padding: '',
      width: '',
      textAlign: 'center',
      header: true,
    },
    {
      name: 'viktorText',
      position: '15 / 12 / 18 / 18',
      margin: '',
      padding: '',
      width: '22vw',
    },
    {
      name: 'myhajlo',
      position: '15 / 18 / 19 / 21',
      margin: '',
      padding: '',
      width: '',
      textAlign: 'center',
      header: true,
    },
    {
      name: 'myhajloText',
      position: '19 / 18 / 25 / 21',
      margin: '',
      padding: '',
      width: '19vw',
    },
    {
      name: 'olenaTaVolodymyr',
      position: '27 / 13 / 28 / 21',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'olenaTaVolodymyrText',
      position: '28 / 13 / 32 / 20',
      margin: '',
      padding: '',
      width: '40vw',
    },
  ],
}

export const page4_5 = {
  cols: 25,
  rows: 25,
  items: [
    {
      name: 'angelZTernopolia1',
      position: '5 / 2 / 6 / 4',
      margin: '',
      padding: '',
      transform: 'rotate(-40deg)',
    },
    {
      name: 'angelZTernopolia2',
      position: '5 / 4 / 6 / 6',
      margin: '-2vw 1.5vw 1vw -2vw',
      padding: '',
      transform: 'rotate(41deg)',
    },
    {
      name: 'konykIPesykTitle',
      position: '4 / 15 / 7 / 20',
      margin: '1vw 0 -1.5vw 0',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'konykIPesyk1',
      position: '4 / 20 / 9 / 21',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykIPesyk1',
      position: '9 / 19 / 11 / 20',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykIPesyk1',
      position: '11 / 18 / 14 / 19',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykIPesyk1',
      position: '14 / 17 / 16 / 19',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykIPesyk1',
      position: '16 / 16 / 18 / 18',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykIPesyk1',
      position: '18 / 16 / 20 / 17',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykIPesyk1',
      position: '20 / 15 / 22 / 17',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykJakogoSpasPesyk',
      position: '3 / 23 / 14 / 24',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykJakogoSpasPesyk',
      position: '14 / 22 / 16 / 23',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykJakogoSpasPesyk',
      position: '16 / 21 / 18 / 22',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykIPesyk2',
      position: '17 / 10 / 20 / 12',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykIPesyk2',
      position: '18 / 12 / 19 / 13',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykIPesyk3',
      position: '3 / 6 / 6 / 7',
      margin: '0 2vw 0 -1.5vw',
      padding: '',
      width: '',
    },
    {
      name: 'konykIPesyk3',
      position: '6 / 5 / 8 / 6',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykIPesyk3',
      position: '8 / 4 / 10 / 5',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykIPesyk3',
      position: '10 / 3 / 11 / 5',
      margin: '0 2vw 0 3.5vw',
      padding: '',
      width: '',
    },
    {
      name: 'konykIPesyk3',
      position: '11 / 3 / 20 / 4',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykIPesyk3',
      position: '20 / 2 / 22 / 3',
      margin: '',
      padding: '',
      width: '',
    },
  ],
}

export const page6_7 = {
  rows: 25,
  cols: 25,
  items: [
    {
      name: 'dobriLiudySpaslyPesyka',
      position: '2 / 3 / 3 / 25',
      margin: '',
      padding: '',
      width: '90vw',
      header: true,
    },
    {
      name: 'blagodijnyjFond',
      position: '4 / 4 / 12 / 12',
      margin: '',
      padding: '',
      width: '35vw',
    },
    {
      name: 'pesykReksSpas',
      position: '18 / 3 / 20 / 7',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'pesykReksSpas',
      position: '14 / 3 / 18 / 6',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'lystRomashky',
      position: '14 / 7 / 25 / 13',
      margin: '',
      padding: '',
      width: '20vw',
    },
    {
      name: 'reksZakryv2bijciv',
      position: '4 / 15 / 5 / 22',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'reksZakryv2bijciv',
      position: '3 / 22 / 4 / 24',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'likyJakymyReksaVyvodyly',
      position: '8 / 16 / 9 / 22',
      margin: '-1vw 0 0.3vw 0',
      padding: '',
      width: '',
      transform: 'rotate(-21deg)',
    },
    {
      name: 'reksPisliaNadannia',
      position: '11 / 16 / 14 / 18',
      margin: '', //check
      padding: '',
      width: '',
    },
    {
      name: 'travaZBlokpostu',
      position: '22 / 14 / 24 / 16',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'travaZBlokpostu',
      position: '18 / 15 / 22 / 16',
      margin: '',
      padding: '',
      width: '',
    },
  ],
}

export const page8_9 = {
  rows: 33,
  cols: 24,
  items: [
    {
      name: 'konIKorovy',
      position: '13 / 14 / 17 / 19',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'minomet',
      position: '3 / 16 / 4 / 21',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'minomet',
      position: '4 / 21 / 6 / 24',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'spalyvKorovamHatkuJabluniu',
      position: '5 / 13 / 10 / 19', // check
      margin: '',
      padding: '',
      width: '20vw',
    },
    {
      name: 'spalyvKorovamHatkuJabluniu',
      position: '10 / 13 / 11 / 14', // check
      margin: '',
      padding: '',
      width: '20vw',
    },
    {
      name: 'konykVytiagujeZVogniu',
      position: '19 / 21 / 22 / 24', //check
      margin: '',
      padding: '',
      width: '10vw',
    },
    {
      name: 'rozbytaJablunia',
      position: '2 / 4 / 3 / 8',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konIKorovy3',
      position: '19 / 5 / 21 / 6',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konIKorovy3',
      position: '17 / 6 / 20 / 7',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konIKorovy3',
      position: '15 / 7 / 18 / 8',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konIKorovy3',
      position: '16 / 8 / 17 / 9',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konIKorovy3',
      position: '15 / 9 / 16 / 10',
      margin: '',
      padding: '',
      width: '',
    },
  ],
}

export const page10_11 = {
  rows: 25,
  cols: 25,
  items: [
    {
      name: 'ogoloShenniaZiStovpiv',
      position: '3 / 11 / 5 / 13',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'odnaGryvnia',
      position: '10 / 9 / 11 / 13',
      margin: '-1vw 0  1vw 0',
      padding: '',
      width: '',
      transform: 'rotate(-10deg)',
    },
    {
      name: 'pochatokIstorijiLyst',
      position: '11 / 9 / 12 / 13',
      margin: '', //check
      padding: '',
      width: '',
      transform: 'rotate(-14deg)',
    },
    {
      name: 'lystText',
      position: '12 / 2 / 18 / 7',
      margin: '-2vw -1.5vw 2vw 1.5vw',
      padding: '',
      width: '22vw',
      transform: 'rotate(-18deg)',
    },
    {
      name: 'hlivChornushy',
      position: '14 / 9 / 16 / 12',
      margin: '0.7vw -0.7vw 0.7vw 1.2vw',
      padding: '',
      width: '',
    },
    {
      name: 'jakivLukich',
      position: '9 / 20 / 10 / 24',
      margin: '0 -1.5vw 0 0',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'jakivLukichText',
      position: '10 / 15 / 13 / 24',
      margin: '0 0 0 1.8vw',
      padding: '',
      width: '37vw',
    },
    {
      name: 'myhajloUhmanText',
      position: '5 / 15 / 9 / 19',
      margin: '0 -2vw 0 1vw',
      padding: '',
      width: '18vw',
    },
    {
      name: 'chornushka',
      position: '14 / 15 / 15 / 20',
      margin: '-1vw 0 0 0',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'chornushkaText',
      position: '15 / 20 / 21 / 23',
      margin: '1vw 0 0 2.2vw',
      padding: '',
      width: '10vw',
    },
    {
      name: 'dobriLiudySpaslyKorovu',
      position: '23 / 5 / 24 / 20',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
  ],
}

export const page12_13 = {
  rows: 25,
  cols: 25,
  items: [
    {
      name: 'angelZLutska',
      position: '5 / 8 / 6 / 11',
      margin: '',
      padding: '',
      transform: 'rotate(-21deg)',
    },
    {
      name: 'kinIMysha',
      position: '15 / 14 / 18 / 18',
      margin: '-1vw 0 1vw 1vw',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'kinIMysha',
      position: '15 / 18 / 16 / 19',
      margin: '0 2vw 0 0',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'kinIMysha1',
      position: '17 / 20 / 20 / 22',
      margin: '1vw 0.3vw 0.1vw 0.3vw',
      padding: '',
      width: '5vw',
    },
    {
      name: 'kinIMysha2',
      position: '8 / 9 / 9 / 11',
      margin: '0.5vw 0 0 0',
      padding: '',
      width: '',
    },
    {
      name: 'kinIMysha2',
      position: '9 / 11 / 10 / 14',
      margin: '-0.5vw 2vw 0 -2vw',
      padding: '',
      width: '',
      transform: 'rotate(17deg)',
    },
    {
      name: 'kinIMysha2',
      position: '11 / 13 / 12 / 15',
      margin: '-2vw -2vw 1vw 2.2vw',
      padding: '',
      width: '',
      transform: 'rotate(-45deg)',
    },
    {
      name: 'kinIMysha2',
      position: '10 / 15 / 12 / 16',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'kinIMysha2',
      position: '12 / 16 / 13 / 18',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'kinIMysha2',
      position: '10 / 18 / 12 / 19',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'kinIMysha2',
      position: '8 / 19 / 9 / 23',
      margin: '',
      padding: '',
      width: '',
      transform: 'rotate(-30deg)',
    },
    {
      name: 'kinIMysha3',
      position: '17 / 10 / 21 / 13',
      margin: '0 0 0 -0.6vw',
      padding: '',
      width: '11vw',
    },
  ],
}

export const page14_15 = {
  rows: 25,
  cols: 25,
  items: [
    {
      name: 'HannaTerjanik',
      position: '8 / 6 / 9 / 12',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'HannaTerjanikText',
      position: '9 / 6 / 14 / 12',
      margin: '0 0 1.5vw 1vw',
      padding: '',
      width: '23vw',
    },
    {
      name: 'rozpiznavalnyjPrapor',
      position: '10 / 4 / 17 / 5',
      margin: '-0.5vw -1vw -0.5vw 2vw',
      padding: '',
      width: '',
      transform: 'rotate(10deg)',
    },
    {
      name: 'travynkaZirvana',
      position: '14 / 5 / 24 / 6',
      margin: '0 1.7vw -0.5vw -0.2vw',
      padding: '',
      width: '',
    },
    {
      name: 'myshiDonbasu',
      position: '10 / 16 / 12 / 24',
      margin: '',
      padding: '',
      width: '',
      header: true,
      fontSize: 52,
    },
    {
      name: 'dopomagajutLiudiam',
      position: ' /  /  / ',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'myshiDonbasu1',
      position: '2 / 15 / 9 / 19',
      margin: '',
      padding: '',
      width: '17vw',
    },
    {
      name: 'myshiDonbasu1',
      position: '3 / 19 / 4 / 20',
      margin: '',
      padding: '',
      width: '17vw',
    },
    {
      name: 'myshiDonbasu2',
      position: '12 / 19 / 16 / 25',
      margin: '1.5vw 0 0 -0.7vw',
      padding: '',
      width: '26vw',
    },
    {
      name: 'myshiDonbasu2',
      position: '16 / 19 / 21 / 24',
      margin: '0 0 0 -0.4vw',
      padding: '',
      width: '26vw',
    },
    {
      name: 'vTojChasVPiskah',
      position: '21 / 16 / 23 / 24',
      margin: '1vw 0 1vw -0.6vw',
      padding: '',
      width: '',
    },
    {
      name: 'maliunky',
      position: '23 / 17 / 24 / 22',
      margin: '-1vw -0.5vw 1vw -1vw',
      padding: '',
      width: '',
    },
  ],
}

export const page16_17 = {
  rows: 25,
  cols: 25,
  items: [
    {
      name: 'angelZHersonu1',
      position: '4 / 10 / 5 / 13',
      margin: '1vw 0 -1vw 0',
      padding: '',
      transform: 'rotate(-21deg)',
    },
    {
      name: 'angelZHersonu2',
      position: '4 / 13 / 6 / 14',
      margin: '1vw 2vw -1vw -1vw',
      padding: '',
    },
    {
      name: 'granatomet',
      position: '3 / 21 / 4 / 24',
      margin: '',
      padding: '',
    },
    {
      name: 'konykIRyba',
      position: '8 / 15 / 9 / 20',
      margin: '-0.4vw -0.7vw 0 0',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'konykIRyba',
      position: '9 / 15 / 11 / 18',
      margin: '0 0 -0.3vw 0',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'konykIRyba1',
      position: '3 / 13 / 11 / 14',
      margin: '0 -1vw 0 -1.2vw', //can check
      padding: '',
      width: '',
      transform: 'rotate(45deg)',
    },
    {
      name: 'konykIRyba2',
      position: '3 / 9 / 10 / 10',
      margin: '0 -0.5vw 0 1.9vw',
      padding: '',
      width: '',
    },
    {
      name: 'konykIRyba2',
      position: '2 / 8 / 3 / 10',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykIRyba2',
      position: '10 / 11 / 16 / 12', //check!!
      // margin: '0 0 0 -4.5vw',
      padding: '',
      width: '',
      transform: 'rotate(135deg)',
    },
    {
      name: 'konykIRyba3',
      position: '13 / 19 / 19 / 20',
      margin: '0 -1vw 0 1.8vw',
      padding: '',
      width: '',
      transform: 'rotate(15deg)',
    },
  ],
}

export const page18_19 = {
  cols: 16,
  rows: 33,
  items: [
    {
      name: 'childrenFromKarlivka',
      position: '3 / 5 / 7 / 8',
      margin: '',
      padding: '',
      header: true,
    },
    {
      name: 'childrenFromKarlivkaText',
      position: '8 / 5 / 20 / 8',
      margin: '',
      padding: '',
      width: '20vw',
    },
    {
      name: 'lystShkoliaraText',
      position: '9 / 1 / 16 / 5',
      margin: '',
      padding: '',
      width: '20vw',
    },
    {
      name: 'lystShkoliara',
      position: '17 / 2 / 18 / 5',
      margin: '',
      padding: '',
    },
    {
      name: 'stavokTaLis',
      position: '18 / 2 / 22 / 4',
      margin: '',
      padding: '',
      width: '20vw',
    },
    {
      name: 'stavokTaLis',
      position: '18 / 4 / 19 / 5',
      margin: '',
      padding: '',
      width: '20vw',
    },
    {
      name: 'rybalkaBezVudok',
      position: '26 / 2 / 29 / 4',
      margin: '',
      padding: '',
    },
    {
      name: 'diakuyuShoOberigaete',
      position: '2 / 10 / 3 / 13',
      margin: '',
      padding: '',
    },
    {
      name: 'stavok',
      position: '3 / 13 / 4 / 16',
      margin: '',
      padding: '',
      header: true,
    },
    {
      name: 'stavokText',
      position: '5 / 12 / 10 / 16',
      margin: '',
      padding: '',
      width: '22vw',
    },
    {
      name: 'stavokText',
      position: '10 / 13 / 14 / 16',
      margin: '',
      padding: '',
      width: '22vw',
    },
    {
      name: 'stavokText',
      position: '14 / 14 / 16 / 16',
      margin: '',
      padding: '',
    },
    {
      name: 'dobriLiudyRemontuyut',
      position: '4 / 10 / 5 / 11',
      margin: '',
      padding: '',
      header: true,
      fontSize: 28,
    },
    {
      name: 'dobriLiudyRemontuyut',
      position: '5 / 10 / 9 / 12',
      margin: '',
      padding: '',
      header: true,
      fontSize: 28,
    },
    {
      name: 'dobriLiudyRemontuyut',
      position: '9 / 11 / 14 / 12',
      margin: '',
      padding: '',
      header: true,
      fontSize: 28,
    },
    {
      name: 'dobriLiudyRemontuyut',
      position: '14 / 11 / 16 / 13',
      margin: '',
      padding: '',
      header: true,
      fontSize: 28,
    },
    {
      name: 'dobriLiudyRemontuyut',
      position: '16 / 12 / 19 / 14',
      margin: '',
      padding: '',
      header: true,
      fontSize: 28,
    },
    {
      name: 'dobriLiudyRemontuyut',
      position: '19 / 13 / 20 / 14',
      margin: '',
      padding: '',
      header: true,
      fontSize: 28,
    },
    {
      name: 'dobriLiudyRemontuyut',
      position: '20 / 13 / 21 / 15',
      margin: '',
      padding: '',
      header: true,
      fontSize: 28,
    },
    {
      name: 'dobriLiudyRemontuyut',
      position: '21 / 14 / 23 / 15',
      margin: '',
      padding: '',
      header: true,
      fontSize: 28,
    },
    {
      name: 'dobriLiudyRemontuyut',
      position: '23 / 14 / 24 / 15',
      margin: '',
      padding: '',
      header: true,
      fontSize: 28,
    },
  ],
}

export const page20_21 = {
  rows: 18,
  cols: 25,
  items: [
    {
      name: 'pidAvdiivkoiu',
      position: '17 / 15 / 18 / 20',
      margin: '2vw 1vw -1vw 1vw',
      padding: '',
    },
    {
      name: 'angelZIvanoFrankovsku1',
      position: '17 / 20 / 18 / 22',
      margin: '1vw 0 -1vw -1vw',
      padding: '',
    },
    {
      name: 'angelZIvanoFrankovsku2',
      position: '14 / 22 / 17 / 23',
      margin: '0 1vw -1.5vw -1vw',
      padding: '',
      transform: 'rotate(20deg)',
    },
    {
      name: 'angelZIvanoFrankovsku3',
      position: '14 / 23 / 15 / 25',
      margin: '0 0 0 -1vw',
      padding: '',
      transform: 'rotate(20deg)',
    },
    {
      name: 'konykIKoza',
      position: '6 / 14 / 9 / 19',
      margin: '', // check
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'konykIKoza1',
      position: '2 / 20 / 3 / 24',
      margin: '1.5vw 0 -1.5vw 0',
      padding: '',
      width: '',
    },
    {
      name: 'konykIKoza2',
      position: '5 / 8 / 6 / 13',
      margin: '-0.7vw 0 0 0',
      padding: '',
      width: '',
    },
    {
      name: 'konykIKoza3',
      position: '11 / 10 / 12 / 11',
      margin: '', // check
      padding: '',
      width: '10vw',
    },
    {
      name: 'konykIKoza3',
      position: '12 / 10 / 16 / 13',
      margin: '',
      padding: '',
      width: '10vw',
    },
    {
      name: 'konykIKoza4',
      position: '8 / 18 / 9 / 25',
      margin: '1.3vw 0 -1.3vw 0',
      padding: '',
      width: '',
      transform: 'rotate(150deg)',
    },
  ],
}

export const page22_23 = {
  rows: 23,
  cols: 25,
  items: [
    {
      name: 'seloVodiane',
      position: '6 / 15 / 7 / 18',
      margin: '1vw 0 -0.5vw -0.5vw',
      padding: '',
    },
    {
      name: 'trava',
      position: '21 / 17 / 22 / 19',
      margin: '0 2vw 0 0',
      padding: '',
    },
    {
      name: 'zrujnovanyiBudynky',
      position: '6 / 19 / 7 / 25',
      margin: '1vw 0 0 1vw',
      padding: '',
      width: '',
    },
    {
      name: 'kozy4IBaba',
      position: '7 / 20 / 9 / 24',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'kozy4IBabaText',
      position: '9 / 20 / 14 / 25',
      margin: '',
      padding: '',
      width: '25vw',
    },
    {
      name: 'kozaMykolyFoto',
      position: '17 / 15 / 18 / 19',
      margin: '-0.8vw 0 0.8vw  -0.5vw',
      padding: '',
      width: '',
    },
    {
      name: 'babaZojaFoto',
      position: '22 / 19 / 23 / 24',
      margin: '-0.5vw 1.2vw 0.5vw 0',
      padding: '',
      width: '',
    },
    {
      name: 'Avdijivka',
      position: '8 / 6 / 9 / 10',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'kozaIkozliata',
      position: '10 / 4 / 11 / 12',
      margin: '-0.7vw 0 0.5vw 0',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'kozaIkozliataText',
      position: '11 / 4 / 15 / 12',
      margin: '0 0 1vw 0',
      padding: '',
      width: '30vw',
    },
    {
      name: 'kozaIHlopec',
      position: '15 / 4 / 16 / 12',
      margin: '0 1.5vw 0 0',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'kozaIHlopecText',
      position: '16 / 4 / 20 / 12',
      margin: '',
      padding: '',
      width: '30vw',
    },
  ],
}

export const page24_25 = {
  rows: 28,
  cols: 25,
  items: [
    {
      name: 'konykIPtashkaTitle',
      position: '14 / 15 / 18 / 20',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'konykIPtashka1',
      position: '19 / 15 / 20 / 21',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykIPtashka1',
      position: '20 / 15 / 21 / 22',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'granata',
      position: '21 / 19 / 22 / 21',
      margin: '0 0 0.8vw 0',
      padding: '',
      width: '',
    },
    {
      name: 'konykIPtashka2',
      position: '15 / 21 / 20 / 22',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykIPtashka2',
      position: '13 / 20 / 16 / 21',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykIPtashka2',
      position: '12 / 19 / 15 / 20',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykIPtashka2',
      position: '12 / 19 / 14 / 20',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykIPtashka2',
      position: '11 / 18 / 13 / 19',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykIPtashka2',
      position: '10 / 17 / 12 / 18',
      margin: '', // check
      padding: '',
      width: '',
    },
    {
      name: 'konykIPtashka2',
      position: '9 / 16 / 11 / 17',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykIPtashka2',
      position: '8 / 15 / 10 / 16',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykIPtashka3',
      position: '17 / 3 / 20 / 4',
      margin: '',
      padding: '',
      width: '23vw',
    },
    {
      name: 'konykIPtashka3',
      position: '16 / 4 / 23 / 7',
      margin: '',
      padding: '',
      width: '23vw',
    },
    {
      name: 'konykIPtashka3',
      position: '18 / 7 / 22 / 8',
      margin: '', // check
      padding: '',
      width: '23vw',
    },
    {
      name: 'shnurSpletenyjZZhylky',
      position: '22 / 6 / 23 / 17',
      margin: '', //check
      padding: '',
      width: '',
      transform: 'rotate(-8deg)',
    },
  ],
}

export const page26_27 = {
  rows: 25,
  cols: 25,
  items: [
    {
      name: 'Donbas',
      position: '2 / 3 / 4 / 8',
      margin: '1vw 0 0 0',
      padding: '',
    },
    {
      name: 'zaminovanaZemlia',
      position: '3 / 15 / 4 / 24',
      margin: '0 0 -1vw 0',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'naPidkontrolnijUriadu',
      position: '4 / 3 / 5 / 5',
      margin: '0 0 -1vw 0',
      padding: '',
      width: '45vw',
      header: true,
      fontSize: 26,
    },
    {
      name: 'naPidkontrolnijUriadu',
      position: '4 / 5 / 5 / 10',
      margin: '0 -1vw 0 0',
      padding: '',
      width: '45vw',
      header: true,
      fontSize: 26,
    },
    {
      name: 'naPidkontrolnijUriadu',
      position: '6 / 3 / 8 / 4',
      margin: '-1vw 0 0 0.8vw',
      padding: '',
      width: '45vw',
      header: true,
      fontSize: 26,
    },
    {
      name: 'naPidkontrolnijUriadu',
      position: '6 / 4 / 8 / 5',
      margin: '',
      padding: '',
      width: '45vw',
      header: true,
      fontSize: 26,
    },
    {
      name: 'naPidkontrolnijUriadu',
      position: '6 / 5 / 9 / 6',
      margin: '1.4vw 0 0 0',
      padding: '',
      width: '45vw',
      header: true,
      fontSize: 26,
    },
    {
      name: 'naPidkontrolnijUriadu',
      position: '7 / 6 / 10 / 7',
      margin: '',
      padding: '',
      width: '45vw',
      header: true,
      fontSize: 26,
    },
    {
      name: 'naPidkontrolnijUriadu',
      position: '8 / 7 / 11 / 8',
      margin: '',
      padding: '',
      width: '45vw',
      header: true,
      fontSize: 26,
    },
    {
      name: 'naPidkontrolnijUriadu',
      position: '9 / 8 / 13 / 9',
      margin: '', // check
      padding: '',
      width: '45vw',
      header: true,
      fontSize: 26,
    },
    {
      name: 'naPidkontrolnijUriadu',
      position: '11 / 9 / 15 / 10',
      margin: '',
      padding: '',
      width: '45vw',
      header: true,
      fontSize: 26,
    },
    {
      name: 'naPidkontrolnijUriadu',
      position: '14 / 10 / 17 / 11',
      margin: '', // check
      padding: '',
      width: '45vw',
      header: true,
      fontSize: 26,
    },
    {
      name: 'naPidkontrolnijUriadu',
      position: '17 / 10 / 18 / 11',
      margin: '0 -0.5vw 0 2.5vw',
      padding: '',
      width: '45vw',
      header: true,
      fontSize: 26,
    },
    {
      name: 'dytyna21',
      position: '10 / 3 / 13 / 5',
      margin: '-.0.5vw 0 0 0',
      padding: '',
      width: '',
      header: true,
      color: '#C1442B',
      fontSize: 50,
    },
    {
      name: 'dytyna21',
      position: '12 / 5 / 13 / 8',
      margin: '-0.7vw 0 0 0',
      padding: '',
      width: '',
      header: true,
      color: '#C1442B',
      fontSize: 50,
    },
    {
      name: 'vybuhovonebezpechnyk37300',
      position: ' /  /  / ',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'photoAndrij',
      position: ' /  /  / ',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'promka',
      position: '5 / 21 / 6 / 23',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'promkaText',
      position: '6 / 21 / 13 / 25',
      margin: '0.5vw 0 0 -0.7vw',
      padding: '',
      width: '20vw',
    },
    {
      name: 'dobriLiudyRozminovujut',
      position: '14 / 14 / 15 / 20',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'dobriLiudyRozminovujut',
      position: '15 / 15 / 16 / 19',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'dobriLiudyRozminovujut',
      position: '16 / 14 / 17 / 15',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'dobriLiudyRozminovujut',
      position: '16 / 19 / 17 / 20',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'dobriLiudyRozminovujut',
      position: '17 / 19 / 18 / 20',
      margin: '0 -0.5vw -0.5vw 2vw',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'maliuvanniaZNatury',
      position: '13 / 21 / 14 / 25',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'photoUlizDenys',
      position: ' /  /  / ',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
  ],
} // empty

export const page28_29 = {
  rows: 21,
  cols: 25,
  items: [
    {
      name: 'angelZVinnyci1',
      position: '4 / 14 / 7 / 15',
      margin: '1vw 0',
      padding: '',
    },
    {
      name: 'angelZVinnyci2',
      position: '3 / 15 / 4 / 18',
      margin: '1vw 2vw -1.5vw -0.5vw',
      padding: '',
    },
    {
      name: 'konIZajac',
      position: '9 / 15 / 10 / 20',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'konIZajac',
      position: '10 / 20 / 11 / 23',
      margin: '', //check
      padding: '',
      width: '',
      header: true,
      transform: 'rotate(19deg)',
    },
    {
      name: 'konIZajac1',
      position: '2 / 18 / 3 / 25',
      margin: '1.7vw 0 -2.5vw 0',
      padding: '',
      width: '',
      transform: 'rotate(14deg)',
    },
    {
      name: 'konIZajac2',
      position: '3 / 3 / 4 / 12', //check
      margin: '-2vw 0 0.8vw 0',
      padding: '',
      width: '',
      transform: 'rotate(-22deg)',
    },
    {
      name: 'konIZajac3',
      position: '14 / 11 / 16 / 13',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konykNaBMP',
      position: '12 / 22 / 15 / 25', //check
      margin: '',
      padding: '',
      width: '10vw',
    },
  ],
}

export const page30_31 = {
  rows: 27,
  cols: 26,
  items: [
    {
      name: 'zajciDonbasu',
      position: '3 / 3 / 5 / 12',
      margin: '',
      padding: '',
      width: '',
      header: true,
      fontSize: 48,
    },
    {
      name: 'stat',
      position: '10 / 3 / 11 / 8',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'statText',
      position: '11 / 3 / 15 / 13',
      margin: '',
      padding: '',
      width: '30vw',
    },
    {
      name: 'terytorijaOdnogoZajcia',
      position: '16 / 3 / 17 / 12',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'terytorijaOdnogoZajciaText',
      position: '17 / 3 / 24 / 13',
      margin: '',
      padding: '',
      width: '38vw',
    },
    {
      name: 'pozyvnij',
      position: '24 / 8 / 26 / 11',
      margin: '', //check
      padding: '',
      width: '',
    },
    {
      name: 'zajecVBMP2',
      position: '3 / 15 / 5 / 23',
      margin: '',
      padding: '',
      width: '',
      header: true,
      fontSize: 48,
    },
    {
      name: 'zajecVBMP2Text',
      position: '6 / 15 / 8 / 22',
      margin: '',
      padding: '',
      width: '30vw',
    },
    {
      name: 'zajecVBMP2Text',
      position: '8 / 15 / 10 / 21',
      margin: '',
      padding: '',
      width: '30vw',
    },
    {
      name: 'zajecVBMP2Text',
      position: '10 / 15 / 12 / 20',
      margin: '',
      padding: '',
      width: '30vw',
    },
    {
      name: 'dobriLiudy',
      position: '18 / 16 / 21 / 17',
      margin: '',
      padding: '',
      width: '60vw',
      header: true,
    },
    {
      name: 'dobriLiudy',
      position: '16 / 17 / 18 / 18',
      margin: '',
      padding: '',
      width: '60vw',
      header: true,
    },
    {
      name: 'dobriLiudy',
      position: '14 / 18 / 16 / 19',
      margin: '',
      padding: '',
      width: '60vw',
      header: true,
    },
    {
      name: 'dobriLiudy',
      position: '12 / 19 / 14 / 20',
      margin: '',
      padding: '',
      width: '60vw',
      header: true,
    },
    {
      name: 'dobriLiudy',
      position: '11 / 20 / 13 / 21',
      margin: '',
      padding: '',
      width: '60vw',
      header: true,
    },
    {
      name: 'dobriLiudy',
      position: '9 / 21 / 12 / 22',
      margin: '',
      padding: '',
      width: '60vw',
      header: true,
    },
    {
      name: 'dobriLiudy',
      position: '7 / 22 / 10 / 23',
      margin: '',
      padding: '',
      width: '60vw',
      header: true,
    },
    {
      name: 'dobriLiudy',
      position: '4 / 23 / 8 / 24',
      margin: '',
      padding: '',
      width: '60vw',
      header: true,
    },
    {
      name: 'dobriLiudy',
      position: ' /  /  / ',
      margin: '',
      padding: '',
      width: '60vw',
      header: true,
    },
    {
      name: 'infoPojizd',
      position: '12 / 15 / 15 / 18', //check
      margin: '',
      padding: '',
      width: '10vw',
    },
  ],
}

export const page32_33 = {
  rows: 29,
  cols: 27,
  items: [
    {
      name: 'konykIDvaKoty',
      position: '9 / 16 / 12 / 20', //check
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'konykIDvaKoty1',
      position: '3 / 22 / 6 / 25',
      margin: '',
      padding: '',
      width: '15vw',
    },
    {
      name: 'konykIDvaKoty1',
      position: '3 / 25 / 5 / 27',
      margin: '',
      padding: '',
      width: '15vw',
    },
    {
      name: 'konykIDvaKoty2',
      position: '7 / 11 / 12 / 14',
      margin: '',
      padding: '',
      width: '13vw',
    },
    {
      name: 'konykIDvaKoty3',
      position: '12 / 6 / 14 / 9', //check
      margin: '',
      padding: '',
      width: '15vw',
    },
    {
      name: 'konykIDvaKoty4',
      position: '19 / 4 / 24 / 7', //check
      margin: '0 -1vw 0 0',
      padding: '',
      width: '15vw',
    },
    {
      name: 'kitApelsyn',
      position: '21 / 10 / 23 / 15',
      margin: '',
      padding: '',
      width: '',
      transform: 'rotate(-30deg)',
    },
    {
      name: 'konykHovajetsia',
      position: '26 / 16 / 27 / 26',
      margin: '',
      padding: '',
      width: '',
    },
  ],
}

export const page34_35 = {
  rows: 30,
  cols: 29,
  items: [
    {
      name: 'gusy',
      position: '3 / 3 / 4 / 5',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'gusyText',
      position: '4 / 3 / 10 / 9',
      margin: '',
      padding: '',
      width: '23vw',
    },
    {
      name: 'kozy',
      position: '11 / 3 / 12 / 5',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'kozyText',
      position: '12 / 3 / 14 / 14',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'dykiSvyni',
      position: '14 / 3 / 15 / 8',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'dykiSvyniText',
      position: '15 / 3 / 16 / 14',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'pesykyVijny',
      position: '16 / 3 / 17 / 9',
      margin: '1vw 0 -1vw 0',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'pesykyVijnyText',
      position: '18 / 3 / 28 / 9',
      margin: '',
      padding: '',
      width: '25vw',
    },
    {
      name: 'andrijFoto',
      position: '16 / 9 / 17 / 14',
      margin: '1.8vw 0 -1vw 1.5vw',
      padding: '',
      width: '',
    },
    {
      name: 'tvarynyDopomagajut',
      position: '4 / 19 / 5 / 24',
      margin: '-1.5vw 0 0 0',
      padding: '',
      width: '',
      header: true,
      textAlign: 'center',
    },
    {
      name: 'tvarynyDopomagajut',
      position: '5 / 18 / 6 / 25',
      margin: '',
      padding: '',
      width: '',
      header: true,
      textAlign: 'center',
    },
    {
      name: 'tvarynyDopomagajut',
      position: '6 / 18 / 8 / 23',
      margin: '',
      padding: '',
      width: '',
      header: true,
      textAlign: 'center',
    },
    {
      name: 'piven',
      position: '9 / 18 / 10 / 21',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'pivenText',
      position: '10 / 18 / 18 / 23',
      margin: '',
      padding: '',
      width: '20vw',
    },
    {
      name: 'koty',
      position: '18 / 18 / 19 / 20',
      margin: '0 -0.7vw -0.5vw 0', //check
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'kotyText',
      position: '20 / 18 / 26 / 27',
      margin: '', //check
      padding: '',
      width: '',
    },
    {
      name: 'erikaFoto',
      position: '18 / 23 / 19 / 27',
      margin: '0 -0.7vw -0.5vw 0', //check
      padding: '',
      width: '',
    },
    {
      name: 'vKrasnogorivci',
      position: '26 / 18 / 27 / 27',
      margin: '', //check
      padding: '',
      width: '',
    },
  ],
}

export const page36_37 = {
  rows: 29,
  cols: 23,
  items: [
    {
      name: 'angelZMariupolia1',
      position: '3 / 11 / 4 / 14',
      margin: '-0.5vw 2vw 0 0',
      padding: '',
    },
    {
      name: 'angelZMariupolia2',
      position: '2 / 13 / 3 / 20',
      margin: '-0.5vw 0 0 0',
      padding: '',
      transform: 'rotate(-5deg)',
    },
    {
      name: 'kinIDzhmeli',
      position: '3 / 15 / 5 / 20',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'kinIDzhmeli',
      position: '5 / 15 / 7 / 21',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'kinIDzhmeli',
      position: '4 / 20 / 5 / 21',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'kinIDzhmeli1',
      position: '12 / 19 / 13 / 21',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'kinIDzhmeli1',
      position: '8 / 21 / 14 / 22',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'kinIDzhmeli2',
      position: '8 / 5 / 9 / 13',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'kinIDzhmeli2',
      position: '10/ 13 / 11 / 20',
      margin: '-0.8vw 0 0.4vw -0.5vw',
      padding: '',
      width: '',
      transform: 'rotate(12deg)',
    },
    {
      name: 'kinIDzhmeli3',
      position: '16 / 10 / 17 / 15',
      margin: '-0.5vw -0.8vw 0.3vw 2vw',
      padding: '',
      width: '',
      transform: 'rotate(-30deg)',
    },
    {
      name: 'kinIDzhmeli3',
      position: '14 / 15 / 15 / 18',
      margin: '0 0 0.5vw 0',
      padding: '',
      width: '',
    },
    {
      name: 'kinIDzhmeli3',
      position: '15 / 18 / 22 / 19',
      margin: '-1.5vw -2vw 0 2.5vw',
      padding: '',
      width: '',
      transform: 'rotate(-35deg)',
    },
  ],
}

export const page38_39 = {
  rows: 17,
  cols: 26,
  items: [
    {
      name: 'fotoTaInfo',
      position: '2 / 10 / 4 / 13',
      margin: '0.7vw 0 -0.6vw 0',
      padding: '',
      width: '10vw',
    },
    {
      name: 'pidstavkyPidVulyky',
      position: '5 / 10 / 7 / 13',
      margin: '-0.9vw 0 1vw 0',
      padding: '',
      width: '10vw',
    },
    {
      name: 'vulykyZrobleni',
      position: '8 / 6 / 9 / 12',
      margin: '0.5vw 0 0 -0.5vw',
      padding: '',
      width: '',
    },
    {
      name: 'vulykyVijny',
      position: '10 / 3 / 11 / 11',
      margin: '0 -1.8vw 0.5vw 1.8vw',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'vulykyVijnyText',
      position: '11 / 3 / 14 / 13',
      margin: '0 0 1.5vw 0',
      padding: '',
      width: '38vw',
    },
    {
      name: 'vulykyVijnyText',
      position: '11 / 2 / 12 / 3',
      margin: '1.6vw 0 0.8vw 0.8vw',
      padding: '',
      width: '38vw',
    },
    {
      name: 'dzhmeliv1000',
      position: '2 / 17 / 3 / 25',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'dzhmeliv1000',
      position: '3 / 21 / 4 / 26',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'dzhmeliv1000Text',
      position: '9 / 15 / 16 / 18',
      margin: '',
      padding: '',
      width: '22vw',
    },
    {
      name: 'dzhmeliv1000Text',
      position: '8 / 18 / 15 / 19',
      margin: '',
      padding: '',
      width: '22vw',
    },
    {
      name: 'dzhmeliv1000Text',
      position: '8 / 19 / 11 / 20',
      margin: '0 0 -1vw 0',
      padding: '',
      width: '22vw',
    },
    {
      name: 'dzhmeliv1000Text',
      position: '16 / 15 / 17 / 17',
      margin: '0 1vw 1.5vw 1vw',
      padding: '',
      width: '22vw',
    },
    {
      name: 'dzhmeliv1000Text',
      position: '8 / 20 / 9 / 21',
      margin: '0.7vw -0.3vw 0 0',
      padding: '',
      width: '22vw',
    },
    {
      name: 'dzhmeliv1000Text',
      position: '8 / 16 / 9 / 18',
      margin: '-1vw -0.5vw -1vw -1vw',
      padding: '',
      width: '22vw',
      transform: 'rotate(15deg)',
    },
    {
      name: 'dobriLiudySpaslyBdzhil',
      position: '8 / 20 / 16 / 21',
      margin: '',
      padding: '',
      width: '',
      header: true,
      fontSize: 28,
      mobileFontSize: 18,
      transform: 'rotate(25deg)',
    },
    {
      name: 'dobriLiudySpaslyBdzhil',
      position: '3 / 24 / 9 / 25',
      margin: '0 0 -1.5vw 0',
      padding: '',
      width: '',
      header: true,
      fontSize: 28,
      mobileFontSize: 18,
      transform: 'rotate(39deg)',
    },
  ],
}

export const page40_41 = {
  rows: 26,
  cols: 29,
  items: [
    {
      name: 'angelZChernivciv1',
      position: '2 / 4 / 3 / 10',
      margin: '0 0 -1vw -1vw',
      padding: '',
      transform: 'rotate(-5deg)',
    },
    {
      name: 'angelZChernivciv2',
      position: '3 / 3 / 8 / 4',
      margin: '0 0.5vw 0 -1vw',
      padding: '',
    },
    {
      name: 'konIDub',
      position: '3 / 17 / 4 / 24',
      margin: '0 0 -0.7vw 0',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'konIDub1',
      position: '6 / 16 / 7 / 27',
      margin: '',
      padding: '',
      width: '',
      transform: 'rotate(-25deg)',
    },
    {
      name: 'konIDub2',
      position: '14 / 5 / 16 / 6',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konIDub2',
      position: '12 / 6 / 14 / 7',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konIDub2',
      position: '11 / 6 / 12 / 10',
      margin: '0 0 0.5vw 0',
      padding: '',
      width: '',
      transform: 'rotate(-35deg)',
    },
    {
      name: 'konIDub2',
      position: '7 / 9 / 10 / 10',
      margin: '-0.8vw 0.5vw 0 0',
      padding: '',
      width: '',
    },
    {
      name: 'konIDub3',
      position: '17 / 12 / 22 / 13',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konIDub3',
      position: '14 / 12 / 17 / 13',
      margin: '0 -0.5vw 0 1.5vw',
      padding: '',
      width: '',
    },
    {
      name: 'konIDub3',
      position: '10 / 13 / 14 / 14',
      margin: '0 -0.5vw -1.5vw 1vw',
      padding: '',
      width: '',
      transform: 'rotate(30deg)',
    },
    {
      name: 'konIDub3',
      position: '6 / 14 / 10 / 15',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'konIDub4',
      position: '9 / 24 / 12 / 29',
      margin: '-1vw 0 -0.8vw 0',
      padding: '',
      width: '15vw',
      transform: 'rotate(-20deg)',
    },
    {
      name: 'sijalka',
      position: '21 / 17 / 22 / 19',
      margin: '-1vw 0.5vw 1.8vw 0',
      padding: '',
      width: '',
      transform: 'rotate(-15deg)',
    },
    {
      name: 'mina82mm',
      position: '21 / 10 / 22 / 11',
      margin: '0.5vw 0 -0.5vw 0',
      padding: '',
      width: '',
    },
    {
      name: 'mina82mm',
      position: '19 / 11 / 22 / 12',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'mina82mm',
      position: '19 / 8 / 20 / 11',
      margin: '',
      padding: '',
      width: '',
      transform: 'rotate(-20deg)',
    },
    {
      name: 'konyk',
      position: '20 / 27 / 21 / 29',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'dubovyjLis',
      position: '21 / 27 / 22 / 29',
      margin: '',
      padding: '',
      width: '',
    },
  ],
}

export const page42_43 = {
  rows: 23,
  cols: 29,
  items: [
    {
      name: 'spasenyjVojin',
      position: '2 / 7 / 3 / 13',
      margin: '',
      padding: '',
      width: '',
      header: true,
      fontSize: 30,
    },
    {
      name: 'spasenyjVojinText',
      position: '3 / 7 / 6 / 14',
      margin: '0 0 -1vw 0',
      padding: '',
      width: '30vw',
    },
    {
      name: 'derevaSpasajut',
      position: '8 / 5 / 9 / 15',
      margin: '1vw 0 -1.5vw 0',
      padding: '',
      width: '',
      header: true,
      fontSize: 34,
      transform: 'rotate(-38deg)',
    },
    {
      name: 'SpasenaHataTaRodyna',
      position: '9 / 10 / 12 / 13',
      margin: '0 -1.5vw 1vw 0.5vw',
      padding: '',
      width: '',
      fontSize: 28,
      header: true,
    },
    {
      name: 'SpasenaHataTaRodynaText',
      position: '12 / 7 / 15 / 14',
      margin: '-0.5vw 0 1vw 0.3vw',
      padding: '',
      width: '30vw',
    },
    {
      name: 'roztrosheneMynojuDerevo',
      position: '15 / 6 / 16 / 14',
      margin: '0.5vw 0 -0.5vw',
      padding: '',
      width: '',
    },
    {
      name: 'liudySadzhajut',
      position: '5 / 2 / 7 / 3',
      margin: '0 0 0 0.5vw',
      padding: '',
      width: '',
    },
    {
      name: 'liudySadzhajut',
      position: '7 / 3 / 9 / 4',
      margin: '0 0 -0.5vw 0',
      padding: '',
      width: '',
    },
    {
      name: 'jaPalcemRobliuDirky',
      position: '10 / 2 / 12 / 5',
      margin: '-0.5vw 0 0 -1vw',
      padding: '',
      width: '25vw',
      transform: 'rotate(-29deg)',
    },
    {
      name: 'jaPalcemRobliuDirky',
      position: '8 / 4 / 9 / 10',
      margin: '1vw 0 -0.4vw 0',
      padding: '',
      width: '25vw',
      transform: 'rotate(-29deg)',
    },
    {
      name: 'rozmovaZVojinom',
      position: '11 / 3 / 12 / 6',
      margin: '1vw 0 -1vw 0',
      padding: '',
      width: '15vw',
      transform: 'rotate(-29deg)',
    },
    {
      name: 'budynkyLiudej',
      position: '3 / 17 / 4 / 22',
      margin: '1vw 0 -0.8vw -1vw',
      padding: '',
      width: '',
      header: true,
      fontSize: 34,
      transform: 'rotate(-21deg)',
    },
    {
      name: 'budynkyLiudej',
      position: '3 / 22 / 4 / 25',
      margin: '0 0 0.5vw 0',
      padding: '',
      width: '',
      header: true,
      fontSize: 34,
    },
    {
      name: 'budynkyLiudej',
      position: '3 / 26 / 7 / 27',
      margin: '',
      padding: '',
      width: '',
      header: true,
      fontSize: 34,
      transform: 'rotate(-50deg)',
    },
    {
      name: 'budynkyLiudej',
      position: '6 / 27 / 9 / 28',
      margin: '',
      padding: '',
      width: '',
      header: true,
      fontSize: 34,
    },
    {
      name: 'spasenaShkola',
      position: '11 / 17 / 12 / 26',
      margin: '',
      padding: '',
      width: '',
      header: true,
      fontSize: 28,
    },
    {
      name: 'spasenaShkolaText',
      position: '12 / 17 / 15 / 25',
      margin: '0 -0.5vw 0 1vw',
      padding: '',
      width: '30vw',
    },
    {
      name: 'povertajsiaZhyvym',
      position: '21 / 6 / 23 / 11',
      margin: '0 0.8vw 0.5vw 0.7vw',
      padding: '',
      width: '',
      fontSize: 28,
      transform: 'rotate(6deg)',
      // mobileFontSize: 18,
    },
  ],
}

export const page48_49 = {
  rows: 23,
  cols: 29,
  items: [
    {
      name: 'angelZToshkivky1',
      position: '6 / 5 / 12 / 6',
      margin: '',
      padding: '',
      transform: 'rotate(45deg)',
    },
    {
      name: 'angelZToshkivky2',
      position: '3 / 9 / 9 / 10',
      margin: '0 1vw 0 -0.5vw',
      padding: '',
      transform: 'rotate(65deg)',
    },
    {
      name: 'toshkivskaShkola',
      position: '4 / 17 / 5 / 20',
      margin: '0.5vw -0.7vw -0.7vw 0',
      padding: '',
    },
    {
      name: 'sychZShkoly1',
      position: '16 / 14 / 17 / 16',
      margin: '1vw -1vw 0 0',
      padding: '',
    },
    {
      name: 'sychZShkoly2',
      position: '14 / 16 / 16 / 17',
      margin: '0 1vw -1.5vw -0.5vw',
      padding: '',
      transform: 'rotate(25deg)',
    },
    {
      name: 'sychZShkoly3',
      position: '14 / 17 / 15 / 21',
      margin: '-1.5vw 0 1vw -1vw',
      padding: '',
    },
    {
      name: 'sychZShkoly4',
      position: '13 / 22 / 20 / 23',
      margin: '',
      padding: '',
      transform: 'rotate(-50deg)',
    },
    {
      name: 'konykISych',
      position: '4 / 23 / 5 / 28',
      margin: '-0.5vw 0 0 0',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'konykISych',
      position: '5 / 22 / 7 / 27',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'konykISych1',
      position: '10 / 8 / 11 / 14',
      margin: '0 0 0.5vw 0',
      padding: '',
      width: '',
      transform: 'rotate(-30deg)',
    },
    {
      name: 'konykISych1',
      position: '8 / 14 / 9 / 22',
      margin: '-1vw 0 1.2vw -1.5vw',
      padding: '',
      width: '',
      transform: 'rotate(-8deg)',
    },
    {
      name: 'konykISych2',
      position: '9 / 12 / 10 / 16',
      margin: '0.8vw 0 0 0',
      padding: '',
      width: '',
      transform: 'rotate(-14deg)',
    },
    {
      name: 'konykISych2',
      position: '8 / 16 / 9 / 28',
      margin: '0 -1.5vw -0.5vw -1vw',
      padding: '',
      width: '',
      transform: 'rotate(-6deg)',
    },
    {
      name: 'konykISych2',
      position: '9 / 25 / 10 / 26',
      margin: '-1vw 0 1vw 0',
      padding: '',
      width: '',
    },
    {
      name: 'konykISych3',
      position: '11 / 24 / 13 / 29',
      margin: '-1vw 0 0 0',
      padding: '',
      width: '25vw',
      transform: 'rotate(-28deg)',
    },
  ],
}

export const page50_51 = {
  rows: 31,
  cols: 29,
  items: [
    {
      name: 'podiaka',
      position: '3 / 21 / 5 / 27',
      margin: '0 -0.5vw 0 0',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'podiaka',
      position: '5 / 23 / 7 / 27',
      margin: '0 -0.8vw 0 0',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'shkolaFoto',
      position: '3 / 3 / 4 / 10',
      margin: '0 0.5vw 0 0',
      padding: '',
      width: '',
    },
    {
      name: 'dityFoto',
      position: '10 / 6 / 11 / 11',
      margin: '',
      padding: '',
      width: '',
    },
    {
      name: 'lystyVUKK',
      position: '22 / 7 / 27 / 9',
      margin: '-0.7vw 0 -0.5vw 1.5vw',
      padding: '',
      width: '15vw',
      transform: 'rotate(37deg)',
    },
    {
      name: 'raketaVidGragu',
      position: '2 / 17 / 7 / 18',
      margin: '0 1.7vw 0 -0.8vw',
      padding: '',
      width: '',
    },
    {
      name: 'raketaPopalaVShkolu',
      position: '8 / 18 / 9 / 22',
      margin: '0.5vw 0 0 0',
      padding: '',
      width: '',
    },
    {
      name: 'direktor',
      position: '7 / 17 / 8 / 27',
      margin: '0.5vw 0 -0.5vw 0',
      padding: '',
      width: '',
    },
    {
      name: 'direktor29zhovtnia2014',
      position: '8 / 25 / 10 / 28',
      margin: '0 0 0 -0.8vw',
      padding: '',
      width: '18vw',
    },
    {
      name: 'direktor29zhovtnia2014',
      position: '8 / 28 / 9 / 29',
      margin: '0.9vw 1vw -0.3vw 0',
      padding: '',
      width: '18vw',
    },
    {
      name: 'dityZKatolyckojiShkoly',
      position: '12 / 26 / 15 / 29',
      margin: '-0.5vw 0 0 0',
      padding: '',
      width: '15vw',
    },
    {
      name: 'pidpysyDitej',
      position: '19 / 17 / 30 / 18',
      margin: '0 1.5vw 0 -0.7vw',
      padding: '',
      width: '30vw',
    },
  ],
}

export const page52_53 = {
  rows: 23,
  cols: 27,
  items: [
    {
      name: 'kaligrafia',
      position: '9 / 3 / 10 / 6',
      margin: '1.5vw 0 -0.5vw 0',
      padding: '',
    },
    {
      name: 'drukarnia',
      position: '14 / 3 / 15 / 6',
      margin: '0 0 1vw 1vw',
      padding: '',
    },
    {
      name: 'papirnia',
      position: '18 / 3 / 19 / 6',
      margin: '1vw 1vw 0 1vw',
      padding: '',
    },
    {
      name: 'biblioteka',
      position: '8 / 22 / 9 / 25',
      margin: '-1vw 0 1.5vw 1vw',
      padding: '',
    },
    {
      name: 'shkola',
      position: '9 / 23 / 10 / 25',
      margin: '1vw 1vw 0 -1vw',
      padding: '',
    },
    {
      name: 'cerkva',
      position: '17 / 22 / 18 / 25',
      margin: '0 0 0 1vw',
      padding: '',
    },
    {
      name: 'muzej',
      position: '20 / 23 / 21 / 25',
      margin: '1vw 1vw 0 -1vw',
      padding: '',
    },
    {
      name: 'konykISanktuarium',
      position: '4 / 15 / 6 / 16',
      margin: '-1vw 0 0 0',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'konykISanktuarium',
      position: '5 / 16 / 6 / 23',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'konykISanktuarium',
      position: '3 / 17 / 6 / 23',
      margin: '0 1vw 0 1vw',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'molytvaIrobotaIChytannia',
      position: '2 / 11 / 4 / 12',
      margin: '', //check
      padding: '',
      width: '',
    },
    {
      name: 'molytvaIrobotaIChytannia',
      position: '4 / 12 / 5 / 13',
      margin: '', //check
      padding: '',
      width: '',
    },
    {
      name: 'molytvaIrobotaIChytannia',
      position: '4 / 13 / 6 / 14',
      margin: '', //check
      padding: '',
      width: '',
    },
    {
      name: 'molytvaIrobotaIChytannia',
      position: '5 / 14 / 6 / 15',
      margin: '', //check
      padding: '',
      width: '',
    },
    {
      name: 'molytvaIrobotaIChytannia',
      position: '7 / 15 / 10 / 16',
      margin: '', //check
      padding: '',
      width: '',
    },
    {
      name: 'konykPryviv',
      position: '17 / 8 / 18 / 9',
      margin: '', //check
      padding: '',
      width: '',
    },
    {
      name: 'konykPryviv',
      position: '15 / 9 / 16 / 11',
      margin: '', //check
      padding: '',
      width: '',
    },
    {
      name: 'konykPryviv',
      position: '12 / 11 / 15 / 12',
      margin: '', //check
      padding: '',
      width: '',
    },
    {
      name: 'konykPryviv',
      position: '11 / 12 / 12 / 15',
      margin: '', //check
      padding: '',
      width: '',
    },
    {
      name: 'konykPryviv',
      position: '12 / 15 / 15 / 16',
      margin: '', //check
      padding: '',
      width: '',
    },
    {
      name: 'misceVJakomu',
      position: '19 / 16 / 20 / 17',
      margin: '', //check
      padding: '',
      width: '',
    },
    {
      name: 'misceVJakomu',
      position: '18 / 17 / 20 / 18',
      margin: '', //check
      padding: '',
      width: '',
    },
    {
      name: 'misceVJakomu',
      position: '10 / 18 / 19 / 19',
      margin: '', //check
      padding: '',
      width: '',
    },
    {
      name: 'misceVJakomu',
      position: '9 / 19 / 12 / 20',
      margin: '', //check
      padding: '',
      width: '',
    },
  ],
}

export const page54_55 = {
  rows: 29,
  cols: 29,
  items: [
    {
      name: 'estonia',
      position: '14 / 18 / 16 / 23',
      margin: '1vw 1vw 0 0',
      padding: '',
      header: true,
      transform: 'rotate(-13deg)',
    },
    {
      name: 'spasybi',
      position: '3 / 20 / 5 / 28',
      margin: '',
      padding: '',
      width: '',
      header: true,
      fontSize: 58,
    },
    {
      name: 'konykVezePodiaku',
      position: '8 / 18 / 13 / 20',
      margin: '',
      padding: '',
      width: '32vw',
      header: true,
      textAlign: 'center',
    },
    {
      name: 'konykVezePodiaku',
      position: '7 / 20 / 13 / 22',
      margin: '',
      padding: '',
      width: '32vw',
      header: true,
      textAlign: 'center',
    },
    {
      name: 'konykVezePodiaku',
      position: '7 / 22 / 12 / 23',
      margin: '-0.5vw 0 0.5vw 0',
      padding: '',
      width: '32vw',
      header: true,
      textAlign: 'center',
    },
    {
      name: 'konykVezePodiaku',
      position: '6 / 23 / 11 / 24',
      margin: '1vw 0 -0.9vw 0',
      padding: '',
      width: '32vw',
      header: true,
      textAlign: 'center',
    },
    {
      name: 'konykVezePodiaku',
      position: '6 / 24 / 10 / 28',
      margin: '',
      padding: '',
      width: '32vw',
      header: true,
      textAlign: 'center',
    },
  ],
}

export const page56_57 = {
  rows: 25,
  cols: 25,
  items: [
    {
      name: 'ukrkultfond',
      position: '2 / 3 / 3 / 12',
      margin: '1vw 0 -0.3vw 0',
      padding: '',
      width: '',
      header: true,
      fontSize: 24,
    },
    {
      name: 'eestifilmfest',
      position: '3 / 5 / 4 / 10',
      margin: '0.4vw 0 1vw -0.7vw',
      padding: '',
      width: '',
      header: true,
      fontSize: 24,
    },
    {
      name: 'docfilmkazkaprokonyka',
      position: '7 / 3 / 8 / 14',
      margin: '', //check
      padding: '',
      width: '',
      header: true,
      fontSize: 25,
    },
    {
      name: 'cejFilmMyPlanujemo',
      position: '15 / 3 / 23 / 5',
      margin: '0 0.8vw 0 -0.7vw',
      padding: '',
      width: '10vw',
    },
    {
      name: 'Ukraina',
      position: '9 / 11 / 10 / 14',
      margin: '0 2vw 0 0.5vw',
      padding: '',
      width: '',
      header: true,
      fontSize: 26,
    },
    {
      name: 'surnames',
      position: '10 / 11 / 23 / 13',
      margin: '1vw -0.8vw -0.7vw 0.5vw',
      padding: '',
      width: '',
    },
    {
      name: 'kuliaJakuDobryjLikar',
      position: '6 / 14 / 9 / 17',
      margin: '',
      padding: '',
      width: '10vw',
    },
    {
      name: 'denysFilmuvavMajdan',
      position: '6 / 17 / 7 / 25',
      margin: '0 0 -0.5vw 0',
      padding: '',
      width: '32vw',
    },
    {
      name: 'andrijKumSestry',
      position: '9 / 20 / 14 / 21',
      margin: '-1vw 1vw -0.5vw 0',
      padding: '',
      width: '',
    },
    {
      name: 'dobriLikariZa4Godyny',
      position: '14 / 21 / 15 / 25',
      margin: '-0.5vw 0 0 -0.8vw',
      padding: '',
      width: '15vw',
    },
    {
      name: 'ulianaVTojChasVidpravliala',
      position: '15 / 15 / 17 / 20',
      margin: '-1vw 0 0 0',
      padding: '',
      width: '40vw',
    },
    {
      name: 'ulianaVTojChasVidpravliala',
      position: '15 / 20 / 17 / 21',
      margin: '',
      padding: '',
      width: '40vw',
    },
    {
      name: 'ulianaVTojChasVidpravliala',
      position: '16 / 21 / 17 / 25',
      margin: '',
      padding: '',
      width: '40vw',
    },
    {
      name: 'zTogoChasuKrimDenysaVonaPoliubyla',
      position: '18 / 14 / 25 / 17',
      margin: '-0.5vw 0 2vw 1.5vw',
      padding: '',
      width: '38vw',
    },
    {
      name: 'zTogoChasuKrimDenysaVonaPoliubyla',
      position: '23 / 17 / 24 / 25',
      margin: '0 1vw -1vw 0',
      padding: '',
      width: '38vw',
    },
    {
      name: 'UlianaTaDenysZnimajut',
      position: '17 / 17 / 18 / 24',
      margin: '0.7vw -0.9vw 0.8vw 1vw',
      padding: '',
      width: '',
    },
    {
      name: 'UlianaTaDenysZnimajut',
      position: '18 / 21 / 19 / 24',
      margin: '0 -1.2vw 1vw 0',
      padding: '',
      width: '',
    },
  ],
}

export const page58 = {
  rows: 26,
  cols: 29,
  items: [
    {
      name: 'analolij',
      position: '12 / 4 / 13 / 11',
      margin: '',
      padding: '',
      width: '',
      header: true,
    },
    {
      name: 'text',
      position: '13 / 4 / 20 / 11',
      margin: '',
      padding: '',
      width: '30vw',
      mobileWidth: '60vw',
    },
  ],
}
