import React from 'react'

import { StyledPage, StyledWrapper } from '../styled'
import pageToCount from '../../assets/images/köide_003.png'
import { StyledHome } from '../../Home/styled'
import Languages from '../../Home/Languages/Languages'

const CheckDeviceMode = ({ t, pageToCountRef }) => {
  return (
    <StyledWrapper className="wrapper" justifyContent="center">
      <StyledPage className="hidden">
        <img src={pageToCount} alt="countImg0" ref={pageToCountRef} className="hidden" />
      </StyledPage>
      <StyledHome className="checkDeviceMode">
        <Languages className="top" />
        <div style={{ marginTop: -10 }}>
          <h1>{t('common:landscape')}</h1>
          <h1 style={{ marginTop: '20px', display: 'block' }}>{t('common:rotate')}</h1>
          {/*<a data-tip data-for="clickme" data-event="click">*/}
          {/*  (❂‿❂)*/}
          {/*</a>*/}
          {/*<ReactTooltip id="clickme" place="right" effect="solid" clickable={true}>*/}
          {/*  <input type="text" placeholder="Type something..." />*/}
          {/*</ReactTooltip>*/}
        </div>
      </StyledHome>
    </StyledWrapper>
  )
}

export default CheckDeviceMode
