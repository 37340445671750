import { useCallback, useEffect, useState } from 'react'

export const useElementRect = (ref) => {
  const getRect = useCallback(() => (ref && ref.current ? ref.current.getBoundingClientRect() : undefined), [ref])

  const [elementRect, setElementRect] = useState(getRect)

  const getRectReturnValues = () => {
    // console.log(elementRect)
    if (elementRect) {
      const { height, width, x, y, top, right, bottom, left } = elementRect
      return { height, width, x, y, top, right, bottom, left }
    }
    return {}
  }

  useEffect(() => {
    // console.log(ref.current, elementRect)
    if (ref.current && (!elementRect || (elementRect && !elementRect.height))) {
      setTimeout(() => {
        setElementRect(getRect())
      }, 100)
    }
  }, [getRect, ref, elementRect])

  useEffect(() => {
    if (ref.current) {
      setElementRect(getRect())

      const handleResize = () => {
        setElementRect(getRect())
      }

      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [getRect, ref]) // Empty array ensures that effect is only run on mount and unmount

  return getRectReturnValues()
}
