// eslint-disable-next-line import/no-anonymous-default-export
export default {
  common: {
    kazkaProKonyka: 'Sõda ja Puuhobune',
    landscape: 'Seda raamatut saab vaadata ainult maastiku režiimis.',
    rotate: 'Kontrollige ekraani pööramise seadeid ja pöörake seadet horisontaalselt.',
    // mobile: 'Seda raamatut saab kõige paremini vaadata töölaual. Palun külastage saiti oma arvutist.',
    search: '',
    zoomIn: '',
    zoomOut: '',
    createdWithHelpOfUkrInstitute: 'Sait loodi Ukraina Instituudi toel',
    howToTranslateTip: 'Tõlke lugemiseks liiguta \n kursor teksti peale\n',
    mobileTranslateTip: 'Tõlke lugemiseks\nklõpsake tekstil.',
    // mobileZoomTip: 'Suumimiseks või vähendamiseks sirutage või lohistage kahte sõrme.',
    mobileZoomTip: 'Suumimiseks\nvõi vähendamiseks\nkasutage topeltklõpsu.',
    swipe: 'Pühkige',
  },
  contentTexts: {
    content: 'sisukord',
    swipeText: 'Pühkige \njärgmisele lehele',
  },
  content: {
    volunteers_1: 'lk 1 Hobu ja Vabatahtlikud',
    dog_5: 'lk 5 Hobu ja Koer',
    cows_9: 'lk 9 Hobu ja Lehmad',
    mouse_13: 'lk 13 Hobu ja Hiir',
    fish_17: 'lk 17 Hobu ja Kala',
    goat_21: 'lk 21 Hobu ja Kits',
    bird_25: 'lk 25 Hobu ja Lind',
    hare_29: 'lk 29 Hobu ja Jänes',
    cats_33: 'lk 33 Hobu ja kaks Kassi',
    bumblebees_37: 'lk 37 Hobu ja Kimalased',
    oak_41: 'lk 41 Hobu ja Tamm',
    owl_49: 'lk 49 Hobu ja Kakk',
    sanctuary_53: 'lk 53 Hobu ja Sanctuarium',
    gratitude_55: 'lk 55 Tänusõnad Eestile',
    film_57: 'lk 57 Dokumentaalfilm',
    author_58: 'lk 58 Raamatu Autor',
  },
  last: {
    nadSaitomPracuvaly: 'Veebisaidi meeskond:',
    avtorKnygy: 'Raamatu Autor',
    avtorKnygyName: 'Anatoli Ljutjuk',
    avtoryProektu: 'Projekti autorid',
    elektronniShryfty: 'Elektroonilised fondid',
    rozrobkaSaytu: 'Veebilehe arendaja',
    perekladyNaEstonsku: 'Eestikeelne tõlge',
    perekladyNaAngl: 'Ingliskeelne tõlge',
    finansy: 'Rahandus',
    avtoryProektuName: 'Uljana Osovska\nDeniss Strashnyj',
    elektronniShryftyName: 'Mara Zabashta',
    rozrobkaSaytuName: 'Oleksandra Harahulja',
    perekladyNaEstonskuName: 'Katja Novak',
    perekladyNaAnglName: 'Uljana Fedoranchenko',
    finansyName: 'Uljana Osovska\nOleksandra Hluschenko',
    knygaStvorenaZaPidtrymkyUKF: 'Raamat loodi Ukraina Kultuuri Fondi toel',
    saytStvorenyjZaPidtrymkyUkrInst: 'Veebileht loodi  Ukraina Instituudi toel “proMotion” programmi raames',
  },
  texts: {
    //page0
    year: '2014-2020\n',
    kazkaProKonyka: 'Sõda \nja\n Puuhobune',
    knygaProLiudejZviriv: 'Raamat Inimestest, Loomadest ja Headusest sõjas\n',
    anatolijLiutiuk: 'Anatoli Ljutjuk \nja sõbrad\n',
    //page1
    donbas: 'Donbass',
    mistaZliva1: 'Lugansk, Donetsk, Avdijivka, Piskõ, Vodjane',
    mistaZliva2: 'Lugansk, Donetsk, Avdijivka, Piskõ, Vodjane',
    mistaSprava1: 'Kyiv, Poltava',
    mistaSprava2: 'Ternopil, Lviv, Ivano-Frankivsk, Võžnõtsia',
    mistaSprava3: 'Ternopil, Lviv, Ivano-Frankivsk, Võžnõtsia',
    jakKonykVolonteromStavTitle: 'Kuidas\n Hobu\n vabatahtlikuks sai',
    jakKonykVolonteromStavText:
      '\t1.\tHobu sündis Võžnõtsjas, kunstikoolis.\n' +
      '\t2.\tTa unistas tiibadest, et lennata üle Karpaatide.\n' +
      '\t3.\tAjal, kui Ukrainasse saabus  Suur Häda, valmistas ise endale tiivad ja lendas Donbassi rindejoonele inimesi abistama.\n',
    mistoVSviti: 'Koht, kus kõik inimesed aitavad hobustel kohtuda. \n',
    //page2-3
    papirDliaKnygy:
      'Selle raamatu jaoks paberi valmistamisel oli lisatud Viktori sõbra ja härra Myhailo riiete tükke nende perede loal\n',
    viddalySvojeZhyttia: 'Andsid oma elu... Inimesi abistades...',
    miscePamjati: 'Mälestuskoht',
    z2014RokuRadaCerkvivEstonii:
      '2014. a korraldas Eesti Kirikute Nõukogu  üle-Eestilise jumalateenistuse “Palve Ukraina eest”.\n Sellest ajast toimub ka meie kiriku mälestuskohal igapäevane palve mälestamaks neid, kes andsid oma elu Ukraina eest. ',
    viktor: 'Viktor Gurnjak\n 1987-2014',
    viktorText:
      "Tema suri inimesi pommitamise alt ära viies.\n Ajakirjanik, sõjaväelane, fotokorrespondent, vabatahtlik, Plast'i kasvandik. (Plast - ukraina skaudiorganisatsioon)",
    myhajlo: 'Mõhailo\n Stasiv\n 1968-2016',
    myhajloText:
      'Suri infarkti pärast sõjast naasmist.\n Kirurgina tegi operatsioone kindlustatud muldonnides ja kaitsekraavides Debaltseve linna lähedal.',
    olenaTaVolodymyr: 'Olena ja Volodõmõr Kuliš',
    olenaTaVolodymyrText:
      '2014. a lasti neid maha selle eest, et tõid toitu inimestele lahingutsooni.\n Koos nendega lasti maha seitset koera, kellele Olena ja Volodõmõr andsid varjupaika oma majas.',
    //page4-5
    angelZTernopolia1: 'ingel Ternopolist',
    angelZTernopolia2: 'ingel Ternopolist',
    konykIPesykTitle: 'Hobu ja\nKoer',
    konykIPesyk1: '\t1.\tStepis oleks Hobu äärepealt tabanud miin. Koer Reks kaitses Hobu ja sai vigastada.\n',
    konykJakogoSpasPesyk: 'Hobu, kelle elu sõjas päästis Reks.',
    konykIPesyk2: '\t2.\tKoer jäi ellu!\n',
    konykIPesyk3: '\t3.\tHobu viib vigastatud Reksi haiglasse ja ravib teda samal ajal.\n',
    //page6_7
    dobriLiudySpaslyPesyka: 'Head inimesed päästsid vigastatud koera elu.\n',
    blagodijnyjFond:
      'Heategevusfond ASAP EMS Hottabõtš\n' +
      '\t•\tReksi päästsid Oleksandr hüüdnimega Kabantšõk (Metsseake), Roman hüüdnimega Romeo ja Nadijka.\n' +
      '\t•\tReksi vigastati Majorski lähedal, mitte kuigi kaugel Žovanka külast.\n' +
      '\t•\tPärast seda aitasid Reksi arstid Tšasiv Jari haiglas.\n' +
      '\t•\tSiis viidi Reks Toretskisse. Operatsiooni tegi haavaarst Oleksij ja anestesioloog Viktor.\n' +
      '\t•\tTal tuvastati miinihaav. Tema kehast võeti välja 15 kildu.\n' +
      '\t•\tNiiviisi päästis Reks omaniku ja tema sõbra. \n',
    pesykReksSpas: 'Koer\nReks\n päästis\n häid\n inimesi.\n',
    lystRomashky:
      'Tere päevast!\n' +
      'Siin Romaška (Võilill). See on mu hüüdnimi sõjas. Tahan jutustada teile meie koer Reksist. See koer kaitses kahte sõdurit. Ja sai raskelt vigastada. Pärast operatsiooni hoolitsesime tema eest veel neli kuud. Ta sai maitsvaid kinke tervest maailmast. Nüüd on Reks omanikuga kodus. \n' +
      'Lugupidamisega\n' +
      'Romaška\n' +
      'P.S Saadan ka süsti-, sideme- ja ravimipakid. \n',
    reksZakryv2bijciv: 'Reks kattis endaga kahte sõdurit, seepärast sai haavata',
    likyJakymyReksaVyvodyly: 'Ravimid, mida kasutati Reksi taastamiseks pärast šokiseisundist\n',
    reksPisliaNadannia: 'Reks pärast abi saamist\n',
    travaZBlokpostu: 'Rohi kontrollpunkti juures, Zaitseve linna sissesõidu ees\n',
    //page8_9
    konIKorovy: 'Hobu ja\n Lehmad\n',
    minomet: 'Miinipilduja Vasiljok kuulipilduja \nja graanadiheitjaga.\n',
    spalyvKorovamHatkuJabluniu: '\t1.\tSüütas lammaste tare.\nTappis lehma Jana.\nMurdis õunapuud.',
    konykVytiagujeZVogniu: '\t4.\tHobu päästab lehma tulekahjust.\n',
    rozbytaJablunia: '\t1.\tMurtud õunapuu\n',
    konIKorovy3: '\t3.\tLehm Tšornuška ajab sabaga kuule eemale otsekui kärbseid.\n',
    //page10_11
    ogoloShenniaZiStovpiv: 'Kuulutus Marjinka teadetetahvilt.\n',
    odnaGryvnia: 'Üks grõvnja (Ukraina valuuta)\n',
    pochatokIstorijiLyst: 'Jutu algus (Mõhailo Uhmani kiri)\n',
    lystText:
      'Jakiv Lukõtš – on Marjinka elanik, kes elas täpselt rindejoonel. Sel vanal ja pimedal mehel on lehm. Vabatahtlikud päästsid pommitamisel tema ja ta lehma. Kui olete huvitatud, andke aga mulle teada. Mul oleks rõõm teid aidata.\n' +
      'Lugupidamisega, Mõhailo Uhman, ajakirjanik, vabatahtlik.',
    hlivChornushy: 'Laut, kus Tšornuška elas.\n',
    jakivLukich: 'Jakiv Lukõtš',
    jakivLukichText:
      'Jakiv Lukõtš jutustas meile, et 25. novembril pommitati Marjinkat. Pommitamise käigus tabas granaat lauta. Laut läks põlema, head inimesed ruttasid päästma loomi, kes olid põlevas laudas.\n' +
      'Nad päästsid lehma Tšornuška ja kaks kassi.',
    myhajloUhmanText:
      'Mõhailo Uhman, sõjaväeajakirjanik, viis meid (Anatolit, Uljana Osovskat, Denõs Strašnõjd) Maksõmivka külla tutvuma ühe väga huvitava inimesega, kelle elu on Donbassi konfliktiga seotud.',
    chornushka: 'Tšornuška\n',
    chornushkaText:
      'Kahjuks aga lehm Jana, kass Žuža põlesid, hukkusid ka pääsukesed oma pesas.\n\n' +
      'Tšornuška elab praegu Maksõmivka külas ja sünnitas mullika.',
    dobriLiudySpaslyKorovu: 'Head inimesed päästsid lehma.\n',
    //page12_13
    angelZLutska: 'ingel Lutskist',
    kinIMysha: 'Hobu\n ja Hiir\n',
    kinIMysha1: '\t1.\tHobu, kes kartis hiiri.\n',
    kinIMysha2: '\t2.\tTank purustas kiriku ja hiire kodu ning murdis lilled.\n',
    kinIMysha3: '\t3.\tHobu oli ehmunud, tal oli Hiirest kahju ja ta ehitas talle uue ilusa kodu.\n',
    //page14_15
    HannaTerjanik: 'Hanna Terjanik',
    HannaTerjanikText:
      'Vigastatud viidi eesliinilt ära. Sõja esimesel talvel (2014-2015 veebruar) viidi Debaltseve lähistelt minema jalavigastusega sõdur hüüdnimega Sirõi (Hall). Kui valu oli vaibunud, jutustas ta oma elust ATOs. (Terrorismi vastane operatsioon Ida-Ukrainas, sõja ametlik nimi kuni 2019)',
    rozpiznavalnyjPrapor: 'Eralduslipp Hanna autolt.\n',
    travynkaZirvana: 'Taim, mis on korjatud haigla aias, kuhu Hanna tõi vigastatud sõdurid.',
    myshiDonbasu: 'Donbassi hiired abistavad inimesi',
    dopomagajutLiudiam: 'abistavad inimesi', // нет в разметке
    myshiDonbasu1:
      'Muidugi tegid hiired sõduritele kahju (mõnikord sõid isegi varuks pandud sigarette). Aga tihtipeale usaldavad need väikesed olendid oma elu inimestele, lubavad end silitada, võtavad käest toitu, elavad nende jaoks tehtud pesades.\n' +
      'Niisugune „kaitsekraavi lohutus“.',
    myshiDonbasu2:
      '2016. aastal tõime pagulastele humanitaarabi. 15.11.2016. А ööbisin Kramatorskis Kyivi polgu asukohas. \n' +
      'Natüütmort mu voodi kõrval.\n' +
      '16.11.2016 kohtasin sõdurit, kes oli oma neljandas sõjas. Ta oli sõdinud veel Afganistanis, Karabahhis,\n' +
      'Tšetšeenias ja Donbassis. Hüüdnimega Vadim.\n' +
      'Ta rääkis, et 2015. aastal oli tal üks sõber, kes tegi hiirele pesa, pakkus talle kuivikuid ja ajas temaga öösiti juttu. See hiir aga ei läinud teistele sõduritele sülle. Selline lugu.',
    vTojChasVPiskah:
      'Samal ajal oli Piskõs ka 95. brigaad, Karpatska Sitšist pärit vabatahtlikud ja teised abilised. (Karpatska Sitš on pataljoni nimetus)\n',
    maliunky: 'Joonistused Donbassi reisidest. Lood.\n',
    //page16_17
    angelZHersonu1: 'ingel Khersonist',
    angelZHersonu2: 'ingel Khersonist',
    konykIRyba: 'Hobu ja Kala\n',
    konykIRyba1: '\t1.\tGranaadiheitja purustas tammi.\n',
    konykIRyba2: '\t2.\tVaestel kaladel, vähkidel, konnadel, lindudel, liiliatel ja liuskuritel pole elamiskohta.\n',
    konykIRyba3: '\t3.\tHobu parandab tammi.\n',
    granatomet: 'granaadiheitja',
    //page18_19
    childrenFromKarlivka: 'Lapsed Karlivkast\n',
    childrenFromKarlivkaText:
      '5.12.2017 kuulsin kolme teismelise juttu. „Kas sa tuled koos meiega järve äärde kalu uimastama?“ \n' +
      'Ei tea, kas nad ikkagi läksid kalu uimastama või mitte, aga olen täiskasvanutelt kuulnud, et lapsed astuvad mineeritud põldudele ja tõmbavad granaate lõksmiinidest välja.\n' +
      '30.04.2015 tõmbasid neli teismelist granaadi lõksmiinist välja, et kalu uimastada.',
    stavokTaLis: 'Järv ja mets – Donbassi laste meelispuhkekohad, mis on praegu muutunud ohtlikuks.\n',
    rybalkaBezVudok: 'Kalapüük ilma õngeta – miinide ja granaatidega.\n',
    lystShkoliara: 'Kiri õpilaselt õpetajale\n',
    lystShkoliaraText:
      'Tervist!\n' +
      'Veetsin suve kenasti. Käisin järve ääres. Lisaks sellele sõin marju. Tegin kodutöid ja õppisin reegleid. Ja päevitasin. \n' +
      'Teie õpilane',
    stavok: 'Järv',
    stavokText:
      '2016. aasta 9. märtsil jõudsime meie - Sanitar, Lõs (Rebane), Anna Bridt - Donetski lähedal asuva järve äärde.  Lõs jutustas, et inimesed viskavad tihtipeale sinna miine ja uimastavad kalu.\n' +
      'Samuti lõhuti pais. Head inimesed tegid koos vabatahtlikega paisu ja silla korda.\n' +
      'Piskõ poolt minnes asub järv rindejoonelt nelja kilomeetri kaugusel.',
    dobriLiudyRemontuyut: 'Head inimesed parandavad paisud ega viska miine ja granaate järve.\n',
    diakuyuShoOberigaete: 'Täname, et meid kaitsete!\n',
    //page20_21 - to check
    konykIKoza: 'Hobu ja Kits\n',
    konykIKoza1: '\t1.\tKahur „Savuška“ tulistab Kitse.\n',
    konykIKoza2: '\t2.\tKits ja kitsetalled on ohelikuga kinni seotud.\n',
    konykIKoza3:
      '\t3.\tHobu heitis nööriga kivi kui lingu, tabas ohelikku, tõmbas selle lahti ning päästis Kitse ja kitsetalled.\n',
    konykIKoza4: '\t4.\tHobu aitab Kitsel ja talledel pommitamise eest terve nahaga pääseda.\n',
    pidAvdiivkoiu: 'Avdijivka lähedal',
    angelZIvanoFrankovsku1: 'ingel Ivano-Frankivskist',
    angelZIvanoFrankovsku2: 'ingel Ivano-Frankivskist',
    angelZIvanoFrankovsku3: 'ingel Ivano-Frankivskist',
    //page22_23
    zrujnovanyiBudynky: 'Pommitatud majad. 25.04.2018.\n',
    seloVodiane: 'Vodjane küla',
    kozy4IBaba: 'Neli kitse \nja Vanaema Zoja\n',
    kozy4IBabaText:
      'Vanaema Zoja elas Vodjane külas oma pisikese majapidamise ja 11 kitsega. Aimamata  võimalikku pommitamist ja häda, sidus ta oma kitsed põllul kinni. Siis tuli 16 mürsku, mis tabasid seitset kitse, vigastasid kassi, murdsid ploomipuud, purustasid kapsaid.',
    kozaMykolyFoto: 'Mõkola Ivanovitši kits pärit Vodjane külast, 27.03.2018',
    trava: 'taim Avdijivkast',
    babaZojaFoto: 'Vanaema Zoja oma majas.\n',
    Avdijivka: 'Avdijivka',
    kozaIkozliata: 'Kits talledega\n',
    kozaIkozliataText:
      'Avdijivka lähedal, rinde lahingjoonel, pommitamise alas, kümme meetrit enne kaitsekraavi seisab kits talledega, kes on sinna kinni seotud või ise takerdunud. Kui pommid lendasid, kitsed ehmusid ja määgisid valjusti. Sõdur hüüdnimega Mõkola sidus nööri ümber kivi ja, hoides ise varju, viskas selle kitsede poole seni, kuni päästis nad lahti.',
    kozaIHlopec: 'Kits ja poiss\n',
    kozaIHlopecText:
      '23.05.2017 pommitati Avdijivkat. Tulistati „Gradidest“. (“Grad” on reaktiivmiinipilduja). Kaheksa mürsku tabasid majasid. Üks nooruk sai vigastada. Lõhuti ka kuur ja tapeti 15 hane, koera ja kitse.',
    //page24_25
    konykIPtashkaTitle: 'Hobu ja\nLind\n',
    konykIPtashka1: '\t1.\tPuu otsas oli linnupesa.\n Puu all oli lõksmiin.\n',
    granata: 'Granaat',
    konykIPtashka2: '\t2.\tLind hüüdis, et keegi paigaldas lõksmiini puu alla.\n',
    konykIPtashka3:
      '\t3.\tHobu, kes oli esimest korda rindejoonel, ei teadnud sellest aga midagi. Kui seda lindu poleks olnud, lasknuks ta õhku nii enda, oma sõprad, linnupojad ja kõik teised 200m raadiuses.',
    shnurSpletenyjZZhylky: 'Traat, mis ei paindu. \n',
    //page26_27
    Donbas: 'Donbass',
    zaminovanaZemlia: 'Mineeritud maa\n',
    naPidkontrolnijUriadu:
      '2014.-2018. aastatel hukkus Ukraina valitsuse kontrolli all oleval alal 428 inimest, neist 21 last. Vigastada said 1858 inimest.\n',
    zagynulo428liudej: '', //same as prev
    dytyna21: '21 last',
    vybuhovonebezpechnyk37300: '373000 plahvatusohtlikku eset.\n',
    photoAndrij: 'Andrii Dubtšaki pildid.\n',
    promka: 'Promka (Tööstusrajoon)\n',
    promkaText:
      '15.11.2016 olin Avdijivkas, sealt sõitsime Promkasse. Tsitadellist natuke kaugemal jõudsime lõhutud majani. Läksime autost välja. Mina suundusin ühe linnu juurde, kes oli puu otsas.\n Lind pistis karjuma ja ma keerasin tagasi. \nHiljem näitasid poisid mulle lõksmiini, millest mul jäi paar sammu puudu.\n',
    dobriLiudyRozminovujut: 'Sõbralikud inimesed\n demineerivad maad.\n',
    maliuvanniaZNatury: 'Joonistus natuurist\n',
    photoUlizDenys: 'Pildid Uljana Osovska, Denõs Strašnõj.\n',
    //page28_29
    angelZVinnyci1: 'ingel Vinnõtsjast',
    angelZVinnyci2: 'ingel Vinnõtsjast',
    konIZajac: 'Hobu ja Jänes\n',
    konIZajac1: '\t1.\tMasin Grad tulistab Jänest.\n',
    konIZajac2: '\t2.\tJänes peidab end Graadi eest.\n',
    konIZajac3: '\t3.\tHobu päästab Jänese.\n',
    konykNaBMP: 'Hobu viib rauteril Jänese pommitamise eest ära.\n',
    //page30_31
    zajciDonbasu: 'Donbassi jänesed\n',
    stat: 'Statistika',
    statText:
      'Metsavarumise riigiagentuur:\n' +
      '2014-2016. aastatel oli Donetski ja Luganski oblastis 1068 põlengut. Põles maha 16621 hektari ulatuses metsa. \n' +
      'Kaitseala „Pühad mäed“. Andmed jäneste kohta: \n' +
      '2012. aastal elas metsas 114 913 jänest.\n' +
      '2016. aastal oli alles 63 259 jänest.',
    terytorijaOdnogoZajcia: 'Ühe jänese lugu\n',
    terytorijaOdnogoZajciaText:
      '2015. aasta aprillis oli valvekord sõduril, kelle hüüdnimi on Strõž (Piiritaja). Kaitsekraav oli hästi varustatud ja selle ees seisis vana murtud juurtega akaatsia. Öö oli vaikne ja õhus midagi ei lennanud, tööpostil oli vaid snaiper. \n' +
      'Umbes kell kaks öösel lendas 82mm miin. Pärast plahvatust kuulis Strõž õrna trummi kõla. Ta vaatas kraavist välja ja nägi jänest puu juurte vahel. Jänes vaatas sinna poole, kust miin tuli, ning andis käpaga klobistades teistele jänestele ja üldse kõikidele teada, et  see on tema territoorium.',
    pozyvnij: 'Hüüdnimi Strõž, 16.11.2015, Avdijivka\n',
    zajecVBMP2: 'Jänes BMP-2-s\n',
    zajecVBMP2Text:
      '22. aprillil süttis pärast suurtükituld Pühade Mägede kaitseala. Tulekahju ja mürskude eest põgenedes  hüppas jänes luugist soomukisse. Ta ei kartnud sõdureid ning hakkas nende käest hiljem isegi leivakuivikuid sööma. Talle osteti porgandikott ja ta jäi soomukisse elama.',
    dobriLiudy: 'Head inimesed hoiavad jäneseid\n',
    infoPojizd: 'Rong Kõjiv – Konstjantõnivka, 4.12.17, Poltavast pärit Igor hüüdnimega Hõžak (Kiskja)\n',
    //page32_33
    konykIDvaKoty: 'Hobu ja\n kaks Kassi\n',
    konykIDvaKoty1: '\t1.\tGranaadiheitja, mis tahab tarka Kassi ja sõbralikku Hobu tulistada.\n',
    konykIDvaKoty2:
      '\t2.\tTargad kassid Apelsin ja Persik teavad, millal granaadiheitja tulistab – jooksevad ringi, hüppavad ja hoiatavad Hobu, et ta läheks keldrisse varju. \n',
    konykIDvaKoty3: '\t3.\tHobu tegi keldri lahti, päästis end ja Kassi.\n',
    konykIDvaKoty4: '\t4.\tSõbraliku Vanaema kelder, kuhu mürsud ei pääse. Seal peidavad end kõik pommitamise eest.\n',
    kitApelsyn: 'Kass Apelsin varjub tulistamise eest.\n',
    konykHovajetsia: 'Hobu peidab ennast Sõbraliku Vanaema keldrisse. \n',
    //page34_35
    gusy: 'Haned',
    gusyText:
      'Luganski oblastis Krõmske külas A-sektoris elas hani Grõša. 10-15 min enne pommitamist aimas ta miine ja mürske, karjus ja peitis ennast kaitsekraavi. Siis läksime meie ka varju. Grõša toksis samuti nokaga teisi võõraid inimesi.\n' +
      'Oleksandr Donetskist. Hüüdnimega Bober (Kobras) ',
    kozy: 'Kitsed',
    kozyText:
      'Donetski oblastis Vodjane külas tulistati seitset kitse. Neli jäid ellu ja hakkasid pommitamisi aimama. Peidavad ennast ja hoiatavad teisi. \n',
    dykiSvyni: 'Metssead',
    dykiSvyniText:
      'Luganski oblastis taltsutasid sõdurid metssigu. Nagu koerad annavad metssead märku võõraste saabumisest.\n',
    pesykyVijny: 'Sõja koerakesed',
    pesykyVijnyText:
      '„Sõja koerakesed“ – Andrii, hüüdnimega Kelt, jutt sõjast.\n' +
      'Koerakesed – Keldi sõbrad:\n' +
      '\t•\tAlja – hoiatas omanikku vaenlase saabumise eest.\n' +
      '\t•\tDozor (Piilkond) – nuttis oma tapetud omaniku pärast.\n' +
      '\t•\tBars – hüppas lõksmiini peale ja päästis inimesi.\n' +
      '\t•\tBilotška (Orav) – ootab õues omanikku juba tund enne selle saabumist \n' +
      '\t•\tRem – aimab pommitamist ja hoiatab inimesi.\n',
    andrijFoto: 'Andrii, hüüdnimega Kelt',
    tvarynyDopomagajut: 'Loomad\n abistavad \ninimesi\n hädas.\n',
    piven: 'Kukk\n',
    pivenText:
      'Kaitse eesliinile tuli suur kukk. Ta käis nii kaitsekraavides kui ka kindlustatud muldonnides ringi, toksis nokaga kassi, sõi kuivikuid ja jäi sõdurite juurde elama. Enne pommitamist hüüdis ta valjusti, et kõik läheksid varju. Selline peremees oli. \n' +
      'Kramatorsk, hüüdnimi Škala, 2019. a.',
    koty: 'Kassid',
    kotyText:
      'Marjinkas elasid „Donetski“ monumendi lähedal majas kaks kassi. Üks oranži värvi Apelsin ja teine Persik. 5-10 min enne pommitamist hakkasid nad toas ringi jooksma ja kargama. Kraapisid keldriluuki. Kui luuk tehti lahti, kargasid kassid esimesena keldrisse. \n' +
      'Marijinka, 2017a. ',
    erikaFoto: 'Erika, hüüdnimega Laska (Nirk)\n',
    vKrasnogorivci: 'Krasnohorivkas viis kass enne pommitamist kassipojad keldrisse.\n',
    //page36_37
    angelZMariupolia1: 'ingel Mariupolist Slavne külas',
    angelZMariupolia2: 'ingel Mariupolist Slavne külas',
    kinIDzhmeli: 'Hobu ja\n Kimalased\n',
    kinIDzhmeli1: '\t1.\tGranaadiheitja tulistab kimalasi.\n',
    kinIDzhmeli2: '\t2.\tGranaadiheitja lõhkus kimalaste pesa.\n',
    kinIDzhmeli3: '\t3.\tHobu ehitas kimalastele pesa.\n',
    //page38_39
    fotoTaInfo: 'Piltide autor ja infoallikas Jevhen Kaplin, humanitaarabimisjoni Prolisok koordinaator.\n',
    pidstavkyPidVulyky: 'Mesilastaru alus on miinipilduja miinide osadest tehtud.\n',
    vulykyZrobleni: 'Mesilastarud on tehtud miinikastidest.\n',
    vulykyVijny: 'Sõja mesilastarud\n',
    vulykyVijnyText:
      'Hea peremees Serhii Kit (Kass) elab Slavne külas. Ta tegi eesliinist paari sammu kaugusel mesila. Miinikastist tehtud mesitarud meeldivad väga mesilastele, kellel ei olnud elamiskohta, ja nii tulid nad peremehe juurde ja on kastidega väga rahul. Nüüdseks on 15 mesilasperel kodu ja nad korjavad mett.',
    dzhmeliv1000: '1000 kimalast ja komandör\n',
    dobriLiudySpaslyBdzhil: 'Head inimesed päästsid mesilasi ja kimalasi.\n',
    dzhmeliv1000Text:
      'Pommitamise käigus lõhkusid mürsud kaitsekraavi, kindlustatud muldonni ja palju kimalaste pesasid. Kimalased, kes jäid ellu, lendasid kaitsekraavis ringi. Tuhanded. Üleval tulistatakse, kaitsekraavis on aga kimalased. Siis hakkas ülem kimalastega juttu ajama: „Sellid, kui te kedagi hammastate, siis viskan kohe suitsugranaadi.“ \n' +
      'Seejärel lausus komandör: Kujuta ette, Anatoli, nad ei hammastanudki mitte kedagi. Nad pole ju inimesed, nendega võib kokku leppida! \n' +
      'Hiljem viis ta terved pesad ohutusse kohta\n' +
      'Öövestlus, Piskõ, hüüdnimega Saimon',
    //page40_41
    angelZChernivciv1: 'ingel Tšernivtsist Zolote linnas',
    angelZChernivciv2: 'ingel Tšernivtsist Zolote linnas',
    konIDub: 'Hobu ja Tamm\n',
    konIDub1: '\t1.\tKuulipilduja, mis tahab tappa sõbralikku Hobu.\n',
    konIDub2: '\t2.\tHobu palub Tammelt abi.\n',
    konIDub3: '\t3.\tTamm kaitseb Hobu kuulide ja miinide eest.\n',
    konIDub4: '\t4.\tHobu korjas tõrusid Tammelt, mis päästis teda. Tegi ka külviku ja istutas tammemetsa.\n',
    sijalka: 'Külvik',
    mina82mm: '82 mm miin vigastas Hobu jalga.\n',
    konyk: 'Hobu',
    dubovyjLis: 'Tammemets',
    //page42_43
    spasenyjVojin: 'Päästetud sõdur\n',
    spasenyjVojinText:
      'Hiiglaslik vana puu seisis minu ees. Mul tekkis selline tunne, et tahan teda kallistada. Tulin ta juurde, kallistasin, aga jalg jäi nähtavale. Lendas miin ja nüüd ma elan ilma jalata, aga ikkagi elan...\n' +
      'Tallinn 2018. a. Kõnelus sõduriga.',
    derevaSpasajut: 'Puud päästavad\n',
    SpasenaHataTaRodyna: 'Päästetud tare ja pere\n',
    SpasenaHataTaRodynaText:
      '11.04.2019 pommitati Zolote linna. Miinid tabasid Gornjatska tänav 2 asuva tare hoovi. Miin tabas puud ja plahvatas enne maja jõudmist. Elanikud pääsesid ja maja jäi terveks.\n',
    roztrosheneMynojuDerevo: 'Miinide murtud puu.\n',
    liudySadzhajut: 'Inimesed istutavad puid\n',
    jaPalcemRobliuDirky:
      'Kaitsekraavi ääres puurin sõrmega mulda auke. Kunagi tulen tagasi – vaata, milline tammemets on siia kasvanud...\n',
    rozmovaZVojinom: 'Kõnelus sõduriga 2016. a. Granitne, Donetski oblast.\n',
    budynkyLiudej: 'Meeste, naiste ja \nnende laste majad\n',
    spasenaShkola: 'Päästetud kool ja lapsed\n',
    spasenaShkolaText:
      '17.05.2019 pommitati kooli Svitlodarski linnas. 370 õpilast evakueeriti. Mürsk tabas puud. Lapsed kallistasid seda puud ja tänasid, öeldes: „Sa oled samuti elav, ka sina tunned valu“.\n',
    povertajsiaZhyvym: 'Tule elavana tagasi!\n',
    //page44_45??

    //page46_47??

    //page48_49
    angelZToshkivky1: 'ingel Toškivka külast',
    angelZToshkivky2: 'ingel Toškivka külast',
    konykISych: 'Hobu\n ja Kakk\n',
    konykISych1:
      '\t1.\t29. oktoobril aastal 2014 kell 10 hakkas Kakk, kes elas koolis, akendele koputama ja saabuva häda eesti hoiatama.\n',
    konykISych2:
      '\t2.\tKoolidirektor kuulis Kakku, jättis tunnid ära ja lasi õpilastel kl 11 koju minna ja hästi tegi, sest \n',
    konykISych3: '\t3.\t29. oktoobril 2014. a kelll 13.30 tabasid mürsud akent ja purustasid ühte osa koolist.\n',
    toshkivskaShkola: 'Toškivka kool',
    sychZShkoly1: 'Toškivka kakk - parim koolilaste sõber',
    sychZShkoly2: 'Toškivka kakk - parim koolilaste sõber',
    sychZShkoly3: 'Toškivka kakk - parim koolilaste sõber',
    sychZShkoly4: 'Toškivka kakk - parim koolilaste sõber',
    //page50_51
    podiaka: 'Tänusõnad \nKakule\n',
    shkolaFoto: 'Toškivka kool, mida tabas rakett\n',
    dityFoto: 'Toškivka koolilapsed\n',
    lystyVUKK: 'Kirjad Ukraina Kultuurikeskusele (lapsed meisterdasid hobukesi)\n',
    raketaVidGragu: '“Gradi” rakett (“Grad” on reaktiivmiinipilduja)\n',
    raketaPopalaVShkolu: 'Rakett tabas kooli\n',
    direktor: 'Toškivka koolidirektor Volodõmõr Tšerkess\n',
    direktor29zhovtnia2014: '29.10.2014. a usaldas koolidirektor kakku ja käskis õpilastele koju minna\n',
    dityZKatolyckojiShkoly: 'Tartu katoliku kooli lapsed kogusid raha ja tellisid autahvli tänusõnadega Kakule\n',
    pidpysyDitej: 'Laste allkirjad. Just nemad ei kogenud pommitamist, sest Kakk päästis neid.\n',
    //page52_53
    kaligrafia: 'kalligraafia kool',
    drukarnia: 'trükikoda',
    papirnia: 'paberikoda',
    konykISanktuarium: 'Hobu ja Sanctuarium\n',
    molytvaIrobotaIChytannia: 'Palve ja töö ja lugemine.\n',
    konykPryviv: 'Hobu viis oma sõbrad Sanctuariumisse -',
    misceVJakomu:
      'koht, kus inimesed palvetavad iga päev looduse ja süütute või ülekohtuselt taga kiusatud inimeste eest.\n',
    biblioteka: 'raamatukogu',
    shkola: 'kool',
    cerkva: 'kirik',
    muzej: 'muuseum',
    //page54_55
    spasybi: 'Täname!\n',
    estonia: 'Eestimaa',
    konykVezePodiaku: 'Puuhobune edastab ukrainlaste tänu Eestimaa headele inimestele.\n',
    //page56_57
    ukrkultfond: 'Ukraina Kultuuri Fond\n',
    eestifilmfest: 'Eesti Filmi Instituud\n',
    docfilmkazkaprokonyka:
      'Dokumentaalfilm “Казка про Коника” (Sõda ja Puuhobune)\n Režissöörid Uljana Osovska ja Deniss Strashnyj\n',
    cejFilmMyPlanujemo:
      'Plaanime näidata seda filmi Ukraina, Eesti, Soome, Rootsi, Norra, Leedu, Läti, Saksamaa, Itaalia, Poola, Tšehhi ja teiste riikide vaatajatele. \n',
    Ukraina: 'Ukraina',
    surnames:
      '* Animatsioon - Rodion Shub\n* Kunstnik - Mara Zabashta, Masha Shub\n' +
      '* Heli - Vasylj Javtushenko\n' +
      '* Muusika-helilooja - Anton Degtjarjov\n' +
      '* Anastasija Sidelnik\n' +
      '* Volodymyr Favorskyj\n' +
      '* Pavlo Oleksienko\n' +
      '* Oleksandra Hluschenko\n' +
      '* Uljana Fedorachenko\n' +
      '* Anastasija Vesna\n' +
      '* Bohdan Kushnirchuk\n',
    kuliaJakuDobryjLikar:
      'Kuul, mida  Denissist eemaldas hea arsti, 25.02 nädal aega hiljem! Kuul 9 mm lendas õhkpüssist “Fort” Berkuti poolt.\n',
    denysFilmuvavMajdan:
      'Denis filmis Maidani. Üks minut peale seda kaadrit ta oli haavatud kuuliga. Kyiv, Instytutska tänav, 18 detsember  2014 a. kell 21.35.\n',
    andrijKumSestry: 'Andrii, Denissi ristiisa.',
    dobriLikariZa4Godyny:
      'Head arstid nelja tunise lõikuse käigus leidsid selle kuuli Denisis. (Deniss on pikali, anestesioloog Andrii seisab)\n',
    ulianaVTojChasVidpravliala:
      'Sellel ajal Uljana koostöös “Е+” organisatsiooniga tegeles haavatute saatmisega Eestisse ravile. Siis ta sai Denissiga tuttavaks. Hiljem, 10.04.2014 Uljana tuli ise Eestisse Deniss oli väga õnnelik, sest juba haiglas kohtudes ta armus Uljanasse. Ning kohe,  2 aastat möödudes, 11. novembril nad pidasid pulma, kus oli 150 külalist. Foto pulmadest\n',
    zTogoChasuKrimDenysaVonaPoliubyla:
      'Sellest ajast, lisaks Denissile, Uljana armus ka Ukraina Kultuurikeskusesse Eestis. Deniss armus ka meie keskusesse. Ja selle armastusega nemad koos meiega alates  2014 aastast sõidavad mööda rindejoont abistama inimesi. Igal pool, kus me olime, Uljana ja Deniss filmisid. Koostöös oma andekate ukraina ja eesti kolleegidega nad tegid hea filmi. Mina samal ajal koos nendega ja eesti kolleegidega tegin selle raamatu.',
    UlianaTaDenysZnimajut:
      'Uljana Osovska ja Deniss Strashnyj filmivad Piskah, Donetski oblastis dokumentaalfilmi “Казка про Коника” (“Sõda ja Puuhobune”)\n',
    //page58
    analolij: 'Anatoli Ljutjuk',
    text:
      'Elan munga elu tavamaailmas. Olen Püha Benediktuse oblaat, tsistertslaste kloostri juures Norras ning jälgin Püha Benediktuse reeglit “Ora et Labora” (palve ja töö). Igapäevaselt palvetan looduse eest. Tegelen üle 20 aasta ikoonide loomisega materiaalses ruumis, mis sai Tallinna Sanktuaariumiks (lk 52).\n\n' +
      'Koos mõttekaaslastega kogun lugusid Headusest ning inimese ja looduse harmoonia säilitamisest. Nendest lugudest loome käsitööraamatuid järgides vanu tehnoloogiaid.\n\n' +
      '“Казка про Коника” (“Sõda ja Puuhobune”) – see on raamat reaalsete lugudega Inimestest, Loomadest ja Headusest sõjas, mida ma kogusin humanitaarabi missioonidel rindejoone lähedal Donbassis.',
  },
}
