import {
  page0,
  page1,
  page10_11,
  page12_13,
  page14_15,
  page16_17,
  page18_19,
  page2_3,
  page4_5,
  page6_7,
  page8_9,
  page20_21,
  page22_23,
  page24_25,
  page26_27,
  page28_29,
  page30_31,
  page32_33,
  page34_35,
  page54_55,
  page56_57,
  page58,
  page52_53,
  page36_37,
  page38_39,
  page40_41,
  page42_43,
  page48_49,
  page50_51,
} from './pagesTranslationConfig'

export const TRANSLATIONS_POS = {
  0: page0,
  1: page1,
  2: page2_3,
  3: page2_3,
  4: page4_5,
  5: page4_5,
  6: page6_7,
  7: page6_7,
  8: page8_9,
  9: page8_9,
  10: page10_11,
  11: page10_11,
  12: page12_13,
  13: page12_13,
  14: page14_15,
  15: page14_15,
  16: page16_17,
  17: page16_17,
  18: page18_19,
  19: page18_19,
  20: page20_21,
  21: page20_21,
  22: page22_23,
  23: page22_23,
  24: page24_25,
  25: page24_25,
  26: page26_27,
  27: page26_27,
  28: page28_29,
  29: page28_29,
  30: page30_31,
  31: page30_31,
  32: page32_33,
  33: page32_33,
  34: page34_35,
  35: page34_35,
  36: page36_37,
  37: page36_37,
  38: page38_39,
  39: page38_39,
  40: page40_41,
  41: page40_41,
  42: page42_43,
  43: page42_43,
  48: page48_49,
  49: page48_49,
  50: page50_51,
  51: page50_51,
  52: page52_53,
  53: page52_53,
  54: page54_55,
  55: page54_55,
  56: page56_57,
  57: page56_57,
  58: page58,
  59: page58,
}
